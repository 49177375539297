import React, { FC } from 'react'
import Helmet from 'react-helmet'
import YoutubeSearchedFor from '@material-ui/icons/YoutubeSearchedFor'
import { RelateKeywordContainer } from '@containers/relatedKeyword'
import { PageHeader } from '@components/common'

const RelatedKeyword: FC = () => (
  <div>
    <Helmet>
      <title>WIDETREND | 연관검색어 확장 분석</title>
    </Helmet>
    <PageHeader
      helpblock='주제 키워드와 연관된 관심사를 확장하여 분석할 수 있습니다.'
      icon={<YoutubeSearchedFor />}
    >
      연관검색어 확장 분석
    </PageHeader>
    <RelateKeywordContainer />
  </div>
)

export default RelatedKeyword
