import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import HighChart from '../HighChart'

type SearchEngineBarChartProps = {
  categories: string[]
  datas: SeriesOptionsType[]
  chartType: string
  stacking: undefined | 'normal' | 'percent'
}

const baseDataLabel = {
  enabled: true,
  rotation: 0,
  color: '#FFFFFF',
  align: 'center',
  format: '{point.percentage:,.0f}%',
  style: {
    fontSize: '12px',
  },
}

const stackingMap = (data: any) => ({
  ...data,
  dataLabels: {
    ...baseDataLabel,
    format: '{point.percentage:,.0f}%',
  },
})

const nonStackingMap = (data: any) => ({
  ...data,
  dataLabels: {
    ...baseDataLabel,
    align: 'right',
    format: '{point.y:,.0f}',
  },
})

const buildOption = ({
  categories,
  datas,
  chartType,
  stacking,
}: SearchEngineBarChartProps): Highcharts.Options => ({
  chart: {
    type: chartType,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories,
    crosshair: true,
  },
  yAxis: {
    min: 0,
    max: chartType === 'bar' && stacking ? 100 : null,
    title: {
      text: '검색량',
    },
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat:
      chartType === 'bar' && stacking
        ? '{series.name}: {point.y:,.0f}%'
        : '{series.name}: {point.y:,.0f}',
  },
  colors: ['#46CF5D', '#4284F4', '#F50E02'],
  plotOptions: {
    series: {
      stacking,
    },
  },

  series: datas.map(d => stacking ? stackingMap(d): nonStackingMap(d))
})

const SearchEngineBarChart: FC<SearchEngineBarChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default SearchEngineBarChart
