import React, { FC } from 'react'
import Helmet from 'react-helmet'
import Inbox from '@material-ui/icons/Inbox'
import { NoticeContainer } from '@containers/notice'
import PageHeader from '@components/common/PageHeader'

const Notice: FC = () => (
  <div>
    <Helmet>
      <title>WIDETREND | 공지사항 및 안내</title>
    </Helmet>
    <PageHeader icon={<Inbox />}>공지사항 및 안내</PageHeader>
    <NoticeContainer />
  </div>
)

export default Notice
