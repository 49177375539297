/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userInfo
// ====================================================

export interface userInfo_me {
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   */
  username: string;
}

export interface userInfo {
  me: userInfo_me;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchExcelDownloadLink
// ====================================================

export interface fetchExcelDownloadLink_excelFileDownloadLink {
  filename: string;
  url: string;
}

export interface fetchExcelDownloadLink {
  excelFileDownloadLink: fetchExcelDownloadLink_excelFileDownloadLink;
}

export interface fetchExcelDownloadLinkVariables {
  dataType: DataTypes;
  periodType: PeriodTypes;
  keywords: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchDailyData
// ====================================================

export interface fetchDailyData_dailyGroupData_daily {
  date: string;
  device: Devices;
  volume: number;
}

export interface fetchDailyData_dailyGroupData {
  keywords: string[];
  daily: fetchDailyData_dailyGroupData_daily[];
}

export interface fetchDailyData {
  dailyGroupData: fetchDailyData_dailyGroupData[];
}

export interface fetchDailyDataVariables {
  periodType: PeriodTypes;
  keywords: string[][];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchMonthlyData
// ====================================================

export interface fetchMonthlyData_monthlyData_months {
  date: string;
  device: Devices;
  volume: number;
}

export interface fetchMonthlyData_monthlyData_stats {
  date: string;
  device: Devices;
  age: AgeGroup;
  gender: Gender;
  volume: number;
}

export interface fetchMonthlyData_monthlyData {
  name: string;
  months: fetchMonthlyData_monthlyData_months[];
  stats: fetchMonthlyData_monthlyData_stats[];
}

export interface fetchMonthlyData {
  monthlyData: fetchMonthlyData_monthlyData[];
}

export interface fetchMonthlyDataVariables {
  periodType: PeriodTypes;
  keywords: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchCompareMonthlyData
// ====================================================

export interface fetchCompareMonthlyData_compareMonthlyData_months {
  date: string;
  device: Devices;
  volume: number;
}

export interface fetchCompareMonthlyData_compareMonthlyData_stats {
  date: string;
  device: Devices;
  age: AgeGroup;
  gender: Gender;
  volume: number;
}

export interface fetchCompareMonthlyData_compareMonthlyData {
  keywords: string[];
  months: fetchCompareMonthlyData_compareMonthlyData_months[];
  stats: fetchCompareMonthlyData_compareMonthlyData_stats[];
}

export interface fetchCompareMonthlyData {
  compareMonthlyData: fetchCompareMonthlyData_compareMonthlyData[];
}

export interface fetchCompareMonthlyDataVariables {
  periodType: PeriodTypes;
  keywords: string[][];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login {
  token: string;
}

export interface login {
  login: login_login | null;
}

export interface loginVariables {
  username: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Posts
// ====================================================

export interface Posts_posts {
  id: string;
  title: string;
  content: string;
  updatedAt: string;
}

export interface Posts {
  posts: (Posts_posts | null)[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchRelatedKeywordQuery
// ====================================================

export interface fetchRelatedKeywordQuery_relatedKeywordData_months {
  date: string;
  device: Devices;
  volume: number;
}

export interface fetchRelatedKeywordQuery_relatedKeywordData_stats {
  date: string;
  device: Devices;
  age: AgeGroup;
  gender: Gender;
  volume: number;
}

export interface fetchRelatedKeywordQuery_relatedKeywordData {
  keywords: string[];
  months: fetchRelatedKeywordQuery_relatedKeywordData_months[];
  stats: fetchRelatedKeywordQuery_relatedKeywordData_stats[];
}

export interface fetchRelatedKeywordQuery {
  relatedKeywordData: fetchRelatedKeywordQuery_relatedKeywordData[];
}

export interface fetchRelatedKeywordQueryVariables {
  periodType: PeriodTypes;
  keywords: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchSearchEngineData
// ====================================================

export interface fetchSearchEngineData_searchEngineData_data {
  channel: Channels;
  volume: number;
}

export interface fetchSearchEngineData_searchEngineData {
  name: string;
  data: fetchSearchEngineData_searchEngineData_data[];
}

export interface fetchSearchEngineData {
  searchEngineData: fetchSearchEngineData_searchEngineData[];
}

export interface fetchSearchEngineDataVariables {
  keywords: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signup
// ====================================================

export interface signup_createUser {
  ok: boolean | null;
}

export interface signup {
  createUser: signup_createUser | null;
}

export interface signupVariables {
  username: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AgeGroup {
  FIFTY_ = "FIFTY_",
  FOURTY_ZERO__NINE = "FOURTY_ZERO__NINE",
  THIRTEEN__NINETEEN = "THIRTEEN__NINETEEN",
  THIRTY_ZERO__NINE = "THIRTY_ZERO__NINE",
  TWENTY_FIVE__NINE = "TWENTY_FIVE__NINE",
  TWENTY_ZERO__FOUR = "TWENTY_ZERO__FOUR",
  _TWELVE = "_TWELVE",
}

/**
 * An enumeration.
 */
export enum Channels {
  GOOGLE = "GOOGLE",
  SEARCHAD = "SEARCHAD",
  YOUTUBE = "YOUTUBE",
}

export enum DataTypes {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  USERSTAT = "USERSTAT",
}

/**
 * An enumeration.
 */
export enum Devices {
  MOBILE = "MOBILE",
  PC = "PC",
}

/**
 * An enumeration.
 */
export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum PeriodTypes {
  ALL = "ALL",
  YEAR_1 = "YEAR_1",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
