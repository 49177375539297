import moment from 'moment'

export const getBaseMonths = (): string[] => {
  const results: string[] = []
  const startMonth = moment('2016-02', 'YYYY-MM')
  const endMonth = moment()


  while (true) {
    const month = startMonth.add(1, 'months')
    results.push(month.format('YYYY-MM'))
    if (month.diff(endMonth, 'months') === -2) break
  }
  return results.reverse()
}
