import React, { FC, useMemo, useEffect } from 'react'
import SearchDataDownload from '@containers/download/SearchDataDownload'
import { SearchForm, useSearchForm } from '@components/common'
import { KeywordCompareContent } from '@components/keywordCompare'
import {
  IPeriodTypes,
  useFetchCompareMonthlyDataLazyQuery,
  IDataTypes
} from '@generated/graphql'
import NoSearchResult from '@components/common/NoSearchResult'
import useSearchFormSubmit from '@hooks/useSearchFormSubmit'
import { getEmptyKeywords, getTotalKeywordsLength } from '@lib/utils/keywordGroup'
import useKeywordParams from './useKeywordParams'

const SearchHelpBlock = () => (
  <ul>
    <li>
      각 줄마다 콤마(,)로 구분하여 키워드 그룹을 묶을 수 있습니다.
    </li>
    <li>
      줄바꿈(Enter)으로 비교대상 그룹을 나눌 수 있습니다. (첫번째 키워드를 대표 키워드로 표시함) <br />
      <span style={{ color: 'rgb(190, 190, 190)' }}>
        예) 스타벅스, 스벅, 별다방, starbucks<br />
         커피빈, 콩다방, coffeebean<br />
         엔젤리너스, 엔제리너스, angelinus<br />
         투썸, 투썸플레이스, twosome, twosomeplace<br />
         탐앤탐스, 탐탐, tomntoms<br />
      </span>
    </li>
    <li>최대 10개(행 기준)까지 입력이 가능합니다.</li>
  </ul>
)

const KeywordCompareContainer: FC = () => {
  const [fetch, { data, loading }] = useFetchCompareMonthlyDataLazyQuery()
  const [params, updateParams] = useKeywordParams()
  const onSubmit = useSearchFormSubmit({
    onSearch: (periodType: IPeriodTypes, keywordGroups: string[][]) => updateParams(keywordGroups, periodType),
  })

  useEffect(() => {
    if (!params) return
    fetch({ variables: params })
  }, [params, fetch])

  const initialValue = useMemo(() => {
    if (!params) return undefined
    return {
      keywords: params.keywords.map(group => group.join(',')).join('\n'),
      periodType: params.periodType
    }
  }, [params])

  const [form, onChange] = useSearchForm({ onSubmit, initialValue })
  const emptyKeywords = useMemo(() => getEmptyKeywords(data?.groupedData), [data])

  const contents = useMemo(() => {
    if (!data?.groupedData) return null
    return <KeywordCompareContent groups={data?.groupedData} />
  }, [data])

  const downloadSection = useMemo(() => {
    const isAllEmpty = getTotalKeywordsLength(data?.groupedData) === emptyKeywords.length
    if (!data?.groupedData || isAllEmpty) return null
    return <SearchDataDownload
      {...form}
      dataType={IDataTypes.Monthly}
    />
  }, [data, emptyKeywords, form])

  return (
    <>
      <SearchForm
        form={form}
        placeholder='비교 검색할 키워드를 줄바꿈(Enter)으로 구분하여 입력해주세요'
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loading}
        helpBlock={<SearchHelpBlock />}
        condition
        grouping
      />
      {emptyKeywords.length > 0 && (<NoSearchResult keywords={emptyKeywords} />)}
      {contents}
      {downloadSection}
    </>
  )
}

export default KeywordCompareContainer
