import React, { FC } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

type ProtectRouteProps = {
  component: any
} & RouteProps

const ProtectRoute: FC<ProtectRouteProps> = ({
  path,
  component: Component,
}) => {
  const isLoggedIn = localStorage.getItem('token') !== null
  return (
    <Route
      path={path}
      render={() => !isLoggedIn ? <Redirect to='/login' /> : <Component />}
    />
  )
}

export default ProtectRoute
