import { KeywordRecord } from '@models/keyword'
import {
  TotalVolumeGroup,
  totalVolumeByGroup,
  getMaxEntry,
} from '@lib/utils/keyword'
import { pipe, groupBy, mapObjIndexed, values } from 'ramda'
import { Devices } from 'types/api'

export type DeviceProgressChartType = 'column' | 'line'

export type DeviceProgressSeriesData = {
  name: string
  data: number[]
}

type VolumeGroupByDeviceAndDate = {
  [key: string]: TotalVolumeGroup
}

export const deviceMap = {
  [Devices.PC]: 'PC',
  [Devices.MOBILE]: 'Mobile',
}

export const totalVolumeGroupByDeviceAndDate = (
  months: KeywordRecord[],
): VolumeGroupByDeviceAndDate => pipe(
  groupBy((m: KeywordRecord) => m.device),
  mapObjIndexed<KeywordRecord[], TotalVolumeGroup>((datas: KeywordRecord[]) => totalVolumeByGroup<KeywordRecord>('date', datas),),
)(months)

export const generateDeviceProgressData = (
  groupData: VolumeGroupByDeviceAndDate,
) => pipe(
  mapObjIndexed<TotalVolumeGroup, DeviceProgressSeriesData>(
    (volumeGroup: TotalVolumeGroup, key: string) => ({
      name: key,
      data: values(volumeGroup),
    }),
  ),
  values,
)(groupData)

export const getMaxEntryByDevice = (
  volumeGroupByDeviceAndDate: VolumeGroupByDeviceAndDate,
) => pipe(
  mapObjIndexed<TotalVolumeGroup, [string, number]>(getMaxEntry),
  Object.entries,
)(volumeGroupByDeviceAndDate)
