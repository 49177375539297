import React, { FC, useCallback } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Theme } from '@material-ui/core/styles'
import useReactRouter from 'use-react-router'
import { RootState } from '@store/modules'
import { actions } from '@store/modules/ui'
import { useSelector, useDispatch } from 'react-redux'
import { Header } from '@components/common'

const HeaderContainer: FC = () => {
  const username = useSelector((state: RootState) => state.auth.username)
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { isLgDrawerOpen, isSmDrawerOpen } = useSelector(
    (state: RootState) => state.ui,
  )
  const dispatch = useDispatch()
  const { history } = useReactRouter()

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token')
    history.push('/login')
  }, [history])

  const handleDrawerToggle = useCallback(() => {
    const drawerType = smDown ? 'sm' : 'lg'
    const isOpen = drawerType === 'sm' ? isSmDrawerOpen : isLgDrawerOpen

    if (isOpen) dispatch(actions.closeDrawer(drawerType))
    else dispatch(actions.openDrawer(drawerType))
  }, [dispatch, isSmDrawerOpen, isLgDrawerOpen, smDown])

  return (
    <Header
      username={username}
      onLogout={handleLogout}
      onDrawerToggle={handleDrawerToggle}
    />
  )
}

export default HeaderContainer
