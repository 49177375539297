import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { GroupMonthData } from '@models/keyword'
import { makeStyles } from '@material-ui/core/styles'
import { Section, Strong } from '@components/common'
import { SearchVolumeContainer } from '@containers/keywordCompare'

type SearchVolumeSectionProps = {
  groupMonths: GroupMonthData[]
}

const useStyles = makeStyles({
  root: {},
})

const SearchVolumeSection: FC<SearchVolumeSectionProps> = ({ groupMonths }) => {
  const styles = useStyles()
  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>{groupMonths.length}개 검색어</Strong>의{' '}
          검색량 비교
        </Typography>
      }
      helpBlock='해당 기간동안 검색어의 검색량을 비교합니다.'
    >
      <Paper className={styles.root}>
        <Box p={2}>
          <SearchVolumeContainer groupMonths={groupMonths} />
        </Box>
      </Paper>
    </Section>
  )
}

export default SearchVolumeSection
