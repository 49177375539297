import { ISearchEngineDataType } from '@generated/graphql'
import pipe from 'ramda/es/pipe'
import groupBy from 'ramda/es/groupBy'
import mapObjIndexed from 'ramda/es/mapObjIndexed'
import sum from 'ramda/es/sum'
import map from 'ramda/es/map'
import filter from 'ramda/es/filter'

export const groupByChannel = (channelData: ISearchEngineDataType[]) => groupBy(({ channel }) => channel, channelData)

export const aggregateSum = (channelData: ISearchEngineDataType[]) => pipe(
  groupByChannel,
  mapObjIndexed((data: ISearchEngineDataType[]) => {
    const volumes = filter<number | undefined | null, 'array'>((v) => !!v)(
      map(({ volume }) => volume, data),
    )
    return sum(volumes as number[])
  }),
)(channelData)
