import React, { FC } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { PieChart } from '@widetrend/ui/components/chart/common'
import { CHANNEL_MAP } from '@common/constant'
import { ISearchEngineDataType } from '@generated/graphql'
import { aggregateSum } from './util'

type EngineWeightProps = {
  channelData: ISearchEngineDataType[]
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const EngineWeight: FC<EngineWeightProps> = ({ channelData }) => {
  const styles = useStyles()
  const aggregateData = aggregateSum(channelData)
  return (
    <Paper className={styles.root}>
      <Box p={2}>
        <Typography variant='body1'>검색엔진별 비중</Typography>
        <PieChart
          name='SearchEngine'
          data={Object.keys(CHANNEL_MAP).map((channel) => [
            CHANNEL_MAP[channel],
            aggregateData[channel],
          ])}
          chartOptions={{
            colors: ['#46CF5D', '#4284F4', '#F50E02'],
          }}
        />
      </Box>
    </Paper>
  )
}

export default EngineWeight
