import React, { FC, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import range from 'ramda/es/range'
import {
  DateRangePicker as ReactDatesRangePicker,
  DateRangePickerShape,
} from 'react-dates'

interface DateRangePickerProps
  extends Omit<
    DateRangePickerShape,
    | 'startDateId'
    | 'endDateId'
    | 'displayFormat'
    | 'monthFormat'
    | 'isOutsideRange'
    | 'renderMonthElement'
  > {
  limitStart: moment.Moment
  limitEnd: moment.Moment
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  limitStart,
  limitEnd,
  ...rest
}) => {
  const renderMonthElement = useCallback(
    ({ month: date, onMonthSelect, onYearSelect }) => {
      let years = range(date.year() - 5, date.year() + 5)
      const months = range(0, 12).map((m) => ({
        disabled: !(
          moment([date.year(), m]).diff(limitStart, 'days') >= 0
          && moment([date.year(), m]).diff(limitEnd, 'days') <= 0
        ),
        value: m,
      }))

      if (limitStart && limitEnd) {
        years = years.filter(
          (year) => limitStart.year() <= year && limitEnd.year() >= year,
        )
      }

      return (
        <Box
          display='flex'
          justifyContent='center'
        >
          <Box mr={2}>
            <Select
              native
              value={date.year()}
              onChange={(e) => onYearSelect(date, e.target.value)}
            >
              {years.map((year) => (
                <option
                  key={year}
                  value={year}
                >
                  {year}
                </option>
              ))}
            </Select>
            년
          </Box>
          <Box>
            <Select
              native
              value={date.month()}
              onChange={(e) => onMonthSelect(date, e.target.value)}
            >
              {months.map(({ disabled, value }) => (
                <option
                  disabled={disabled}
                  key={value}
                  value={value}
                >
                  {value + 1}
                </option>
              ))}
            </Select>
            월
          </Box>
        </Box>
      )
    },
    [limitStart, limitEnd],
  )

  return (
    <ReactDatesRangePicker
      {...rest}
      startDateId='start'
      endDateId='end'
      readOnly
      displayFormat='YYYY-MM-DD'
      monthFormat='YYYY년 MM월'
      isOutsideRange={(date: moment.Moment) => !date.isBetween(limitStart, limitEnd)
      }
      renderMonthElement={renderMonthElement}
    />
  )
}

export default DateRangePicker
