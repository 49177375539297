import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  center: {
    textAlign: 'center',
    padding: '10px 20px',
    fontSize: 16,
    color: 'red'
  }
})

interface Props {
  keywords: string[]
}

const NoSearchResult: FC<Props> = ({ keywords }) => {
  const styles = useStyles()

  return (
    <Box my={2}>
      <Paper>
        <div className={styles.center}>
          {keywords.map(v => `"${v}"`).join(',')} 키워드는 수집중입니다.
        </div>
      </Paper>
    </Box>
  )
}

export default NoSearchResult
