import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Section, Strong } from '@components/common'
import pluck from 'ramda/es/pluck'
import flatten from 'ramda/es/flatten'
import moment from 'moment'
import { IFetchSearchEngineDataQuery } from '@generated/graphql'
import { EngineWeight } from './EngineWeight'
import { EngineWeightByKeyword } from './EngineWeightByKeyword'

type SearchEngineSectionProps = {
  data: IFetchSearchEngineDataQuery['searchEngineData']['summary']
}

const SearchEngineSection: FC<SearchEngineSectionProps> = ({ data }) => {
  const channelData = flatten(pluck('data', data))

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>{data.length}개 검색어</Strong>의 검색엔진{' '}
          <Strong color='secondary'>채널별</Strong> 비중
        </Typography>
      }
      helpBlock='검색어의 검색엔진별 검색량 비중을 나타냅니다.'
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <EngineWeight channelData={channelData} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
        >
          <EngineWeightByKeyword data={data} />
        </Grid>
      </Grid>
      <Box py={1}>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          최근 1년(
          {moment()
            .subtract(1, 'years')
            .format('YYYY년 MM월')}{' '}
          ~ {moment().format('YYYY년 MM월')}
          )간의 검색량 기준입니다.
        </Typography>
      </Box>
    </Section>
  )
}

export default SearchEngineSection
