import { StatRecord } from '@models/keyword'
import {
  TotalVolumeGroup,
  totalVolumeByGroup,
  totalVolumeToPercentage,
} from '@lib/utils/keyword'
import {
  pipe,
  groupBy,
  mapObjIndexed,
  map,
  sum,
  values,
  toPairs,
  reduce,
  sortBy,
} from 'ramda'
import { Gender } from 'types/api'

type GroupedTotalVolume = {
  [key: string]: TotalVolumeGroup
}

export const totalVolumeGroupByAgeAndGender = (
  stats: StatRecord[],
): GroupedTotalVolume => pipe(
  groupBy((s: StatRecord) => s.age),
  mapObjIndexed<StatRecord[], TotalVolumeGroup>((datas: StatRecord[]) => totalVolumeByGroup<StatRecord>('gender', datas),),
)(stats)

export const toPercentage = (
  groupedTotalVolume: GroupedTotalVolume,
  total: number,
) => mapObjIndexed((vg: TotalVolumeGroup) => totalVolumeToPercentage(vg, total))(
  groupedTotalVolume,
)

export const sumEntires = (groupedTotalVolume: GroupedTotalVolume): any[] => pipe(
  map(([age, groupedGender]: [string, TotalVolumeGroup]) => [
    age,
    groupedGender[Gender.MALE] || 0,
    groupedGender[Gender.FEMALE] || 0,
    sum(values(groupedGender)) || 0,
  ]),
  sortBy((v: any[]) => v[0]),
)(toPairs<TotalVolumeGroup>(groupedTotalVolume))

export const summaryEntries = (entries: any[]): any[] => reduce(
  ([prevMale, prevFemale, prevTotal], [_, male, female, total]) => [
    prevMale + male || 0,
    prevFemale + female || 0,
    prevTotal + total || 0,
  ],
  [0, 0, 0],
  entries,
)
