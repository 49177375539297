import React, { FC, useMemo } from 'react'
import { SearchForm, useSearchForm } from '@components/common'
import SearchDataDownload from '@containers/download/SearchDataDownload'
import NoSearchResult from '@components/common/NoSearchResult'
import useSearchFormSubmit from '@hooks/useSearchFormSubmit'
import { KeywordContent } from '@components/keyword'
import {
  IPeriodTypes,
  IDataTypes,
  IFetchMonthlyDataQuery,
  useFetchMonthlyDataLazyQuery,
} from '@generated/graphql'

const getEmptyKeywords = (monthlyData?: IFetchMonthlyDataQuery['monthlyData']): string[] => {
  if (!((monthlyData?.length ?? 0) > 0)) return []
  return monthlyData?.filter(({ months, stats }) => months?.length === 0 && stats?.length === 0).map(({ name }) => name) ?? []
}

const SearchHelpBlock = () => (
  <ul>
    <li>입력한 검색어 (띄어쓰기 제외, 대소문자 구분 안함)의 검색 데이터를 합산하여 결과를 제공합니다.</li>
    <li>
      최대 10개의 검색어를 입력할 수 있습니다. <br />
      예)스타벅스, 스벅, starbucks, 별다방
    </li>
  </ul>
)

const KeywordContainer: FC = () => {
  const [fetch, { data, loading }] = useFetchMonthlyDataLazyQuery()
  const onSubmit = useSearchFormSubmit({
    onSearch: (periodType: IPeriodTypes, keywords: string[][]) => fetch({
      variables: {
        periodType,
        keywords: keywords[0],
      },
    }),
  })

  const [form, onChange] = useSearchForm({ onSubmit })
  const emptyKeywords = getEmptyKeywords(data?.monthlyData)

  const contents = useMemo(() => {
    if (!data?.monthlyData) return null
    return <KeywordContent keywords={data?.monthlyData} />
  }, [data])

  const downloadSection = useMemo(() => {
    const isAllEmpty = emptyKeywords.length === data?.monthlyData?.length
    if (data && !isAllEmpty && !loading) {
      return <SearchDataDownload
        {...form}
        dataType={IDataTypes.Monthly}
      />
    }
  }, [data, form, emptyKeywords, loading])


  return (
    <>
      <SearchForm
        form={form}
        placeholder='한 개 이상의 키워드를 콤마(,)로 구분하여 한줄로 입력해주세요'
        onChange={onChange}
        helpBlock={<SearchHelpBlock />}
        onSubmit={onSubmit}
        loading={loading}
        condition
      />
      {emptyKeywords.length > 0 && <NoSearchResult keywords={emptyKeywords} />}
      {contents}
      {downloadSection}
    </>
  )
}

export default KeywordContainer
