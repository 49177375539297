import React, { FC } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Devices } from 'types/api'

type DeviceFilterProps = {
  onChange: any
  isChecked: (val: string) => boolean
}

const valueMap = {
  PC: Devices.PC,
  Mobile: Devices.MOBILE,
}

const DeviceFilter: FC<DeviceFilterProps> = ({ onChange, isChecked }) => (
  <>
    {['PC', 'Mobile'].map((device: string) => (
      <FormControlLabel
        key={device}
        control={
          <Checkbox
            checked={isChecked(valueMap[device])}
            value={valueMap[device]}
            onChange={onChange}
          />
        }
        label={device}
      />
    ))}
  </>
)

export default DeviceFilter
