import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import {
  AgeWeightSection,
  SearchVolumeSection,
  SearchProgressSection,
  DeviceWeightSection,
  GenderWeightSection,
} from '@components/keywordCompare'
import { IFetchCompareMonthlyDataQuery } from '@generated/graphql'
import { KeywordGroup } from '@models/keyword'
import map from 'ramda/es/map'
import isEmpty from 'ramda/es/isEmpty'
import flatten from 'ramda/es/flatten'
import pluck from 'ramda/es/pluck'

type KeywordCompareContentProps = {
  groups?: IFetchCompareMonthlyDataQuery['groupedData']
}

const KeywordCompareContent: FC<KeywordCompareContentProps> = ({ groups }) => {
  if (!groups) return null

  const groupMonths = map(
    ({ keywords, months }: KeywordGroup) => ({ keywords, months }),
    groups,
  )

  const groupStats = map(
    ({ keywords, stats }: KeywordGroup) => ({ keywords, stats }),
    groups,
  )

  const months = flatten(pluck('months', groups))
  const stats = flatten(pluck('stats', groups))

  return (
    <Box>
      {!isEmpty(months) && (
        <>
          <Box mt={8}>
            <SearchVolumeSection groupMonths={groupMonths} />
          </Box>
          <Box mt={8}>
            <SearchProgressSection groupMonths={groupMonths} />
          </Box>
        </>
      )}
      {!isEmpty(stats) && (
        <>
          <Box mt={8}>
            <DeviceWeightSection groupStats={groupStats} />
          </Box>
          <Box mt={8}>
            <GenderWeightSection groupStats={groupStats} />
          </Box>
          <Box mt={8}>
            <AgeWeightSection groupStats={groupStats} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default KeywordCompareContent
