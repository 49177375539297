import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Portal } from '@components/common'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

const Loader: FC = () => {
  const styles = useStyles()

  return (
    <Portal rootId="loading-root">
      <div className={styles.backdrop}>
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      </div>
    </Portal>
  )
}


export default Loader
