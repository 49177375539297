import React, { FC, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useSelector } from 'react-redux'
import { RootState } from '@store/modules'
import { Section, Strong } from '@components/common'
import path from 'ramda/es/path'

type TableSectionProps = {
  children: any
}

const getName = path(['name'])

const TableSection: FC<TableSectionProps> = ({ children }) => {
  const { depth1, depth2 } = useSelector((state:RootState) => state.businessKeyword)

  const [headerEl, helpBlockEl] = useMemo(() => {
    let header
    let helpBlock

    if (!depth1 || depth1?.id === 'ALL') {
      header = <Strong color='primary'>검색량 TOP 키워드</Strong>
      helpBlock = '최근 1년간 가장인기있는 검색어 순위를 나타냅니다.'
    } else {
      const depth1Name = getName(depth1) ?? ""
      const depth2Name = getName(depth2) ?? ""
      header = <Strong color="primary">{[depth1Name, depth2Name].join(' > ')}의 검색량 키워드</Strong>
      helpBlock = '최근 1년간 업종내에서 가장 인기있는 검색어를 발굴합니다.'
    }

    return [header, helpBlock]
  }, [depth1, depth2])

  return (
    <Section
      header={<Typography variant='h5'>{headerEl}</Typography>}
      helpBlock={helpBlockEl}
    >
      <Paper>
        <Box p={2}>
          {children}
        </Box>
      </Paper>
    </Section>
  )
}

export default TableSection
