import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import {
  MonthlySection,
  DeviceSection,
  GenderAgeSection,
  GenderAgeTableSection,
} from '@components/keyword'
import pluck from 'ramda/es/pluck'
import flatten from 'ramda/es/flatten'
import isEmpty from 'ramda/es/isEmpty'
import { IFetchMonthlyDataQuery } from '@generated/graphql'
import { generateKeywordCaption, getPeriodByKeywords } from './util'

type KeywordContentProps = {
  keywords: IFetchMonthlyDataQuery['monthlyData']
}

const KeywordContent: FC<KeywordContentProps> = ({ keywords }) => {
  const months = flatten(pluck('months', keywords))
  const stats = flatten(pluck('stats', keywords))

  return (
    <Box>
      {!isEmpty(months) && (
        <>
          <Box mt={8}>
            <MonthlySection
              monthDatas={months}
              periods={getPeriodByKeywords(keywords)}
              keywordCaption={generateKeywordCaption(keywords)}
            />
          </Box>
          <Box mt={8}>
            <DeviceSection monthDatas={months} />
          </Box>
        </>
      )}
      {!isEmpty(stats) && (
        <>
          <Box mt={8}>
            <GenderAgeSection statDatas={stats} />
          </Box>
          <Box mt={8}>
            <GenderAgeTableSection statDatas={stats} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default KeywordContent
