import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import grey from '@material-ui/core/colors/grey'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${grey[200]}`,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url('/logo.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 300,
    backgroundPosition: 'center -27px',
    height: 60,
  },
  logo: {
    width: 300,
  },
  strong: {
    color: '#F51B3C',
    fontStyle: 'italic',
  },
  gutter: {
    marginBottom: 5,
  },
}))

const FormLogo: FC = () => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <Box
        className={styles.header}
        my={2}
      />
      <Typography
        className={styles.gutter}
        variant='body1'
        align='center'
        color='textSecondary'
      >
        Powered by WIDECORE
      </Typography>
      <Typography
        className={styles.gutter}
        variant='body2'
        align='center'
        color='textSecondary'
      >
        트렌드를 읽는 예리한 시선, <br />
        <strong className={styles.strong}>widetrend</strong> 에서 쉽고 직관적인
        데이터
        <br />
        분석을 경험해보세요
      </Typography>
    </div>
  )
}

export default FormLogo
