import { totalVolumeByGroup, totalVolumeToPercentage } from '@lib/utils/keyword'
import { StatRecord, GroupStatData } from '@models/keyword'
import { pipe, map, pluck, sum } from 'ramda'
import { groupKeywordName, ProcessDataResult } from '../util'

export const processData = (groups: GroupStatData[]) => pipe(
  map(({ keywords, stats }: GroupStatData) => ({
    keyword: groupKeywordName(keywords),
    total: sum(pluck('volume', stats)),
    data: totalVolumeByGroup<StatRecord>('device', stats),
  })),
  map(({ keyword, total, data }: ProcessDataResult) => ({
    keyword,
    total,
    data: totalVolumeToPercentage(data, total),
  })),
)(groups)
