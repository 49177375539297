import { toPairs, fromPairs, map } from 'ramda'

type DictType<T> = {
  [key: string]: T
}

type Pair<T> = [string, T]

export const renameKeys = <T>(target: DictType<string>, source: DictType<T>) => fromPairs<T>(
  map<Pair<T>, Pair<T>>(([k, v]: Pair<T>) => [target[k], v])(
    toPairs<T>(source),
  ),
)
