import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import HighChart from '../HighChart'

type NullableNumber = number | null

type SeriesData = {
  name: string
  data: NullableNumber[]
}

type AgeWeightChartProps = {
  categories: string[]
  datas: SeriesData[]
}

const buildSeries = (data: SeriesData): SeriesOptionsType => ({
  ...data,
  type: 'bar',
  dataLabels: {
    enabled: true,
    rotation: 0,
    color: '#FFFFFF',
    align: 'center',
    format: '{point.y:,.0f}',
    style: {
      fontSize: '12px',
    },
  },
})

const buildOption = ({
  categories,
  datas,
}: AgeWeightChartProps): Highcharts.Options => ({
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories,
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: ''
    }
  },

  legend: {
    reversed: true,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '<b>{point.x}%</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}<br/>',
  },

  plotOptions: {
    series: {
      stacking: 'normal',
    },
  },

  colors: [
    '#FFFD55',
    '#F2C143',
    '#DEBAB8',
    '#D09895',
    '#59AFEB',
    '#3472BA',
    '#06215C',
  ].reverse(),

  series: datas.map((v: SeriesData) => buildSeries(v)),
})

const AgeWeightChart: FC<AgeWeightChartProps> = (props) => {
  const options = buildOption(props)
  return (
    <Box>
      <Typography
        variant='body2'
        color='textSecondary'
        align='right'
      >
        단위(%)
      </Typography>
      <HighChart options={options} />
    </Box>
  )
}

export default AgeWeightChart
