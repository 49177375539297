import React, { FC } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { GroupStatData } from '@models/keyword'
import { StackingBarChart } from '@widetrend/ui/components/chart/keywordCompare'
import sortBy from 'ramda/es/sortBy'
import pluck from 'ramda/es/pluck'
import map from 'ramda/es/map'
import useInput from '@hooks/useInput'
import Box from '@material-ui/core/Box'
import {
  processData,
} from '@components/keywordCompare/DeviceWeightSection/util'
import {
  ProcessDataResult,
  mapCategoriesToChartData,
} from '@components/keywordCompare/util'
import { IDevice } from '@generated/graphql'

type DeviceWeightContainerProps = {
  groupStats: GroupStatData[]
}


type SortItem = {
  label: string
  value: string
}
const sortList = [
  {
    label: '입력순',
    value: 'input',
  },
  {
    label: '검색량순',
    value: 'volume',
  },
  {
    label: 'Mobile 비중높은 순',
    value: IDevice.Mobile,
  },
  {
    label: 'PC 비중높은 순',
    value: IDevice.Pc,
  },
]

const deviceLabels = {
  [IDevice.Mobile]: 'Mobile',
  [IDevice.Pc]: 'PC'
}

const sortFuncMap = {
  volume: ({ total }: ProcessDataResult) => -total,
  [IDevice.Mobile]: ({ data }: ProcessDataResult) => -data[IDevice.Mobile],
  [IDevice.Pc]: ({ data }: ProcessDataResult) => -data[IDevice.Pc],
}

const DeviceWeightContainer: FC<DeviceWeightContainerProps> = ({
  groupStats,
}) => {
  const sort = useInput<string>('input')

  let processResult = processData(groupStats)
  if (sort.value !== 'input') processResult = sortBy(sortFuncMap[sort.value], processResult)
  const chartData = mapCategoriesToChartData([IDevice.Pc, IDevice.Mobile], processResult).map(({ name, data }) => ({
    name: deviceLabels[name],
    data
  }))

  return (
    <Box>
      <Box>
        <Select {...sort.props}>
          {map(
            ({ value, label }: SortItem) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}{' '}
              </MenuItem>
            ),
            sortList,
          )}
        </Select>
      </Box>
      <Box>
        <StackingBarChart
          categories={pluck('keyword', processResult)}
          datas={chartData}
        />
      </Box>
    </Box>
  )
}

export default DeviceWeightContainer
