import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import BarChart from '@material-ui/icons/BarChart'
import InsertChart from '@material-ui/icons/InsertChart'
import ShowChart from '@material-ui/icons/ShowChart'
import useToggleButtonGroup from '@hooks/useToggleButtonGroup'
import { DeviceProgressChart } from '@widetrend/ui/components/chart/keyword'
import { DeviceProgressSeriesData } from '@components/keyword/DeviceSection/util'
import { map } from 'ramda'

type ChartType = 'column' | 'stacking' | 'line'

type DeviceProgressContainerProps = {
  months: string[]
  seriesData: DeviceProgressSeriesData[]
}

const convertHighChartType = (chartType: ChartType) => chartType === 'stacking' ? 'column' : chartType

const DeviceProgressContainer: FC<DeviceProgressContainerProps> = ({
  months,
  seriesData,
}) => {
  const chartType = useToggleButtonGroup<ChartType>('column')

  return (
    <>
      <Box mt={2}>
        <ToggleButtonGroup
          {...chartType}
          exclusive
          size='small'
          aria-label='chart type'
        >
          <ToggleButton
            value='column'
            aria-label='bar chart'
          >
            <BarChart /> 막대차트
          </ToggleButton>
          <ToggleButton
            value='stacking'
            aria-label='stacking chart'
          >
            <InsertChart /> 누적막대차트
          </ToggleButton>
          <ToggleButton
            value='line'
            aria-label='line chart'
          >
            <ShowChart /> 꺽은선차트
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box my={2}>
        <DeviceProgressChart
          chartType={chartType.value}
          months={months}
          datas={map(
            (data: DeviceProgressSeriesData) => ({
              ...data,
              type: convertHighChartType(chartType.value),
            }),
            seriesData,
          )}
          stacking={chartType.value === 'stacking' ? 'percent' : undefined}
        />
      </Box>
    </>
  )
}

export default DeviceProgressContainer
