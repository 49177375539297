import React, { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  Notice,
  Keyword,
  KeywordCompare,
  DailyTrend,
  RelatedKeyword,
  SearchEngine,
  BusinessKeywordMonthly,
} from '..'

const Routes: FC = () => (
  <Switch>
    <Route
      exact
      path='/'
      render={() => <Redirect to='/notice' />}
    />
    <Route
      path='/notice'
      component={Notice}
    />
    <Route
      path='/keyword-analysis'
      component={Keyword}
    />
    <Route
      path='/keyword-compairsion-analysis'
      component={KeywordCompare}
    />
    <Route
      path='/daily-search-volume-trend'
      component={DailyTrend}
    />
    <Route
      path='/related'
      component={RelatedKeyword}
    />
    <Route
      path='/search-engine-analysis'
      component={SearchEngine}
    />
    <Route
      path='/business-keyword-monthly'
      component={BusinessKeywordMonthly}
    />
  </Switch>
)

export default Routes
