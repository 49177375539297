import { IKeywordGroupFieldsFragment } from '@generated/graphql'
import flatten from 'ramda/es/flatten'

export const getEmptyKeywords = (keywordGroup?: IKeywordGroupFieldsFragment[]): string[] => {
  if (!keywordGroup || keywordGroup.length === 0) return []
  return flatten(keywordGroup?.map((data) => data?.emptyKeywords?.filter(Boolean) ?? [])) as string[]
}

export const getTotalKeywordsLength = (keywordGroup?: IKeywordGroupFieldsFragment[]) => {
  if (!keywordGroup || keywordGroup.length === 0) return []
  return flatten(keywordGroup?.map((data) => data?.keywords ?? [])).length
}
