import React, { FC, ChangeEvent, FormEvent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import InputAdornment from '@material-ui/core/InputAdornment'

import { IPeriodTypes } from '@generated/graphql'

export type SearchFormType = {
  periodType: IPeriodTypes
  keywords: string
}

type SearchFormProps = {
  form: SearchFormType
  placeholder?: string
  onSubmit: (form: SearchFormType) => any
  onChange: (e: ChangeEvent<any>) => void
  condition?: boolean
  grouping?: boolean
  helpBlock?: any
  description?: string
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
  },
  form: {},
  leftIcon: {
    marginRight: 5,
  },
  keywordSection: {},
  conditionSection: {},
  buttonSection: {
    textAlign: 'right',
  },
  labelHelp: {
    fontSize: 12,
    marginLeft: 10,
    color: theme.palette.grey[600],
  },
  formGroup: {},
  radioGroup: {
    flexDirection: 'row',
  },
}))

export const SearchForm: FC<SearchFormProps> = ({
  form,
  placeholder,
  onChange,
  onSubmit,
  grouping,
  condition,
  helpBlock,
  loading,
  description
}) => {
  const styles = useStyles()

  return (
    <Paper className={styles.root}>
      <form
        className={styles.form}
        onSubmit={(e: FormEvent) => {
          e.preventDefault()
          onSubmit(form)
        }}
      >
        {condition && (
          <div className={styles.conditionSection}>
            <Typography variant='body2'>분석 기간</Typography>
            <RadioGroup
              name='duration'
              value={form.periodType}
              className={styles.radioGroup}
              onChange={onChange}
            >
              <FormControlLabel
                value={IPeriodTypes.Year_1}
                label='최근 1년'
                control={
                  <Radio
                    color='primary'
                    inputProps={{ name: 'periodType' }}
                    disabled={loading}
                  />
                }
              />
              <FormControlLabel
                value={IPeriodTypes.All}
                label={
                  <span>
                    수집 기간 전체
                    <span className={styles.labelHelp}>
                      { description || '(2016년 ~ 전월)'}
                    </span>
                  </span>
                }
                control={
                  <Radio
                    color='primary'
                    inputProps={{ name: 'periodType' }}
                    disabled={loading}
                  />
                }
              />
            </RadioGroup>
          </div>
        )}
        <div className={styles.keywordSection}>
          <TextField
            label='키워드'
            placeholder={placeholder}
            value={form.keywords}
            onChange={onChange}
            InputProps={{
              name: 'keywords',
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
            multiline={grouping}
            disabled={loading}
          />
        </div>
        <Box mt={2}>{helpBlock}</Box>
        <p className={styles.buttonSection}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
          >
            <Search className={styles.leftIcon} />
            분석
          </Button>
        </p>
      </form>
    </Paper>
  )
}

SearchForm.defaultProps = {
  condition: false,
  grouping: false,
  loading: false,
}
