import gql from 'graphql-tag'
import * as React from 'react'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactComponents from '@apollo/react-components'
import * as ApolloReactHoc from '@apollo/react-hoc'
import * as ApolloReactHooks from '@apollo/react-hooks'

export type Maybe<T> = T | null
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   * */
  DateTime: any
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   * */
  GenericScalar: any
}

/** An enumeration. */
export enum IAgeGroup {
  Twelve = '_TWELVE',
  ThirteenNineteen = 'THIRTEEN__NINETEEN',
  TwentyZeroFour = 'TWENTY_ZERO__FOUR',
  TwentyFiveNine = 'TWENTY_FIVE__NINE',
  ThirtyZeroNine = 'THIRTY_ZERO__NINE',
  FourtyZeroNine = 'FOURTY_ZERO__NINE',
  Fifty = 'FIFTY_',
}

export type IBusinessCategoryExcelDownloadInput = {
  category: ICategoryInput
  date: Scalars['String']
}

export type IBusinessCategoryModelType = {
  __typename?: 'BusinessCategoryModelType'
  id: Scalars['String']
  name: Scalars['String']
  parent: IBusinessCategoryModelType
  children: Array<IBusinessCategoryModelType>
}

export type IBusinessKeywordMonthlyOrder = {
  field: IBusinessKeywordMonthlyOrderField
  direction: IOrderDirection
}

export enum IBusinessKeywordMonthlyOrderField {
  Total = 'TOTAL',
  MobileRatio = 'MOBILE_RATIO',
  PcRatio = 'PC_RATIO',
  Variation = 'VARIATION',
  VariationRatio = 'VARIATION_RATIO',
}

export type IBusinessKeywordMonthlyPage = {
  __typename?: 'BusinessKeywordMonthlyPage'
  totalCount: Scalars['Int']
  pageCount: Scalars['Int']
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  items: Array<IBusinessKeywordMonthlyType>
}

export type IBusinessKeywordMonthlyType = {
  __typename?: 'BusinessKeywordMonthlyType'
  keyword: Scalars['String']
  mobile: Scalars['Int']
  pc: Scalars['Int']
  total: Scalars['Int']
  variation: Scalars['Int']
  variationRatio: Scalars['String']
  months: Array<IMonthlyDataType>
}

export type ICategoryInput = {
  main: Scalars['String']
  sub: Scalars['String']
}

/** An enumeration. */
export enum IChannel {
  Searchad = 'SEARCHAD',
  Youtube = 'YOUTUBE',
  Google = 'GOOGLE',
}

export type ICreateUser = {
  __typename?: 'CreateUser'
  ok?: Maybe<Scalars['Boolean']>
  user: IUserModelType
}

export type ICreateUserInput = {
  username: Scalars['String']
  password: Scalars['String']
  name?: Maybe<Scalars['String']>
  affiliation?: Maybe<Scalars['String']>
  companyNumber?: Maybe<Scalars['String']>
  extraEmail?: Maybe<Scalars['String']>
  tosAgreed: Scalars['Boolean']
  privacyPolicyAgreed: Scalars['Boolean']
}

export type IDailyGroupedDataType = {
  __typename?: 'DailyGroupedDataType'
  keywords: Array<Scalars['String']>
  periodType: IPeriodTypes
  daily: Array<IDailyProgressType>
  emptyKeywords?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type IDailyProgressType = {
  __typename?: 'DailyProgressType'
  volume: Scalars['Int']
  device: IDevice
  date: Scalars['String']
  indexedAt: Scalars['DateTime']
}

export enum IDataTypes {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Userstat = 'USERSTAT',
  RelatedKeywords = 'RELATED_KEYWORDS',
  BusinessCategory = 'BUSINESS_CATEGORY',
}

/** An enumeration. */
export enum IDevice {
  Mobile = 'MOBILE',
  Pc = 'PC',
}

export type IExcelDownloadType = {
  __typename?: 'ExcelDownloadType'
  url: Scalars['String']
  filename: Scalars['String']
  expiredAt: Scalars['String']
}

/** An enumeration. */
export enum IGender {
  Male = 'MALE',
  Female = 'FEMALE',
}

/** An enumeration. */
export enum IKeywordRelation {
  AutoComplete = 'AUTO_COMPLETE',
  Related = 'RELATED',
}

export type IKeywordRelationshipExcelDownloadInput = {
  keywords: Array<Scalars['String']>
  period: IPeriodTypes
  relationType: IKeywordRelation
}

export type IKeywordRelationshipInput = {
  keywords: Array<Scalars['String']>
  periodType: IPeriodTypes
  relationType: IKeywordRelation
}

export type ILogin = {
  __typename?: 'Login'
  token: Scalars['String']
  ok?: Maybe<Scalars['Boolean']>
  user: IUserModelType
}

export type ILogout = {
  __typename?: 'Logout'
  ok?: Maybe<Scalars['Boolean']>
  user: IUserModelType
}

export type IMonthlyDataType = {
  __typename?: 'MonthlyDataType'
  name: Scalars['String']
  periodType: IPeriodTypes
  months: Array<IMonthlyProgressType>
  stats: Array<IMonthlyUserStatType>
}

export type IMonthlyGroupedDataType = {
  __typename?: 'MonthlyGroupedDataType'
  keywords: Array<Scalars['String']>
  emptyKeywords?: Maybe<Array<Maybe<Scalars['String']>>>
  periodType: IPeriodTypes
  months: Array<IMonthlyProgressType>
  stats: Array<IMonthlyUserStatType>
}

export type IMonthlyProgressType = {
  __typename?: 'MonthlyProgressType'
  device: IDevice
  date: Scalars['String']
  indexedAt: Scalars['DateTime']
  volume: Scalars['Int']
}

export type IMonthlyUserStatType = {
  __typename?: 'MonthlyUserStatType'
  age: IAgeGroup
  device: IDevice
  date: Scalars['String']
  gender: IGender
  indexedAt: Scalars['DateTime']
  volume: Scalars['Int']
}

export type IMutation = {
  __typename?: 'Mutation'
  createUser?: Maybe<ICreateUser>
  login?: Maybe<ILogin>
  logout?: Maybe<ILogout>
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<IObtainJsonWebToken>
  verifyToken?: Maybe<IVerify>
  refreshToken?: Maybe<IRefresh>
}

export type IMutationCreateUserArgs = {
  signupInput: ICreateUserInput
}

export type IMutationLoginArgs = {
  password: Scalars['String']
  username: Scalars['String']
}

export type IMutationTokenAuthArgs = {
  username: Scalars['String']
  password: Scalars['String']
}

export type IMutationVerifyTokenArgs = {
  token: Scalars['String']
}

export type IMutationRefreshTokenArgs = {
  token: Scalars['String']
}

/** Obtain JSON Web Token mutation */
export type IObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken'
  token?: Maybe<Scalars['String']>
}

export enum IOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type IPageInfo = {
  no: Scalars['Int']
  size: Scalars['Int']
}

export enum IPeriodTypes {
  /** WITHIN 1 YEAR */
  Year_1 = 'YEAR_1',
  /** ALL */
  All = 'ALL',
}

export type IPostType = {
  __typename?: 'PostType'
  id: Scalars['ID']
  postAuthor: Scalars['Int']
  postDate: Scalars['DateTime']
  postDateGmt: Scalars['DateTime']
  postContent: Scalars['String']
  postTitle: Scalars['String']
  postExcerpt: Scalars['String']
  postStatus: Scalars['String']
  commentStatus: Scalars['String']
  pingStatus: Scalars['String']
  postPassword: Scalars['String']
  postName: Scalars['String']
  toPing: Scalars['String']
  pinged: Scalars['String']
  postModified: Scalars['DateTime']
  postModifiedGmt: Scalars['DateTime']
  postContentFiltered: Scalars['String']
  postParent: Scalars['Int']
  guid: Scalars['String']
  menuOrder: Scalars['Int']
  postType: Scalars['String']
  postMimeType: Scalars['String']
  commentCount: Scalars['Int']
  title: Scalars['String']
  content: Scalars['String']
  updatedAt: Scalars['String']
}

export type IQuery = {
  __typename?: 'Query'
  posts: Array<Maybe<IPostType>>
  businessKeywordsMonthly: IBusinessKeywordMonthlyPage
  categories: Array<IBusinessCategoryModelType>
  searchEngineData: ISearchEngineKeywordType
  searchDataDownload: IExcelDownloadType
  businessKeywordDownload: IExcelDownloadType
  keywordRelationshipDataDownload: IExcelDownloadType
  keywordRelationshipData: Array<IMonthlyGroupedDataType>
  dailyGroupData: Array<IDailyGroupedDataType>
  compareMonthlyData: Array<IMonthlyGroupedDataType>
  monthlyData: Array<IMonthlyDataType>
  me: IUserModelType
}

export type IQueryBusinessKeywordsMonthlyArgs = {
  category: ICategoryInput
  date: Scalars['String']
  page: IPageInfo
  orderBy: IBusinessKeywordMonthlyOrder
}

export type IQueryCategoriesArgs = {
  parent?: Maybe<Scalars['String']>
}

export type IQuerySearchEngineDataArgs = {
  keywords: Array<Scalars['String']>
}

export type IQuerySearchDataDownloadArgs = {
  downloadInput?: Maybe<ISearchDataExcelDownloadInput>
}

export type IQueryBusinessKeywordDownloadArgs = {
  downloadInput?: Maybe<IBusinessCategoryExcelDownloadInput>
}

export type IQueryKeywordRelationshipDataDownloadArgs = {
  downloadInput?: Maybe<IKeywordRelationshipExcelDownloadInput>
}

export type IQueryKeywordRelationshipDataArgs = {
  keywordRelationshipInput?: Maybe<IKeywordRelationshipInput>
}

export type IQueryDailyGroupDataArgs = {
  keywords: Array<Array<Scalars['String']>>
  periodType: IPeriodTypes
}

export type IQueryCompareMonthlyDataArgs = {
  keywords: Array<Array<Scalars['String']>>
  periodType: IPeriodTypes
}

export type IQueryMonthlyDataArgs = {
  keywords: Array<Scalars['String']>
  periodType: IPeriodTypes
}

export type IRefresh = {
  __typename?: 'Refresh'
  token?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['GenericScalar']>
}

export type ISearchDataExcelDownloadInput = {
  keywords: Array<Scalars['String']>
  period: IPeriodTypes
  dataType: IDataTypes
}

export type ISearchEngineDataType = {
  __typename?: 'SearchEngineDataType'
  channel: IChannel
  volume?: Maybe<Scalars['Int']>
}

export type ISearchEngineKeywordType = {
  __typename?: 'SearchEngineKeywordType'
  monthKeys: Array<Scalars['String']>
  summary: Array<ISearchEngineSummaryType>
  monthly: Array<ISearchEngineMonthlyType>
}

export type ISearchEngineMonthlyDataType = {
  __typename?: 'SearchEngineMonthlyDataType'
  channel: IChannel
  volumes: Array<Maybe<Scalars['Int']>>
}

export type ISearchEngineMonthlyType = {
  __typename?: 'SearchEngineMonthlyType'
  name: Scalars['String']
  data: Array<ISearchEngineMonthlyDataType>
}

export type ISearchEngineSummaryType = {
  __typename?: 'SearchEngineSummaryType'
  name: Scalars['String']
  data: Array<ISearchEngineDataType>
}

export type IUserModelType = {
  __typename?: 'UserModelType'
  id: Scalars['ID']
  password: Scalars['String']
  lastLogin?: Maybe<Scalars['DateTime']>
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']
  dateJoined: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  affiliation?: Maybe<Scalars['String']>
  tosAgreed?: Maybe<Scalars['Boolean']>
  privacyPolicyAgreed?: Maybe<Scalars['Boolean']>
  companyNumber?: Maybe<Scalars['String']>
  extraEmail?: Maybe<Scalars['String']>
  isLicensed?: Maybe<Scalars['Boolean']>
  daysForFree: Scalars['Int']
}

export type IVerify = {
  __typename?: 'Verify'
  payload?: Maybe<Scalars['GenericScalar']>
}

export type IBusinessKeywordDownloadQueryVariables = {
  input?: Maybe<IBusinessCategoryExcelDownloadInput>
}

export type IBusinessKeywordDownloadQuery = { __typename?: 'Query' } & {
  downloadData: { __typename?: 'ExcelDownloadType' } & IDownloadFieldsFragment
}

export type IKeywordRelationShipDownloadQueryVariables = {
  input?: Maybe<IKeywordRelationshipExcelDownloadInput>
}

export type IKeywordRelationShipDownloadQuery = { __typename?: 'Query' } & {
  downloadData: { __typename?: 'ExcelDownloadType' } & IDownloadFieldsFragment
}

export type IFetchExcelDownloadLinkQueryVariables = {
  input?: Maybe<ISearchDataExcelDownloadInput>
}

export type IFetchExcelDownloadLinkQuery = { __typename?: 'Query' } & {
  downloadData: { __typename?: 'ExcelDownloadType' } & IDownloadFieldsFragment
}

export type IDownloadFieldsFragment = {
  __typename?: 'ExcelDownloadType'
} & Pick<IExcelDownloadType, 'url' | 'filename' | 'expiredAt'>

export type ILoginMutationVariables = {
  username: Scalars['String']
  password: Scalars['String']
}

export type ILoginMutation = { __typename?: 'Mutation' } & {
  login: Maybe<{ __typename?: 'Login' } & Pick<ILogin, 'token'>>
}

export type ISignupMutationVariables = {
  input: ICreateUserInput
}

export type ISignupMutation = { __typename?: 'Mutation' } & {
  createUser: Maybe<{ __typename?: 'CreateUser' } & Pick<ICreateUser, 'ok'>>
}

export type IUserInfoQueryVariables = {}

export type IUserInfoQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'UserModelType' } & Pick<IUserModelType, 'username'>
}

export type IFetchCategoriesQueryVariables = {}

export type IFetchCategoriesQuery = { __typename?: 'Query' } & {
  categories: Array<
    { __typename?: 'BusinessCategoryModelType' } & Pick<
      IBusinessCategoryModelType,
      'id' | 'name'
    > & {
        children: Array<
          { __typename?: 'BusinessCategoryModelType' } & Pick<
            IBusinessCategoryModelType,
            'id' | 'name'
          >
        >
      }
  >
}

export type IFetchBusinessKeywordMonthlyQueryVariables = {
  category: ICategoryInput
  page: IPageInfo
  date: Scalars['String']
  orderBy: IBusinessKeywordMonthlyOrder
}

export type IFetchBusinessKeywordMonthlyQuery = { __typename?: 'Query' } & {
  businessKeywordsMonthly: { __typename?: 'BusinessKeywordMonthlyPage' } & Pick<
    IBusinessKeywordMonthlyPage,
    'totalCount' | 'pageCount' | 'hasNextPage' | 'hasPreviousPage'
  > & {
      items: Array<
        { __typename?: 'BusinessKeywordMonthlyType' } & Pick<
          IBusinessKeywordMonthlyType,
          'keyword' | 'mobile' | 'pc' | 'variation' | 'variationRatio' | 'total'
        >
      >
    }
}

export type IKeywordDataFieldsFragment = {
  __typename?: 'MonthlyProgressType'
} & Pick<IMonthlyProgressType, 'date' | 'device' | 'volume'>

export type IKeywordStatFieldsFragment = {
  __typename?: 'MonthlyUserStatType'
} & Pick<IMonthlyUserStatType, 'date' | 'device' | 'age' | 'gender' | 'volume'>

export type IKeywordGroupFieldsFragment = {
  __typename?: 'MonthlyGroupedDataType'
} & Pick<IMonthlyGroupedDataType, 'keywords' | 'emptyKeywords'> & {
    months: Array<
      { __typename?: 'MonthlyProgressType' } & IKeywordDataFieldsFragment
    >
    stats: Array<
      { __typename?: 'MonthlyUserStatType' } & IKeywordStatFieldsFragment
    >
  }

export type IFetchMonthlyDataQueryVariables = {
  periodType: IPeriodTypes
  keywords: Array<Scalars['String']>
}

export type IFetchMonthlyDataQuery = { __typename?: 'Query' } & {
  monthlyData: Array<
    { __typename?: 'MonthlyDataType' } & Pick<IMonthlyDataType, 'name'> & {
        months: Array<
          { __typename?: 'MonthlyProgressType' } & IKeywordDataFieldsFragment
        >
        stats: Array<
          { __typename?: 'MonthlyUserStatType' } & IKeywordStatFieldsFragment
        >
      }
  >
}

export type IKeywordRelationshipQueryVariables = {
  input: IKeywordRelationshipInput
}

export type IKeywordRelationshipQuery = { __typename?: 'Query' } & {
  groupedData: Array<
    { __typename?: 'MonthlyGroupedDataType' } & IKeywordGroupFieldsFragment
  >
}

export type IFetchCompareMonthlyDataQueryVariables = {
  periodType: IPeriodTypes
  keywords: Array<Array<Scalars['String']>>
}

export type IFetchCompareMonthlyDataQuery = { __typename?: 'Query' } & {
  groupedData: Array<
    { __typename?: 'MonthlyGroupedDataType' } & IKeywordGroupFieldsFragment
  >
}

export type IFetchDailyDataQueryVariables = {
  periodType: IPeriodTypes
  keywords: Array<Array<Scalars['String']>>
}

export type IFetchDailyDataQuery = { __typename?: 'Query' } & {
  dailyGroupData: Array<
    { __typename?: 'DailyGroupedDataType' } & Pick<
      IDailyGroupedDataType,
      'keywords' | 'emptyKeywords'
    > & {
        daily: Array<
          { __typename?: 'DailyProgressType' } & Pick<
            IDailyProgressType,
            'date' | 'device' | 'volume'
          >
        >
      }
  >
}

export type IFetchSearchEngineDataQueryVariables = {
  keywords: Array<Scalars['String']>
}

export type IFetchSearchEngineDataQuery = { __typename?: 'Query' } & {
  searchEngineData: { __typename?: 'SearchEngineKeywordType' } & Pick<
    ISearchEngineKeywordType,
    'monthKeys'
  > & {
      summary: Array<
        { __typename?: 'SearchEngineSummaryType' } & Pick<
          ISearchEngineSummaryType,
          'name'
        > & {
            data: Array<
              { __typename?: 'SearchEngineDataType' } & Pick<
                ISearchEngineDataType,
                'channel' | 'volume'
              >
            >
          }
      >
      monthly: Array<
        { __typename?: 'SearchEngineMonthlyType' } & Pick<
          ISearchEngineMonthlyType,
          'name'
        > & {
            data: Array<
              { __typename?: 'SearchEngineMonthlyDataType' } & Pick<
                ISearchEngineMonthlyDataType,
                'channel' | 'volumes'
              >
            >
          }
      >
    }
}

export type IPostsQueryVariables = {}

export type IPostsQuery = { __typename?: 'Query' } & {
  posts: Array<
    Maybe<
      { __typename?: 'PostType' } & Pick<
        IPostType,
        'id' | 'title' | 'content' | 'updatedAt'
      >
    >
  >
}

export const DownloadFieldsFragmentDoc = gql`
  fragment DownloadFields on ExcelDownloadType {
    url
    filename
    expiredAt
  }
`
export const KeywordDataFieldsFragmentDoc = gql`
  fragment KeywordDataFields on MonthlyProgressType {
    date
    device
    volume
  }
`
export const KeywordStatFieldsFragmentDoc = gql`
  fragment KeywordStatFields on MonthlyUserStatType {
    date
    device
    age
    gender
    volume
  }
`
export const KeywordGroupFieldsFragmentDoc = gql`
  fragment KeywordGroupFields on MonthlyGroupedDataType {
    keywords
    emptyKeywords
    months {
      ...KeywordDataFields
    }
    stats {
      ...KeywordStatFields
    }
  }
  ${KeywordDataFieldsFragmentDoc}
  ${KeywordStatFieldsFragmentDoc}
`
export const BusinessKeywordDownloadDocument = gql`
  query businessKeywordDownload($input: BusinessCategoryExcelDownloadInput) {
    downloadData: businessKeywordDownload(downloadInput: $input) {
      ...DownloadFields
    }
  }
  ${DownloadFieldsFragmentDoc}
`
export type BusinessKeywordDownloadComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables
  >,
  'query'
>

export const BusinessKeywordDownloadComponent = (
  props: BusinessKeywordDownloadComponentProps,
) => (
  <ApolloReactComponents.Query<
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables
  >
    query={BusinessKeywordDownloadDocument}
    {...props}
  />
)

export type IBusinessKeywordDownloadProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IBusinessKeywordDownloadQuery,
  IBusinessKeywordDownloadQueryVariables
> &
  TChildProps
export function withBusinessKeywordDownload<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables,
    IBusinessKeywordDownloadProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables,
    IBusinessKeywordDownloadProps<TChildProps>
  >(BusinessKeywordDownloadDocument, {
    alias: 'businessKeywordDownload',
    ...operationOptions,
  })
}

/**
 * __useBusinessKeywordDownloadQuery__
 *
 * To run a query within a React component, call `useBusinessKeywordDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessKeywordDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessKeywordDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessKeywordDownloadQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables
  >(BusinessKeywordDownloadDocument, baseOptions)
}
export function useBusinessKeywordDownloadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IBusinessKeywordDownloadQuery,
    IBusinessKeywordDownloadQueryVariables
  >(BusinessKeywordDownloadDocument, baseOptions)
}
export type BusinessKeywordDownloadQueryHookResult = ReturnType<
  typeof useBusinessKeywordDownloadQuery
>
export type BusinessKeywordDownloadLazyQueryHookResult = ReturnType<
  typeof useBusinessKeywordDownloadLazyQuery
>
export type BusinessKeywordDownloadQueryResult = ApolloReactCommon.QueryResult<
  IBusinessKeywordDownloadQuery,
  IBusinessKeywordDownloadQueryVariables
>
export const KeywordRelationShipDownloadDocument = gql`
  query keywordRelationShipDownload(
    $input: KeywordRelationshipExcelDownloadInput
  ) {
    downloadData: keywordRelationshipDataDownload(downloadInput: $input) {
      ...DownloadFields
    }
  }
  ${DownloadFieldsFragmentDoc}
`
export type KeywordRelationShipDownloadComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables
  >,
  'query'
>

export const KeywordRelationShipDownloadComponent = (
  props: KeywordRelationShipDownloadComponentProps,
) => (
  <ApolloReactComponents.Query<
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables
  >
    query={KeywordRelationShipDownloadDocument}
    {...props}
  />
)

export type IKeywordRelationShipDownloadProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IKeywordRelationShipDownloadQuery,
  IKeywordRelationShipDownloadQueryVariables
> &
  TChildProps
export function withKeywordRelationShipDownload<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables,
    IKeywordRelationShipDownloadProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables,
    IKeywordRelationShipDownloadProps<TChildProps>
  >(KeywordRelationShipDownloadDocument, {
    alias: 'keywordRelationShipDownload',
    ...operationOptions,
  })
}

/**
 * __useKeywordRelationShipDownloadQuery__
 *
 * To run a query within a React component, call `useKeywordRelationShipDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordRelationShipDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordRelationShipDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKeywordRelationShipDownloadQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables
  >(KeywordRelationShipDownloadDocument, baseOptions)
}
export function useKeywordRelationShipDownloadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IKeywordRelationShipDownloadQuery,
    IKeywordRelationShipDownloadQueryVariables
  >(KeywordRelationShipDownloadDocument, baseOptions)
}
export type KeywordRelationShipDownloadQueryHookResult = ReturnType<
  typeof useKeywordRelationShipDownloadQuery
>
export type KeywordRelationShipDownloadLazyQueryHookResult = ReturnType<
  typeof useKeywordRelationShipDownloadLazyQuery
>
export type KeywordRelationShipDownloadQueryResult = ApolloReactCommon.QueryResult<
  IKeywordRelationShipDownloadQuery,
  IKeywordRelationShipDownloadQueryVariables
>
export const FetchExcelDownloadLinkDocument = gql`
  query fetchExcelDownloadLink($input: SearchDataExcelDownloadInput) {
    downloadData: searchDataDownload(downloadInput: $input) {
      ...DownloadFields
    }
  }
  ${DownloadFieldsFragmentDoc}
`
export type FetchExcelDownloadLinkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables
  >,
  'query'
>

export const FetchExcelDownloadLinkComponent = (
  props: FetchExcelDownloadLinkComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables
  >
    query={FetchExcelDownloadLinkDocument}
    {...props}
  />
)

export type IFetchExcelDownloadLinkProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IFetchExcelDownloadLinkQuery,
  IFetchExcelDownloadLinkQueryVariables
> &
  TChildProps
export function withFetchExcelDownloadLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables,
    IFetchExcelDownloadLinkProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables,
    IFetchExcelDownloadLinkProps<TChildProps>
  >(FetchExcelDownloadLinkDocument, {
    alias: 'fetchExcelDownloadLink',
    ...operationOptions,
  })
}

/**
 * __useFetchExcelDownloadLinkQuery__
 *
 * To run a query within a React component, call `useFetchExcelDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExcelDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExcelDownloadLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchExcelDownloadLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables
  >(FetchExcelDownloadLinkDocument, baseOptions)
}
export function useFetchExcelDownloadLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchExcelDownloadLinkQuery,
    IFetchExcelDownloadLinkQueryVariables
  >(FetchExcelDownloadLinkDocument, baseOptions)
}
export type FetchExcelDownloadLinkQueryHookResult = ReturnType<
  typeof useFetchExcelDownloadLinkQuery
>
export type FetchExcelDownloadLinkLazyQueryHookResult = ReturnType<
  typeof useFetchExcelDownloadLinkLazyQuery
>
export type FetchExcelDownloadLinkQueryResult = ApolloReactCommon.QueryResult<
  IFetchExcelDownloadLinkQuery,
  IFetchExcelDownloadLinkQueryVariables
>
export const LoginDocument = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`
export type ILoginMutationFn = ApolloReactCommon.MutationFunction<
  ILoginMutation,
  ILoginMutationVariables
>
export type LoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ILoginMutation,
    ILoginMutationVariables
  >,
  'mutation'
>

export const LoginComponent = (props: LoginComponentProps) => (
  <ApolloReactComponents.Mutation<ILoginMutation, ILoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
)

export type ILoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ILoginMutation,
  ILoginMutationVariables
> &
  TChildProps
export function withLogin<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ILoginMutation,
    ILoginMutationVariables,
    ILoginProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ILoginMutation,
    ILoginMutationVariables,
    ILoginProps<TChildProps>
  >(LoginDocument, {
    alias: 'login',
    ...operationOptions,
  })
}

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ILoginMutation,
    ILoginMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<ILoginMutation, ILoginMutationVariables>(
    LoginDocument,
    baseOptions,
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = ApolloReactCommon.MutationResult<
  ILoginMutation
>
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ILoginMutation,
  ILoginMutationVariables
>
export const SignupDocument = gql`
  mutation signup($input: CreateUserInput!) {
    createUser(signupInput: $input) {
      ok
    }
  }
`
export type ISignupMutationFn = ApolloReactCommon.MutationFunction<
  ISignupMutation,
  ISignupMutationVariables
>
export type SignupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ISignupMutation,
    ISignupMutationVariables
  >,
  'mutation'
>

export const SignupComponent = (props: SignupComponentProps) => (
  <ApolloReactComponents.Mutation<ISignupMutation, ISignupMutationVariables>
    mutation={SignupDocument}
    {...props}
  />
)

export type ISignupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ISignupMutation,
  ISignupMutationVariables
> &
  TChildProps
export function withSignup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ISignupMutation,
    ISignupMutationVariables,
    ISignupProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ISignupMutation,
    ISignupMutationVariables,
    ISignupProps<TChildProps>
  >(SignupDocument, {
    alias: 'signup',
    ...operationOptions,
  })
}

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISignupMutation,
    ISignupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ISignupMutation,
    ISignupMutationVariables
  >(SignupDocument, baseOptions)
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>
export type SignupMutationResult = ApolloReactCommon.MutationResult<
  ISignupMutation
>
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ISignupMutation,
  ISignupMutationVariables
>
export const UserInfoDocument = gql`
  query userInfo {
    me {
      username
    }
  }
`
export type UserInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IUserInfoQuery,
    IUserInfoQueryVariables
  >,
  'query'
>

export const UserInfoComponent = (props: UserInfoComponentProps) => (
  <ApolloReactComponents.Query<IUserInfoQuery, IUserInfoQueryVariables>
    query={UserInfoDocument}
    {...props}
  />
)

export type IUserInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  IUserInfoQuery,
  IUserInfoQueryVariables
> &
  TChildProps
export function withUserInfo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IUserInfoQuery,
    IUserInfoQueryVariables,
    IUserInfoProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IUserInfoQuery,
    IUserInfoQueryVariables,
    IUserInfoProps<TChildProps>
  >(UserInfoDocument, {
    alias: 'userInfo',
    ...operationOptions,
  })
}

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IUserInfoQuery,
    IUserInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<IUserInfoQuery, IUserInfoQueryVariables>(
    UserInfoDocument,
    baseOptions,
  )
}
export function useUserInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IUserInfoQuery,
    IUserInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<IUserInfoQuery, IUserInfoQueryVariables>(
    UserInfoDocument,
    baseOptions,
  )
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>
export type UserInfoLazyQueryHookResult = ReturnType<
  typeof useUserInfoLazyQuery
>
export type UserInfoQueryResult = ApolloReactCommon.QueryResult<
  IUserInfoQuery,
  IUserInfoQueryVariables
>
export const FetchCategoriesDocument = gql`
  query fetchCategories {
    categories {
      id
      name
      children {
        id
        name
      }
    }
  }
`
export type FetchCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables
  >,
  'query'
>

export const FetchCategoriesComponent = (
  props: FetchCategoriesComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables
  >
    query={FetchCategoriesDocument}
    {...props}
  />
)

export type IFetchCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  IFetchCategoriesQuery,
  IFetchCategoriesQueryVariables
> &
  TChildProps
export function withFetchCategories<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables,
    IFetchCategoriesProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables,
    IFetchCategoriesProps<TChildProps>
  >(FetchCategoriesDocument, {
    alias: 'fetchCategories',
    ...operationOptions,
  })
}

/**
 * __useFetchCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables
  >(FetchCategoriesDocument, baseOptions)
}
export function useFetchCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchCategoriesQuery,
    IFetchCategoriesQueryVariables
  >(FetchCategoriesDocument, baseOptions)
}
export type FetchCategoriesQueryHookResult = ReturnType<
  typeof useFetchCategoriesQuery
>
export type FetchCategoriesLazyQueryHookResult = ReturnType<
  typeof useFetchCategoriesLazyQuery
>
export type FetchCategoriesQueryResult = ApolloReactCommon.QueryResult<
  IFetchCategoriesQuery,
  IFetchCategoriesQueryVariables
>
export const FetchBusinessKeywordMonthlyDocument = gql`
  query fetchBusinessKeywordMonthly(
    $category: CategoryInput!
    $page: PageInfo!
    $date: String!
    $orderBy: BusinessKeywordMonthlyOrder!
  ) {
    businessKeywordsMonthly(
      category: $category
      page: $page
      date: $date
      orderBy: $orderBy
    ) {
      totalCount
      pageCount
      hasNextPage
      hasPreviousPage
      items {
        keyword
        mobile
        pc
        variation
        variationRatio
        total
      }
    }
  }
`
export type FetchBusinessKeywordMonthlyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables
  >,
  'query'
> &
  (
    | { variables: IFetchBusinessKeywordMonthlyQueryVariables; skip?: boolean }
    | { skip: boolean })

export const FetchBusinessKeywordMonthlyComponent = (
  props: FetchBusinessKeywordMonthlyComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables
  >
    query={FetchBusinessKeywordMonthlyDocument}
    {...props}
  />
)

export type IFetchBusinessKeywordMonthlyProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IFetchBusinessKeywordMonthlyQuery,
  IFetchBusinessKeywordMonthlyQueryVariables
> &
  TChildProps
export function withFetchBusinessKeywordMonthly<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables,
    IFetchBusinessKeywordMonthlyProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables,
    IFetchBusinessKeywordMonthlyProps<TChildProps>
  >(FetchBusinessKeywordMonthlyDocument, {
    alias: 'fetchBusinessKeywordMonthly',
    ...operationOptions,
  })
}

/**
 * __useFetchBusinessKeywordMonthlyQuery__
 *
 * To run a query within a React component, call `useFetchBusinessKeywordMonthlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBusinessKeywordMonthlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBusinessKeywordMonthlyQuery({
 *   variables: {
 *      category: // value for 'category'
 *      page: // value for 'page'
 *      date: // value for 'date'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFetchBusinessKeywordMonthlyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables
  >(FetchBusinessKeywordMonthlyDocument, baseOptions)
}
export function useFetchBusinessKeywordMonthlyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchBusinessKeywordMonthlyQuery,
    IFetchBusinessKeywordMonthlyQueryVariables
  >(FetchBusinessKeywordMonthlyDocument, baseOptions)
}
export type FetchBusinessKeywordMonthlyQueryHookResult = ReturnType<
  typeof useFetchBusinessKeywordMonthlyQuery
>
export type FetchBusinessKeywordMonthlyLazyQueryHookResult = ReturnType<
  typeof useFetchBusinessKeywordMonthlyLazyQuery
>
export type FetchBusinessKeywordMonthlyQueryResult = ApolloReactCommon.QueryResult<
  IFetchBusinessKeywordMonthlyQuery,
  IFetchBusinessKeywordMonthlyQueryVariables
>
export const FetchMonthlyDataDocument = gql`
  query fetchMonthlyData($periodType: PeriodTypes!, $keywords: [String!]!) {
    monthlyData(periodType: $periodType, keywords: $keywords) {
      name
      months {
        ...KeywordDataFields
      }
      stats {
        ...KeywordStatFields
      }
    }
  }
  ${KeywordDataFieldsFragmentDoc}
  ${KeywordStatFieldsFragmentDoc}
`
export type FetchMonthlyDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: IFetchMonthlyDataQueryVariables; skip?: boolean }
    | { skip: boolean })

export const FetchMonthlyDataComponent = (
  props: FetchMonthlyDataComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables
  >
    query={FetchMonthlyDataDocument}
    {...props}
  />
)

export type IFetchMonthlyDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  IFetchMonthlyDataQuery,
  IFetchMonthlyDataQueryVariables
> &
  TChildProps
export function withFetchMonthlyData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables,
    IFetchMonthlyDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables,
    IFetchMonthlyDataProps<TChildProps>
  >(FetchMonthlyDataDocument, {
    alias: 'fetchMonthlyData',
    ...operationOptions,
  })
}

/**
 * __useFetchMonthlyDataQuery__
 *
 * To run a query within a React component, call `useFetchMonthlyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMonthlyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMonthlyDataQuery({
 *   variables: {
 *      periodType: // value for 'periodType'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useFetchMonthlyDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables
  >(FetchMonthlyDataDocument, baseOptions)
}
export function useFetchMonthlyDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchMonthlyDataQuery,
    IFetchMonthlyDataQueryVariables
  >(FetchMonthlyDataDocument, baseOptions)
}
export type FetchMonthlyDataQueryHookResult = ReturnType<
  typeof useFetchMonthlyDataQuery
>
export type FetchMonthlyDataLazyQueryHookResult = ReturnType<
  typeof useFetchMonthlyDataLazyQuery
>
export type FetchMonthlyDataQueryResult = ApolloReactCommon.QueryResult<
  IFetchMonthlyDataQuery,
  IFetchMonthlyDataQueryVariables
>
export const KeywordRelationshipDocument = gql`
  query KeywordRelationship($input: KeywordRelationshipInput!) {
    groupedData: keywordRelationshipData(keywordRelationshipInput: $input) {
      ...KeywordGroupFields
    }
  }
  ${KeywordGroupFieldsFragmentDoc}
`
export type KeywordRelationshipComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables
  >,
  'query'
> &
  (
    | { variables: IKeywordRelationshipQueryVariables; skip?: boolean }
    | { skip: boolean })

export const KeywordRelationshipComponent = (
  props: KeywordRelationshipComponentProps,
) => (
  <ApolloReactComponents.Query<
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables
  >
    query={KeywordRelationshipDocument}
    {...props}
  />
)

export type IKeywordRelationshipProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IKeywordRelationshipQuery,
  IKeywordRelationshipQueryVariables
> &
  TChildProps
export function withKeywordRelationship<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables,
    IKeywordRelationshipProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables,
    IKeywordRelationshipProps<TChildProps>
  >(KeywordRelationshipDocument, {
    alias: 'keywordRelationship',
    ...operationOptions,
  })
}

/**
 * __useKeywordRelationshipQuery__
 *
 * To run a query within a React component, call `useKeywordRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordRelationshipQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKeywordRelationshipQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables
  >(KeywordRelationshipDocument, baseOptions)
}
export function useKeywordRelationshipLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IKeywordRelationshipQuery,
    IKeywordRelationshipQueryVariables
  >(KeywordRelationshipDocument, baseOptions)
}
export type KeywordRelationshipQueryHookResult = ReturnType<
  typeof useKeywordRelationshipQuery
>
export type KeywordRelationshipLazyQueryHookResult = ReturnType<
  typeof useKeywordRelationshipLazyQuery
>
export type KeywordRelationshipQueryResult = ApolloReactCommon.QueryResult<
  IKeywordRelationshipQuery,
  IKeywordRelationshipQueryVariables
>
export const FetchCompareMonthlyDataDocument = gql`
  query fetchCompareMonthlyData(
    $periodType: PeriodTypes!
    $keywords: [[String!]!]!
  ) {
    groupedData: compareMonthlyData(
      periodType: $periodType
      keywords: $keywords
    ) {
      ...KeywordGroupFields
    }
  }
  ${KeywordGroupFieldsFragmentDoc}
`
export type FetchCompareMonthlyDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: IFetchCompareMonthlyDataQueryVariables; skip?: boolean }
    | { skip: boolean })

export const FetchCompareMonthlyDataComponent = (
  props: FetchCompareMonthlyDataComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables
  >
    query={FetchCompareMonthlyDataDocument}
    {...props}
  />
)

export type IFetchCompareMonthlyDataProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IFetchCompareMonthlyDataQuery,
  IFetchCompareMonthlyDataQueryVariables
> &
  TChildProps
export function withFetchCompareMonthlyData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables,
    IFetchCompareMonthlyDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables,
    IFetchCompareMonthlyDataProps<TChildProps>
  >(FetchCompareMonthlyDataDocument, {
    alias: 'fetchCompareMonthlyData',
    ...operationOptions,
  })
}

/**
 * __useFetchCompareMonthlyDataQuery__
 *
 * To run a query within a React component, call `useFetchCompareMonthlyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompareMonthlyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompareMonthlyDataQuery({
 *   variables: {
 *      periodType: // value for 'periodType'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useFetchCompareMonthlyDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables
  >(FetchCompareMonthlyDataDocument, baseOptions)
}
export function useFetchCompareMonthlyDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchCompareMonthlyDataQuery,
    IFetchCompareMonthlyDataQueryVariables
  >(FetchCompareMonthlyDataDocument, baseOptions)
}
export type FetchCompareMonthlyDataQueryHookResult = ReturnType<
  typeof useFetchCompareMonthlyDataQuery
>
export type FetchCompareMonthlyDataLazyQueryHookResult = ReturnType<
  typeof useFetchCompareMonthlyDataLazyQuery
>
export type FetchCompareMonthlyDataQueryResult = ApolloReactCommon.QueryResult<
  IFetchCompareMonthlyDataQuery,
  IFetchCompareMonthlyDataQueryVariables
>
export const FetchDailyDataDocument = gql`
  query fetchDailyData($periodType: PeriodTypes!, $keywords: [[String!]!]!) {
    dailyGroupData(periodType: $periodType, keywords: $keywords) {
      keywords
      emptyKeywords
      daily {
        date
        device
        volume
      }
    }
  }
`
export type FetchDailyDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: IFetchDailyDataQueryVariables; skip?: boolean }
    | { skip: boolean })

export const FetchDailyDataComponent = (
  props: FetchDailyDataComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables
  >
    query={FetchDailyDataDocument}
    {...props}
  />
)

export type IFetchDailyDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  IFetchDailyDataQuery,
  IFetchDailyDataQueryVariables
> &
  TChildProps
export function withFetchDailyData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables,
    IFetchDailyDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables,
    IFetchDailyDataProps<TChildProps>
  >(FetchDailyDataDocument, {
    alias: 'fetchDailyData',
    ...operationOptions,
  })
}

/**
 * __useFetchDailyDataQuery__
 *
 * To run a query within a React component, call `useFetchDailyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDailyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDailyDataQuery({
 *   variables: {
 *      periodType: // value for 'periodType'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useFetchDailyDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables
  >(FetchDailyDataDocument, baseOptions)
}
export function useFetchDailyDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchDailyDataQuery,
    IFetchDailyDataQueryVariables
  >(FetchDailyDataDocument, baseOptions)
}
export type FetchDailyDataQueryHookResult = ReturnType<
  typeof useFetchDailyDataQuery
>
export type FetchDailyDataLazyQueryHookResult = ReturnType<
  typeof useFetchDailyDataLazyQuery
>
export type FetchDailyDataQueryResult = ApolloReactCommon.QueryResult<
  IFetchDailyDataQuery,
  IFetchDailyDataQueryVariables
>
export const FetchSearchEngineDataDocument = gql`
  query fetchSearchEngineData($keywords: [String!]!) {
    searchEngineData(keywords: $keywords) {
      monthKeys
      summary {
        name
        data {
          channel
          volume
        }
      }
      monthly {
        name
        data {
          channel
          volumes
        }
      }
    }
  }
`
export type FetchSearchEngineDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: IFetchSearchEngineDataQueryVariables; skip?: boolean }
    | { skip: boolean })

export const FetchSearchEngineDataComponent = (
  props: FetchSearchEngineDataComponentProps,
) => (
  <ApolloReactComponents.Query<
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables
  >
    query={FetchSearchEngineDataDocument}
    {...props}
  />
)

export type IFetchSearchEngineDataProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  IFetchSearchEngineDataQuery,
  IFetchSearchEngineDataQueryVariables
> &
  TChildProps
export function withFetchSearchEngineData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables,
    IFetchSearchEngineDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables,
    IFetchSearchEngineDataProps<TChildProps>
  >(FetchSearchEngineDataDocument, {
    alias: 'fetchSearchEngineData',
    ...operationOptions,
  })
}

/**
 * __useFetchSearchEngineDataQuery__
 *
 * To run a query within a React component, call `useFetchSearchEngineDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSearchEngineDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSearchEngineDataQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useFetchSearchEngineDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables
  >(FetchSearchEngineDataDocument, baseOptions)
}
export function useFetchSearchEngineDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    IFetchSearchEngineDataQuery,
    IFetchSearchEngineDataQueryVariables
  >(FetchSearchEngineDataDocument, baseOptions)
}
export type FetchSearchEngineDataQueryHookResult = ReturnType<
  typeof useFetchSearchEngineDataQuery
>
export type FetchSearchEngineDataLazyQueryHookResult = ReturnType<
  typeof useFetchSearchEngineDataLazyQuery
>
export type FetchSearchEngineDataQueryResult = ApolloReactCommon.QueryResult<
  IFetchSearchEngineDataQuery,
  IFetchSearchEngineDataQueryVariables
>
export const PostsDocument = gql`
  query Posts {
    posts {
      id
      title
      content
      updatedAt
    }
  }
`
export type PostsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IPostsQuery,
    IPostsQueryVariables
  >,
  'query'
>

export const PostsComponent = (props: PostsComponentProps) => (
  <ApolloReactComponents.Query<IPostsQuery, IPostsQueryVariables>
    query={PostsDocument}
    {...props}
  />
)

export type IPostsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  IPostsQuery,
  IPostsQueryVariables
> &
  TChildProps
export function withPosts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IPostsQuery,
    IPostsQueryVariables,
    IPostsProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IPostsQuery,
    IPostsQueryVariables,
    IPostsProps<TChildProps>
  >(PostsDocument, {
    alias: 'posts',
    ...operationOptions,
  })
}

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IPostsQuery,
    IPostsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<IPostsQuery, IPostsQueryVariables>(
    PostsDocument,
    baseOptions,
  )
}
export function usePostsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IPostsQuery,
    IPostsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<IPostsQuery, IPostsQueryVariables>(
    PostsDocument,
    baseOptions,
  )
}
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>
export type PostsQueryResult = ApolloReactCommon.QueryResult<
  IPostsQuery,
  IPostsQueryVariables
>
