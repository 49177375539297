import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { Strong } from '@components/common'

type CompareHelpBlockProps = {
  maxColumn: string
  maxPercent: number
  minColumn?: string
  minPercent?: number
}

const CompareHelpBlock: FC<CompareHelpBlockProps> = ({
  maxColumn,
  maxPercent,
  minColumn,
  minPercent,
}) => {
  if (minColumn && minPercent) {
    return (
      <Typography variant='body2'>
        검색어는 <Strong color='primary'>{maxColumn}</Strong>의 비중이{' '}
        <Strong color='secondary'>{maxPercent.toFixed(1)}%</Strong>를 차지하고
        있으며, <Strong color='primary'>{minColumn}</Strong> 대비{' '}
        <Strong color='secondary'>
          {(maxPercent - minPercent).toFixed(1)}%
        </Strong>{' '}
        더 높은 검색량을 보이고 있습니다.
      </Typography>
    )
  }

  if (!maxColumn || !maxPercent) return null
  return (
    <Typography variant='body2'>
      검색어는 <Strong color='primary'>{maxColumn}</Strong>의 비중이{' '}
      <Strong color='secondary'>{maxPercent.toFixed(1)}%</Strong>를 차지하고
      있습니다.
    </Typography>
  )
}

export default CompareHelpBlock
