import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Portal } from '@components/common'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  pre: {
    width: '100%',
    overflow: 'scroll',
    background: '#ddd',
    padding: 10,
  },
})

type AgreeModalProps = {
  open: boolean
  onClose: () => void
  title: string
  message: string
}

const AgreeModal: FC<AgreeModalProps> = ({ open, onClose, title, message }) => {
  const styles = useStyles()
  return (
    <Portal>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <pre className={styles.pre}>{message}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color='primary'
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  )
}

export default AgreeModal
