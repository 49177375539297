import {
  createStandardAction,
  createReducer,
  ActionType,
} from 'typesafe-actions'
import merge from 'ramda/es/merge'

export type State = {
  readonly username: string
  readonly status: string
}

export const initialState: State = {
  username: '',
  status: '',
}

const LOGIN = 'auth/LOGIN'
const LOGOUT = 'auth/LOGOUT'
const SET_USER = 'auth/SET_USER'
const EXPIRED = 'auth/EXPIRED'

type SetUserPayload = {
  username: string
}

const login = createStandardAction(LOGIN)()
const logout = createStandardAction(LOGOUT)()
const setUser = createStandardAction(SET_USER)<SetUserPayload>()
const expired = createStandardAction(EXPIRED)()

export const actions = {
  login,
  logout,
  setUser,
  expired,
}

export type Action = ActionType<typeof actions>

export const reducer = createReducer<State, Action>(initialState, {
  [LOGIN]: (state) => merge(state, { status: 'login' }),
  [LOGOUT]: (state) => merge(state, { status: 'logout' }),
  [EXPIRED]: (state) => merge(state, { status: 'expired' }),
  [SET_USER]: (state, action) => merge(state, { username: action.payload.username }),
})
