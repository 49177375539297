import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import HighChart from '../HighChart'

type AgeChartProps = {
  ageGroups: string[]
  datas: SeriesOptionsType[]
}

const buildOption = ({
  ageGroups,
  datas,
}: AgeChartProps): Highcharts.Options => ({
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: ageGroups,
    crosshair: true,
    title: {
      text: '연령별 검색 비중',
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: '백분율(%)',
    },
  },

  legend: {
    enabled: false,
  },

  tooltip: {
    enabled: true,
    pointFormat: '<b>{point.y:.1f}%</b>',
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f} %',
      },
    },
  },
  series: datas,
})

const AgeChart: FC<AgeChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default AgeChart
