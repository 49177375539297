import React, { FC } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { GroupMonthData } from '@models/keyword'
import { SearchVolumeChart } from '@widetrend/ui/components/chart/keywordCompare'
import { DeviceFilter } from '@components/common'
import { generateChartData } from '@components/keywordCompare/SearchVolumeSection/util'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import sortBy from 'ramda/es/sortBy'
import pluck from 'ramda/es/pluck'
import useInput from '@hooks/useInput'
import Box from '@material-ui/core/Box'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type SearchVolumeContianerPrpos = {
  groupMonths: GroupMonthData[]
}

type SortType = 'input' | 'volume'

const SearchVolumeContianer: FC<SearchVolumeContianerPrpos> = ({
  groupMonths,
}) => {
  const [devices, onChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )
  const sort = useInput<SortType>('volume')

  let chartData = generateChartData(groupMonths, devices)

  if (sort.value === 'volume') chartData = sortBy(({ volume }) => -volume, chartData)

  return (
    <Box>
      <Box>
        <DeviceFilter
          onChange={onChange}
          isChecked={isChecked}
        />
        <Select {...sort.props}>
          <MenuItem value='input'>입력순</MenuItem>
          <MenuItem value='volume'>검색량순</MenuItem>
        </Select>
      </Box>
      <Box>
        <SearchVolumeChart
          categories={pluck('keyword', chartData)}
          datas={pluck('volume', chartData)}
        />
      </Box>
    </Box>
  )
}

export default SearchVolumeContianer
