import { useState, useCallback, ChangeEvent } from 'react'
import includes from 'ramda/es/includes'
import append from 'ramda/es/append'
import remove from 'ramda/es/remove'
import indexOf from 'ramda/es/indexOf'

const useCheckboxGroup = (
  initialValue: string[],
): [string[], any, (val: string
) => boolean] => {
  const [checkboxs, setCheckboxs] = useState<string[]>(initialValue)
  const isChecked = useCallback((value: string) => includes(value, checkboxs), [
    checkboxs,
  ])

  const onCheckChange = useCallback(
    (e: ChangeEvent<any>) => {
      const { value } = e.target

      let newCheckbox: string[] = checkboxs
      if (!isChecked(value)) {
        newCheckbox = append<string>(value, checkboxs)
      } else if (checkboxs.length > 1) newCheckbox = remove<string>(indexOf(value, checkboxs), 1, checkboxs)
      setCheckboxs(newCheckbox)
    },
    [checkboxs, setCheckboxs, isChecked],
  )

  return [checkboxs, onCheckChange, isChecked]
}

export default useCheckboxGroup
