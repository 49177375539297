import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 5,
  },
  img: {
    width: 140,
  },
})

const InlineLogo: FC = () => {
  const styles = useStyles()

  return (
    <Typography
      className={styles.logo}
      variant='h5'
      noWrap
    >
      <img
        src='/logo.png'
        alt='logo'
        className={styles.img}
      />
    </Typography>
  )
}

export default InlineLogo
