import { useState, ChangeEvent, useCallback } from 'react'

const useInput = <T, >(initialValue: T) => {
  const [value, setValue] = useState<T>(initialValue)

  const onChange = useCallback(
    (e: ChangeEvent<any>) => {
      setValue(e.target.value)
    },
    [setValue],
  )
  return { props: { value, onChange }, setValue, value }
}

export default useInput
