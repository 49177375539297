import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import HighChart from '../HighChart'

type DeviceProgressChartProps = {
  months: string[]
  datas: SeriesOptionsType[]
  chartType: string
  stacking: undefined | 'normal' | 'percent'
}

const buildOption = ({
  months,
  datas,
  chartType,
  stacking,
}: DeviceProgressChartProps): Highcharts.Options => ({
  chart: {
    type: chartType,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: months,
    crosshair: true,
  },
  yAxis: {
    min: 0,
    max: chartType === 'column' && stacking ? 100 : null,
    title: {
      text: '검색량',
    },
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat:
      chartType === 'column' && stacking
        ? '{series.name}: {point.y:,.0f}%'
        : '{series.name}: {point.y:,.0f}',
  },
  plotOptions: {
    series: {
      stacking,
    },
  },

  series: datas,
})

const DeviceProgressChart: FC<DeviceProgressChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default DeviceProgressChart
