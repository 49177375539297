import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { PageHeader } from '@components/common'
import {
  BusinessCategoryContainer,
  MonthlyContainer,
  TableSection,
} from '@containers/businessKeyword'
import Apps from '@material-ui/icons/Apps'

type BusinessKeywordMonthlyProps = {}

const BusinessKeywordMonthly: FC<BusinessKeywordMonthlyProps> = () => (
  <Box>
    <PageHeader icon={<Apps />}>업종별 키워드 분석</PageHeader>
    <Paper>
      <Box p={2}>
        <BusinessCategoryContainer />
      </Box>
    </Paper>
    <Box mt={8}>
      <TableSection>
        <MonthlyContainer />
      </TableSection>
    </Box>
  </Box>
)

export default BusinessKeywordMonthly
