import { useEffect } from 'react'
import { IDownloadFieldsFragment } from '@generated/graphql'
import download from '@lib/download'

export const useDownload = (data?: IDownloadFieldsFragment) => {
  useEffect(() => {
    if (!data) return

    const { filename, url } = data
    download(url, filename)
  }, [data])
}
