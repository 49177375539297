import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import defaultTo from 'ramda/es/defaultTo'
import HighChart from '../HighChart'

type SeriesData = {
  name: string
  data: any[]
}

type StackingBarChartProps = {
  categories: string[]
  datas: SeriesData[]
  colors?: string[]
}

const DEFAULT_COLORS = ['rgb(124, 181, 236)', 'rgb(67, 67, 72)'].reverse()

const dataLabelOptions = [
  {
    align: 'left',
    x: 5,
  },
  {
    align: 'right',
    x: -5,
  },
].reverse()

type ExtraDataLabelOption = {
  align?: 'left' | 'right'
  x?: number
}

const buildSeries = (data: SeriesData, index: number, colors?: string[]): SeriesOptionsType => ({
  ...data,
  type: 'bar',
  color: colors ? colors[index]: DEFAULT_COLORS[index],
  dataLabels: {
    enabled: true,
    rotation: 0,
    color: '#FFFFFF',
    align: 'left',
    format: '{point.y:,.0f}%',
    style: {
      fontSize: '12px',
    },
    ...(defaultTo({}, dataLabelOptions[index]) as ExtraDataLabelOption),
  },
})

const buildOption = ({
  categories,
  datas,
  colors
}: StackingBarChartProps): Highcharts.Options => ({
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories,
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: '%',
    },
  },
  legend: {
    reversed: true,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<b>{point.x}%</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}%<br/>',
  },
  plotOptions: {
    series: {
      stacking: 'normal',
    },
  },
  series: datas.map((v: SeriesData, i: number) => buildSeries(v, i, colors)),
})

const StackingBarChart: FC<StackingBarChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default StackingBarChart
