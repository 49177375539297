import { TotalVolumeGroup } from '@lib/utils/keyword'
import { renameKeys } from '@lib/utils/function'
import { Gender } from 'types/api'

export const genderMap = {
  [Gender.MALE]: '남성',
  [Gender.FEMALE]: '여성',
}

export const mapGenderKey = (groupedVolume: TotalVolumeGroup) => renameKeys<number>(genderMap, groupedVolume)
