import React, { FC } from 'react'
import { useSnackbar } from 'notistack'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/modules'
// import { logout } from '../../store/modules/auth'

const AuthExpired: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  // const dispatch = useDispatch()
  const { status } = useSelector((state: RootState) => state.auth)

  if (status === 'expired') {
    enqueueSnackbar('로그인 시간이 만료되었습니다.', { variant: 'error' })
    return <Redirect to='/login' />
  }
  return null
}

export default AuthExpired
