import { CHANNEL_MAP } from '@common/constant'
import {
  IChannel,
  ISearchEngineDataType,
  ISearchEngineSummaryType,
} from '@generated/graphql'
import pluck from 'ramda/es/pluck'
import sum from 'ramda/es/sum'
import sortBy from 'ramda/es/sortBy'

type ChartType = 'bar'
export type SortType =
  | 'total'
  | IChannel.Youtube
  | IChannel.Google
  | IChannel.Searchad

export const getCategories = (data: ISearchEngineSummaryType[]) => pluck('name', data)

export const sortData = (
  sortType: SortType,
  engineData: ISearchEngineSummaryType[],
) => sortBy(
  ({ data }) => -sum(pluck(
    'volume',
    sortType === 'total'
      ? data
      : data.filter(({ channel }) => channel === sortType),
  ).filter((v) => v) as number[]),
  engineData,
)

export const generateChartData = (
  channels: IChannel[],
  channelData: ISearchEngineDataType[][],
) => channels.map((c) => ({
  type: 'bar' as ChartType,
  name: CHANNEL_MAP[c],
  data: channelData.map((data) => data.filter((v) => v.channel === c).map((v) => v.volume),),
}))
