import React, { FC, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Footer from './Footer'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundImage: 'url(/assets/img/banner.jpg)',
    backgroundSize: 'cover',
  },
  contents: {
    height: 'calc(100% - 110px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 30,
    background: 'rgba(1,1,1,.4)',
  },
})

type CenterFormTemplateProps = {
  children: ReactNode
}

const CenterFormTemplate: FC<CenterFormTemplateProps> = ({ children }) => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <div className={styles.contents}>{children}</div>
      <Footer />
    </div>
  )
}

export default CenterFormTemplate
