import React, { FC } from 'react'
import SettingsEthernet from '@material-ui/icons/SettingsEthernet'
import Helmet from 'react-helmet'
import { KeywordCompareContainer } from '@containers/keywordCompare'
import { PageHeader } from '@components/common'

const KeywordCompare: FC = () => (
  <div>
    <Helmet>
      <title>WIDETREND | 검색어 비교 분석</title>
    </Helmet>
    <PageHeader icon={<SettingsEthernet />}>검색어 비교 분석</PageHeader>
    <KeywordCompareContainer />
  </div>
)

export default KeywordCompare
