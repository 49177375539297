import { useState, useMemo, useEffect } from 'react'
import { KeywordDailyGroup } from '@models/keyword'
import { getDateKeys } from '@components/dailyTrend/WeekdaySection/util'
import moment from 'moment'
import head from 'ramda/es/head'
import last from 'ramda/es/last'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type DateRange = [moment.Moment, moment.Moment]

type FocusInput = 'startDate' | 'endDate' | null

const convertMomentObject = (date?: string) => {
  if (!date) return moment()
  return moment(date, 'YYYY-MM-DD')
}

type HooksState = {
  limit: DateRange
  uniqDates: string[]
  datePickerInput: [FocusInput, any]
  datePicker: [DateRange, any]
  deviceFilter: [string[], any, any]
}

const useGroupDaily = (groupDailys: KeywordDailyGroup[]): HooksState => {
  const uniqDates = useMemo(() => getDateKeys(groupDailys), [groupDailys])
  const [limitStart, limitEnd] = useMemo(
    () => [
      convertMomentObject(head(uniqDates)),
      convertMomentObject(last(uniqDates)),
    ],
    [uniqDates],
  )

  const [focusInput, setFocusInput] = useState<FocusInput>(null)
  const [[startDate, endDate], setDateRange] = useState<DateRange>([
    limitStart,
    limitEnd,
  ])

  useEffect(() => {
    setDateRange([limitStart, limitEnd])
  }, [limitStart, limitEnd, setDateRange])

  const [devices, onChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )

  return {
    uniqDates,
    limit: [limitStart, limitEnd],
    datePickerInput: [focusInput, setFocusInput],
    datePicker: [[startDate, endDate], setDateRange],
    deviceFilter: [devices, onChange, isChecked],
  }
}

export default useGroupDaily
