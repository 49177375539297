import { KeywordDailyGroup, KeywordRecord } from '@models/keyword'
import {
  getUniqDates,
  filterDevices,
  totalVolumeByGroup,
  TotalVolumeGroup,
} from '@lib/utils/keyword'
import { isBetween } from '@lib/utils/date'
import { pipe, map, flatten, pluck, filter, defaultTo } from 'ramda'
import { groupKeywordName } from '@components/keywordCompare/util'
import moment from 'moment'

export const getDateKeys = (groups: KeywordDailyGroup[]) => getUniqDates(flatten(pluck('daily', groups)))

const filterDates = ([start, end]: [moment.Moment, moment.Moment]) => (
  months: KeywordRecord[],
) => filter(
  (m: KeywordRecord) => isBetween(moment(m.date, 'YYYY-MM-DD'), start, end),
  months,
)

export const processData = (
  groups: KeywordDailyGroup[],
  devices: string[],
  range: [moment.Moment, moment.Moment],
) => pipe(
  map(({ keywords, daily }: KeywordDailyGroup) => ({
    keyword: groupKeywordName(keywords),
    months: pipe(
      filterDevices<KeywordRecord>(devices),
      filterDates(range),
    )(daily),
  })),
  map(({ keyword, months }) => ({
    keyword,
    data: totalVolumeByGroup<KeywordRecord>('date', months),
  })),
)(groups)

export const mapDataMonthKeys = (
  data: TotalVolumeGroup,
  dateKeys: string[],
): (number | null
)[] => map<string, number | null>(
  (date: string) => defaultTo(null, data[date]),
  dateKeys,
)

export const mapChartData = (
  dates: string[],
  processResult: { keyword: string; data: TotalVolumeGroup }[],
) => map(
  ({ keyword, data }) => ({
    name: keyword,
    data: mapDataMonthKeys(data, dates),
  }),
  processResult,
)
