import React, { FC, useMemo } from 'react'
import SearchDataDownload from '@containers/download/SearchDataDownload'
import { SearchForm, useSearchForm } from '@components/common'
import { DailyTrendContent } from '@components/dailyTrend'
import {
  IPeriodTypes,
  IFetchDailyDataQuery,
  useFetchDailyDataLazyQuery,
  IDataTypes
} from '@generated/graphql'
import useSearchFormSubmit from '@hooks/useSearchFormSubmit'
import NoSearchResult from '@components/common/NoSearchResult'
import flatten from 'ramda/es/flatten'

const getEmptyKeywords = (keywordGroup?: IFetchDailyDataQuery['dailyGroupData']): string[] => {
  if (!keywordGroup || keywordGroup.length === 0) return []
  return flatten(keywordGroup?.map((data) => data?.emptyKeywords?.filter(Boolean) ?? [])) as string[]
}

const getTotalKeywordsLength = (keywordGroup?: IFetchDailyDataQuery['dailyGroupData']) => {
  if (!keywordGroup || keywordGroup.length === 0) return []
  return flatten(keywordGroup?.map((data) => data?.keywords ?? [])).length
}

const HelpBlock = () => (
  <ul>
    <li>
      비교하고자 하는 검색어를 입력해주세요. 최대 10까지 입력이 가능합니다.
    </li>
    <li>
      검색 팁) 콤마(,)로 키워드 그룹을 묶을 수 있고, 줄바꿈(Enter)으로 비교대상
      그룹을 나눌 수 있습니다.
    </li>
  </ul>
)

const DailyTrendContentContainer: FC = () => {
  const [fetch, { data, loading }] = useFetchDailyDataLazyQuery()
  const onSubmit = useSearchFormSubmit({
    onSearch: (periodType: IPeriodTypes, keywordGroups: string[][]) => fetch({
      variables: {
        periodType,
        keywords: keywordGroups,
      }
    }),
  })

  const [form, onChange] = useSearchForm({ onSubmit })
  const emptyKeywords = useMemo(() => getEmptyKeywords(data?.dailyGroupData), [data])

  const contents = useMemo(() => {
    if (!data?.dailyGroupData) return null
    return <DailyTrendContent groups={data.dailyGroupData} />
  }, [data])

  const downloadSection = useMemo(() => {
    const isAllEmpty = getTotalKeywordsLength(data?.dailyGroupData) === emptyKeywords.length
    if (!data?.dailyGroupData || isAllEmpty) return null
    return <SearchDataDownload
      {...form}
      dataType={IDataTypes.Daily}
    />
  }, [data, emptyKeywords, form])

  return (
    <>
      <SearchForm
        form={form}
        placeholder='한 개 이상의 키워드를 콤마(,)로 구분하여 한줄로 입력해주세요'
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loading}
        helpBlock={<HelpBlock />}
        description="(2016년 ~ 전일)"
        condition
        grouping
      />
      {emptyKeywords?.length > 0 && <NoSearchResult keywords={emptyKeywords} />}
      {contents}
      {downloadSection}
    </>
  )
}

export default DailyTrendContentContainer
