import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
  },
  headerIcon: {
    marginRight: 5,
    verticalAlign: 'middle',
  },
})

type PageHeaderProps = {
  children: any
  helpblock?: string
  icon?: any
}

const PageHeader: FC<PageHeaderProps> = ({ icon, helpblock, children }) => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <Typography variant='h5'>
        <span className={styles.headerIcon}>{icon}</span>
        {children}
      </Typography>
      {helpblock && (
        <Typography
          variant='body2'
          color='textSecondary'
        >
          {helpblock}
        </Typography>
      )}
    </div>
  )
}

export default PageHeader
