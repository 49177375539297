import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import HighChart from '../HighChart'

type NullableNumber = number | null

type SeriesData = {
  name: string
  data: NullableNumber[]
}

type WeekdayChartProps = {
  categories: string[]
  datas: SeriesData[]
}

const buildSeries = (data: SeriesData): SeriesOptionsType => ({
  ...data,
  type: 'column',
  dataLabels: {
    enabled: true,
    format: '{point.y:,.1f}%',
    style: {
      fontSize: '12px',
    },
  },
})

const buildOption = ({
  categories,
  datas,
}: WeekdayChartProps): Highcharts.Options => ({
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories,
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: '검색량(%)',
    },
  },

  legend: {
    enabled: true,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.1f}%<br/>',
  },
  series: datas.map((v: SeriesData) => buildSeries(v)),
})

const WeekdayChart: FC<WeekdayChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default WeekdayChart
