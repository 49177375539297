import React, {
  FC,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import {
  IBusinessKeywordMonthlyOrderField,
  IOrderDirection,
  useFetchBusinessKeywordMonthlyLazyQuery,
} from '@generated/graphql'
import Button from '@material-ui/core/Button'
import { useQueryParams } from '@hooks/useQueryParams'
import { DataTable, DataTableHandle } from '@components/businessKeyword'
import { Select, SelectItem } from '@components/common'
import Box from '@material-ui/core/Box'
import isEmpty from 'ramda/es/isEmpty'
import BusinessKeywordDownload from '@containers/download/BusinessKeywordDownload'
import useContainerState from './useContainerState'
import useColumns from './useColumns'
import { getBaseMonths } from './util'
// import Checkbox from '@material-ui/core/Checkbox'

type MonthlyContainerProps = {}

const orderFields = [
  {
    value: IBusinessKeywordMonthlyOrderField.Total,
    label: '검색량 높은 순',
  },
  {
    value: IBusinessKeywordMonthlyOrderField.MobileRatio,
    label: 'Mobile 비중 높은 순',
  },
  {
    value: IBusinessKeywordMonthlyOrderField.PcRatio,
    label: 'PC 비중 높은 순',
  },
]

const baseMonths = getBaseMonths()
const MonthlyContainer: FC<MonthlyContainerProps> = () => {
  const tableRef = useRef<DataTableHandle>(null)
  const queryParams = useQueryParams()
  const {
    state: {
      pageIndex,
      pageSize,
      orderField,
      baseMonth,
      selectedRowPaths
    },
    onOrderFieldChange,
    onBaseMonthChange,
    onChangePageInfo,
    onSelectionChange,
    onKeywordCompare
  } = useContainerState()
  const columns = useColumns({ pageIndex, pageSize })

  const [depth1, depth2] = useMemo(() => {
    if (isEmpty(queryParams)) return []
    const { depth1, depth2 } = queryParams
    return [depth1, depth2]
  }, [queryParams])


  const [fetch, { data }] = useFetchBusinessKeywordMonthlyLazyQuery()

  useEffect(() => {
    if (tableRef.current) { tableRef.current.resetSelectedRows() }
  }, [depth1, depth2, baseMonth, tableRef])

  useEffect(() => {
    if (depth1 && depth2) {
      fetch({
        variables: {
          category: {
            main: depth1,
            sub: depth2,
          },
          date: baseMonth,
          page: {
            no: pageIndex + 1,
            size: pageSize,
          },
          orderBy: {
            direction: IOrderDirection.Desc,
            field: orderField,
          },
        },
      })
    }
  }, [fetch, depth1, depth2, baseMonth, pageIndex, pageSize, orderField])

  const pageCount = data?.businessKeywordsMonthly?.totalCount ?? 1
  const list = data?.businessKeywordsMonthly?.items ?? []

  return (
    <div>
      <Box
        mb={2}
        textAlign='right'
      >
        <SelectItem label='기준월'>
          <Select
            value={baseMonth}
            options={baseMonths.map((v: string) => ({ value: v, label: v }))}
            onChange={onBaseMonthChange}
            style={{ marginRight: 5 }}
          />
        </SelectItem>
        <Select
          value={orderField}
          options={orderFields}
          onChange={onOrderFieldChange}
        />
      </Box>
      <DataTable
        ref={tableRef}
        {
        ...{
          columns,
          onChangePageInfo,
          onSelectionChange,
          pageCount,
          data: list
        }
        }
        actions={
          <div style={{ display: 'flex' }}>
            {
              Object.keys(selectedRowPaths || {}).length > 0 && (
                <Button
                  color='default'
                  variant='contained'
                  style={{ marginRight: 10 }}
                  onClick={onKeywordCompare}
                >
                키워드 비교하기
                </Button>
              )
            }
            {
              list.length > 0 && (
                <BusinessKeywordDownload
                  {
                  ...{
                    category: {
                      main: depth1,
                      sub: depth2
                    },
                    date: baseMonth
                  }
                  }
                />
              )
            }
          </div>
        }
      />
    </div>
  )
}

export default MonthlyContainer
