import React, { FC } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { GroupStatData } from '@models/keyword'
import { AgeWeightChart } from '@widetrend/ui/components/chart/keywordCompare'
import { DeviceFilter } from '@components/common'
import {
  processData,
  ageMap,
  ages,
} from '@components/keywordCompare/AgeWeightSection/util'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import sortBy from 'ramda/es/sortBy'
import pluck from 'ramda/es/pluck'
import map from 'ramda/es/map'
import useInput from '@hooks/useInput'
import Box from '@material-ui/core/Box'
import {
  ProcessDataResult,
  mapCategoriesToChartData,
} from '@components/keywordCompare/util'
import { AgeGroup } from 'types/api'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type AgeWeightContainerPrpos = {
  groupStats: GroupStatData[]
}

enum SortType {
  Input = 'input',
  Volume = 'volume',
  Elementary = 'elementary',
  MiddleAndHigh = 'middleAndHigh',
  Early20s = 'early20s',
  Old20s = 'old20s',
  All30s = '30s',
  All40s = '40s',
  All50s = '50s',
}

type SortItem = {
  label: string
  value: SortType
}
const sortList = [
  {
    label: '입력순',
    value: SortType.Input,
  },
  {
    label: '검색량순',
    value: SortType.Volume,
  },
  {
    label: '초등학생이하 비중높은 순',
    value: SortType.Elementary,
  },
  {
    label: '중고등학생 비중높은 순',
    value: SortType.MiddleAndHigh,
  },
  {
    label: '20대초반 비중높은 순',
    value: SortType.Early20s,
  },
  {
    label: '20대후반 비중높은 순',
    value: SortType.Old20s,
  },
  {
    label: '30대 비중높은 순',
    value: SortType.All30s,
  },
  {
    label: '40대 비중높은 순',
    value: SortType.All40s,
  },
  {
    label: '50대이상 비중높은 순',
    value: SortType.All50s,
  },
]

const sortFuncMap = {
  [SortType.Volume]: ({ total }: ProcessDataResult) => -total,
  [SortType.Elementary]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup._TWELVE]],
  [SortType.MiddleAndHigh]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup.THIRTEEN__NINETEEN]],
  [SortType.Early20s]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup.TWENTY_ZERO__FOUR]],
  [SortType.Old20s]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup.TWENTY_FIVE__NINE]],
  [SortType.All30s]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup.THIRTY_ZERO__NINE]],
  [SortType.All40s]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup.FOURTY_ZERO__NINE]],
  [SortType.All50s]: ({ data }: ProcessDataResult) => -data[ageMap[AgeGroup.FIFTY_]],
}

const AgeWeightContainer: FC<AgeWeightContainerPrpos> = ({ groupStats }) => {
  const [devices, onChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )
  const sort = useInput<SortType>(SortType.Input)

  let processResult = processData(groupStats, devices)

  if (sort.value !== SortType.Input) processResult = sortBy(sortFuncMap[sort.value], processResult)

  const chartData = mapCategoriesToChartData(ages, processResult)

  return (
    <Box>
      <Box>
        <DeviceFilter
          onChange={onChange}
          isChecked={isChecked}
        />
        <Select {...sort.props}>
          {map(
            ({ value, label }: SortItem) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}{' '}
              </MenuItem>
            ),
            sortList,
          )}
        </Select>
      </Box>
      <Box>
        <AgeWeightChart
          categories={pluck('keyword', processResult)}
          datas={chartData}
        />
      </Box>
    </Box>
  )
}

export default AgeWeightContainer
