import React, { FC } from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { HeaderCell, SummaryCell } from './StyledCell'

export const TableHeader = () => (
  <TableHead>
    <TableRow>
      <HeaderCell align='right'>나이대</HeaderCell>
      <HeaderCell align='right'>남성</HeaderCell>
      <HeaderCell align='right'>여성</HeaderCell>
      <HeaderCell align='right'>합계</HeaderCell>
    </TableRow>
  </TableHead>
)

type SummaryProps = {
  data: any[]
}

export const Summary: FC<SummaryProps> = ({ data: [male, female, total] }) => (
  <TableRow>
    <SummaryCell align='right'>총합</SummaryCell>
    <SummaryCell align='right'>{male}</SummaryCell>
    <SummaryCell align='right'>{female}</SummaryCell>
    <SummaryCell align='right'>{total}</SummaryCell>
  </TableRow>
)

export const Colgroup = () => (
  <colgroup>
    <col width='200px' />
    <col width='150px' />
    <col width='150px' />
    <col width='150px' />
  </colgroup>
)
