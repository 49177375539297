import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { Portal } from '@components/common'
import { IPostType } from '@generated/graphql'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

type ModalProps = {
  open: boolean
  onClose: () => void
  notice?: IPostType
}

const NoticeModal: FC<ModalProps> = ({ open, onClose, notice }) => {
  if (!notice) return null

  const { title, content, updatedAt } = notice

  return (
    <Portal>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          {title}
          <Typography
            variant='body2'
            color='textSecondary'
          >
            {updatedAt}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box p={2}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color='primary'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  )
}

export default NoticeModal
