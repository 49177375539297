import { useEffect, useRef } from 'react'
import { useForm } from '@hooks/useForm'
import { IPeriodTypes } from '@generated/graphql'

type useSearchFormProps = {
  onSubmit: any
  initialValue?: FormType
}

type FormType = {
  periodType: IPeriodTypes
  keywords: string
}

type HooksReturnType = [FormType, any]

export const useSearchForm = ({ onSubmit, initialValue }: useSearchFormProps): HooksReturnType => {
  const [form, onChange] = useForm<FormType>(initialValue || {
    periodType: IPeriodTypes.Year_1,
    keywords: '',
  })
  const previousPeriodType = useRef<IPeriodTypes>(form.periodType)

  useEffect(() => {
    if (form.periodType !== previousPeriodType.current) onSubmit(form)
    previousPeriodType.current = form.periodType
  }, [form, onSubmit])

  return [form, onChange]
}
