import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { DownloadSection } from '@components/common'
import {
  IPeriodTypes,
  IKeywordRelation,
  useKeywordRelationShipDownloadLazyQuery
} from '@generated/graphql'
import { useDownload } from '../useDownload'

type Props = {
  keyword: string
  relationType: IKeywordRelation
}

const SearchDataDownload: FC<Props> = ({
  keyword,
  relationType
}) => {
  const [loadDownloadLink, { data }] = useKeywordRelationShipDownloadLazyQuery()
  useDownload(data?.downloadData)

  const handleDownload = () => {
    loadDownloadLink({
      variables: {
        input: {
          relationType,
          keywords: [keyword],
          period: IPeriodTypes.Year_1,
        }
      },
    })
  }
  return (
    <Box my={8}>
      <DownloadSection onClick={handleDownload} />
    </Box>
  )
}

export default SearchDataDownload
