import React, { FC } from 'react'
import { KeywordDailyGroup } from '@models/keyword'
import Box from '@material-ui/core/Box'
import { DailyTrendSection, WeekdaySection } from '@components/dailyTrend'
import flatten from 'ramda/es/flatten'
import pluck from 'ramda/es/pluck'
import isEmpty from 'ramda/es/isEmpty'

type DailyTrendContentProps = {
  groups: KeywordDailyGroup[]
}

const DailyTrendContent: FC<DailyTrendContentProps> = ({ groups }) => {
  const dailys = flatten(pluck('daily', groups))
  return (
    <Box>
      {!isEmpty(dailys) && (
        <>
          <Box mt={8}>
            <DailyTrendSection groupDailys={groups} />
          </Box>
          <Box mt={8}>
            <WeekdaySection groupDailys={groups} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default DailyTrendContent
