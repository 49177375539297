import { useReducer, useCallback } from 'react'

const CHANGE = 'change'
const RESET = 'reset'

type Action =
  | { type: 'change'; name: string; value: string | boolean }
  | { type: 'reset' }

function buildReducer<T>(initialState: T) {
  return function reducer(state: T, action: Action): T {
    switch (action.type) {
      case CHANGE:
        return {
          ...state,
          [action.name]: action.value,
        }
      case RESET: {
        return initialState
      }
      default:
        return state
    }
  }
}

export function useForm<T>(defaultValues: T): [T, any, any] {
  const [state, dispatch] = useReducer(
    buildReducer(defaultValues),
    defaultValues,
  )

  const onChange = useCallback((e: React.ChangeEvent<any>) => {
    dispatch({
      type: 'change',
      name: e.target.name,
      value: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    })
  }, [])

  const onReset = useCallback(() => {
    dispatch({
      type: 'reset',
    })
  }, [])

  return [state, onChange, onReset]
}
