import React, { FC } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { GroupStatData } from '@models/keyword'
import { StackingBarChart } from '@widetrend/ui/components/chart/keywordCompare'
import { DeviceFilter } from '@components/common'
import {
  processData,
  genderMap,
  genders,
} from '@components/keywordCompare/GenderWeightSection/util'
import { Gender } from 'types/api'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import sortBy from 'ramda/es/sortBy'
import pluck from 'ramda/es/pluck'
import map from 'ramda/es/map'
import useInput from '@hooks/useInput'
import Box from '@material-ui/core/Box'
import {
  ProcessDataResult,
  mapCategoriesToChartData,
} from '@components/keywordCompare/util'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type GenderWeightContainerPrpos = {
  groupStats: GroupStatData[]
}

enum SortType {
  Input = 'input',
  Volume = 'volume',
  Male = 'male',
  Female = 'female',
}

type SortItem = {
  label: string
  value: SortType
}
const sortList = [
  {
    label: '입력순',
    value: SortType.Input,
  },
  {
    label: '검색량순',
    value: SortType.Volume,
  },
  {
    label: '남자 비중높은 순',
    value: SortType.Male,
  },
  {
    label: '여자 비중높은 순',
    value: SortType.Female,
  },
]

const sortFuncMap = {
  [SortType.Volume]: ({ total }: ProcessDataResult) => -total,
  [SortType.Male]: ({ data }: ProcessDataResult) => -data[genderMap[Gender.MALE]],
  [SortType.Female]: ({ data }: ProcessDataResult) => -data[genderMap[Gender.FEMALE]],
}

const GenderWeightContainer: FC<GenderWeightContainerPrpos> = ({
  groupStats,
}) => {
  const [devices, onChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )
  const sort = useInput<SortType>(SortType.Input)

  let processResult = processData(groupStats, devices)

  if (sort.value !== SortType.Input) processResult = sortBy(sortFuncMap[sort.value], processResult)

  const chartData = mapCategoriesToChartData(genders, processResult)

  return (
    <Box>
      <Box>
        <DeviceFilter
          onChange={onChange}
          isChecked={isChecked}
        />
        <Select {...sort.props}>
          {map(
            ({ value, label }: SortItem) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}{' '}
              </MenuItem>
            ),
            sortList,
          )}
        </Select>
      </Box>
      <Box>
        <StackingBarChart
          categories={pluck('keyword', processResult)}
          datas={chartData}
          colors={['#61BCF5', '#E572B1'].reverse()}
        />
      </Box>
    </Box>
  )
}

export default GenderWeightContainer
