import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { StatRecord } from '@models/keyword'
import { numberFormat } from '@lib/utils/format'
import { AgeGroup } from 'types/api'
import sortBy from 'ramda/es/sortBy'
import { PercentCell, HeatmapCell } from './StyledCell'
import { TableHeader, Summary, Colgroup } from './TablePiece'
import { getMaxValueForEntries } from './util'
import {
  totalVolumeGroupByAgeAndGender,
  sumEntires,
  toPercentage,
  summaryEntries,
} from '../util'

type DataTablesProps = {
  month: string
  stats: StatRecord[]
}

const ageMap = {
  [AgeGroup._TWELVE]: '초등학생이하',
  [AgeGroup.THIRTEEN__NINETEEN]: '중고등학생',
  [AgeGroup.TWENTY_ZERO__FOUR]: '20대초반',
  [AgeGroup.TWENTY_FIVE__NINE]: '20대후반',
  [AgeGroup.THIRTY_ZERO__NINE]: '30대',
  [AgeGroup.FOURTY_ZERO__NINE]: '40대',
  [AgeGroup.FIFTY_]: '50대이상',
}

const DataTables: FC<DataTablesProps> = ({ month, stats }) => {
  const groupedVolumeByAgeGender = totalVolumeGroupByAgeAndGender(stats)
  const ageOrder = Object.keys(ageMap).reduce(
    (prev, k, idx) => ({ ...prev, [k]: idx }),
    {},
  )

  const ageSort = ([age]: any[]) => ageOrder[age]

  const countData = sortBy(ageSort, sumEntires(groupedVolumeByAgeGender))
  const maleCountMax = getMaxValueForEntries(countData, 1)
  const femaleCountMax = getMaxValueForEntries(countData, 2)
  const countSummary = summaryEntries(countData)

  const percentData = sortBy(
    ageSort,
    sumEntires(toPercentage(groupedVolumeByAgeGender, countSummary[2])),
  )
  const totalPercentMax = getMaxValueForEntries(percentData, 3)
  const percentSummary = summaryEntries(percentData)

  return (
    <Grid
      container
      spacing={6}
    >
      <Grid
        item
        lg={6}
        sm={12}
        xs={12}
      >
        <Box mb={1}>
          <Typography variant='subtitle1'>{month} 검색량</Typography>
        </Box>
        <Table>
          <Colgroup />
          <TableHeader />
          <TableBody>
            {countData.map(([age, male, female, total]) => (
              <TableRow key={age}>
                <TableCell align='right'>{ageMap[age]}</TableCell>
                <PercentCell
                  maxValue={maleCountMax}
                  value={male}
                  align='right'
                >
                  {numberFormat(male)}
                </PercentCell>
                <PercentCell
                  maxValue={femaleCountMax}
                  value={female}
                  align='right'
                >
                  {numberFormat(female)}
                </PercentCell>
                <TableCell align='right'>{numberFormat(total)}</TableCell>
              </TableRow>
            ))}
            <Summary data={countSummary.map((v) => numberFormat(v))} />
          </TableBody>
        </Table>
      </Grid>
      <Grid
        item
        lg={6}
        sm={12}
        xs={12}
      >
        <Box mb={1}>
          <Typography variant='subtitle1'>{month} 검색백분율</Typography>
        </Box>
        <Table>
          <Colgroup />
          <TableHeader />
          <TableBody>
            {percentData.map(([age, male, female, total]) => (
              <TableRow key={age}>
                <TableCell align='right'>{ageMap[age]}</TableCell>
                <HeatmapCell
                  opacity={male / 100}
                  align='right'
                >
                  {male.toFixed(1)}%
                </HeatmapCell>
                <HeatmapCell
                  opacity={female / 100}
                  align='right'
                >
                  {female.toFixed(1)}%
                </HeatmapCell>
                <PercentCell
                  maxValue={totalPercentMax}
                  value={total}
                  align='right'
                >
                  {total.toFixed(1)}%
                </PercentCell>
              </TableRow>
            ))}
            <Summary data={percentSummary.map((v) => `${v.toFixed(1)}%`)} />
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default DataTables
