import React, { FC, useState, useEffect, useCallback, ChangeEvent } from 'react'
import { CategoryForm } from '@components/businessKeyword'
import { Category } from '@models/businessKeyword'
import { useLocation, useHistory } from 'react-router-dom'
import { useFetchCategoriesQuery } from '@generated/graphql'
import { useQueryParams } from '@hooks/useQueryParams'
import qs from 'qs'
import removeNullProperty from '@lib/removeNullProperty'
import { actions } from '@store/modules/businessKeyword'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'

type BusinessCategoryContainerProps = {}

const findCategory = (categories: Category[], id: string):Category | undefined => categories.find((c: Category) => c.id === id)

const BusinessCategoryContainer: FC<BusinessCategoryContainerProps> = () => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const { data } = useFetchCategoriesQuery()

  const [categoryDepth1, setCategoryDepth1] = useState<string>('')
  const [categoryDepth2, setCategoryDepth2] = useState<string>('')
  const [depth2List, setDepth2List] = useState<Category[]>([])

  const depth1List: Category[] = data?.categories ?? []

  const handle1DpethClick = useCallback(
    (e: ChangeEvent<any>) => {
      const category = findCategory(depth1List, e.target.value)

      if (category) {
        setCategoryDepth1(category.id)
      }
    },
    [depth1List],
  )

  const handle2DepthClick = useCallback(
    (e: ChangeEvent<any>) => {
      const category = findCategory(depth2List, e.target.value)

      if (category) {
        setCategoryDepth2(category.id)
      }
    },
    [depth2List],
  )

  const handleSubmit = useCallback(() => {
    const queryString = qs.stringify(
      removeNullProperty(
        Object.assign(queryParams, {
          depth1: categoryDepth1,
          depth2: categoryDepth2,
        }),
      ),
    )
    history.push(`${pathname}?${queryString}`)
  }, [categoryDepth1, categoryDepth2, history, pathname, queryParams])

  useEffect(() => {
    const category = findCategory(depth1List, categoryDepth1)
    if (category !== undefined && depth2List) {
      const children = category.children ?? []
      setDepth2List(children)
      if (children.length > 0) setCategoryDepth2(children[0].id)
    }
  }, [categoryDepth1, depth1List, depth2List])


  useEffect(() => {
    if (categoryDepth1 === '' && depth1List && depth1List.length > 0) {
      setCategoryDepth1(depth1List[0].id)
    }
    if (categoryDepth2 === '' && depth2List && depth2List.length > 0) {
      setCategoryDepth2(depth2List[0].id)
    }
  }, [categoryDepth1, categoryDepth2, depth1List, depth2List])

  useEffect(() => {
    const { depth1, depth2 } = queryParams

    if (categoryDepth1 === '' && depth1 && depth1List) setCategoryDepth1(depth1)
    if (categoryDepth2 === '' && depth2 && depth2List) setCategoryDepth2(depth2)

    if (depth1 && depth2) {
      dispatch(actions.setCategories({ depth1: findCategory(depth1List, depth1), depth2: findCategory(depth2List, depth2) }))
    }

    if (isEmpty(queryParams)) {
      dispatch(actions.clearCategories())
    }
  }, [queryParams, depth1List, depth2List, categoryDepth1, categoryDepth2, dispatch])


  const formProps = {
    depth1List,
    depth2List,
    handle1DpethClick,
    handle2DepthClick,
    handleSubmit,
    selectDepth1: categoryDepth1,
    selectDepth2: categoryDepth2,
  }

  return <CategoryForm {...formProps} />
}

export default BusinessCategoryContainer
