import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { WeekdayContainer } from '@containers/dailyTrend'
import { KeywordDailyGroup } from '@models/keyword'

type WeekdaySectionProps = {
  groupDailys: KeywordDailyGroup[]
}

const WeekdaySection: FC<WeekdaySectionProps> = ({ groupDailys }) => (
  <Box>
    <WeekdayContainer groupDailys={groupDailys} />
  </Box>
)

export default WeekdaySection
