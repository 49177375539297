import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { DrawerContainer, HeaderContainer } from '@containers/common'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import ReactGA from 'react-ga'
import { UserLoader, AuthExpired } from '@containers/auth'

import { Footer } from '@components/common'
import Routes from './Routes'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  main: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  content: {
    minHeight: 'calc(100vh - 110px)',
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}))

const MainLayout: FC = () => {
  const styles = useStyles()
  const { pathname, search } = useLocation()
  ReactGA.pageview(`${pathname}${search}`)
  return (
    <div className={styles.root}>
      <HeaderContainer />
      <DrawerContainer />
      <main className={styles.main}>
        <div className={styles.content}>
          <div className={styles.toolbar} />
          <Routes />
          <AuthExpired />
          <UserLoader />
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default MainLayout
