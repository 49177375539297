import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { DownloadSection } from '@components/common'
import {
  IDataTypes,
  IPeriodTypes,
  useFetchExcelDownloadLinkLazyQuery,
} from '@generated/graphql'
import { useDownload } from '../useDownload'

type SearchDataDownloadProps = {
  dataType: IDataTypes
  periodType: IPeriodTypes
  keywords: string
}

const SearchDataDownload: FC<SearchDataDownloadProps> = ({
  dataType,
  periodType,
  keywords,
}) => {
  const [loadDownloadLink, { data }] = useFetchExcelDownloadLinkLazyQuery()
  useDownload(data?.downloadData)

  const handleDownload = () => {
    let keywordArray = keywords

    if (keywords.indexOf('\n') !== -1) {
      keywordArray = keywords.split('\n').join(',')
    }

    loadDownloadLink({
      variables: {
        input: {
          dataType,
          period: periodType,
          keywords: keywordArray.split(','),
        }
      },
    })
  }

  return (
    <Box my={8}>
      <DownloadSection onClick={handleDownload} />
    </Box>
  )
}

export default SearchDataDownload
