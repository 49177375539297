import React, { FC, useState, useCallback } from 'react'
import useReactRouter from 'use-react-router'
import { useSnackbar } from 'notistack'
import { SignupForm } from '@components/signup'
import { FormErrorType } from '@common/types'
import { useForm } from '@hooks/index'
import { pickFirstError } from '@lib/formError'
import { useSignupMutation } from '@generated/graphql'
import * as yup from 'yup'

const EMAIL_MESSAGE = '이메일 형식만 입력 가능합니다.'
const REQUIRED_MESSAGE = (target) => `${target} 입력해주세요`

const signupSchema = yup.object({
  email: yup
    .string()
    .email(EMAIL_MESSAGE)
    .required(REQUIRED_MESSAGE('이메일을')),
  password: yup
    .string()
    .required(REQUIRED_MESSAGE('패스워드를'))
    .matches(/^[A-Za-z0-9+]*$/, "영문과 숫자 조합만 가능합니다")
    .min(6, "최소 6자리 이상으로 입력해주세요")
    .max(12, '패스워드는 12자를 넘길 수 없습니다.'),
  passwordCheck: yup
    .string()
    .required('패스워드를 한번 더 입력해주세요')
    .oneOf([yup.ref('password')], '패스워드와 일치하지 않습니다.'),
  name: yup
    .string()
    .required(REQUIRED_MESSAGE('이름을'))
    .max(20, '이름은 20자를 넘길 수 없습니다.'),
  affiliation: yup.string().max(20, '소속은 20자를 넘길 수 없습니다.'),
  companyNumber: yup.string(),
  extraEmail: yup
    .string()
    .email(EMAIL_MESSAGE),
  tosAgreed: yup.boolean().oneOf([true], '서비스 이용약관에 동의해주세요'),
  privatePolicyAgreed: yup
    .boolean()
    .oneOf([true], '개인정보보호정책에 동의해주세요'),
})

const validateOrder = [
  'email',
  'password',
  'passwordCheck',
  'name',
  'affiliation',
  'companyNumber',
  'extraEmail',
  'tosAgreed',
  'privatePolicyAgreed',
]

const SignupContainer: FC = () => {
  const { history } = useReactRouter()
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState<FormErrorType | undefined>(undefined)
  const [form, onChange] = useForm({
    email: '',
    password: '',
    passwordCheck: '',
    name: '',
    affiliation: '',
    companyNumber: '',
    extraEmail: '',
    tosAgreed: false,
    privatePolicyAgreed: false,
  })

  const [signup] = useSignupMutation()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      try {
        await signupSchema.validate(form, { abortEarly: false })
        signup()
      } catch (err) {
        const firstError = pickFirstError(err, validateOrder)

        if (firstError) {
          setError({
            message: firstError.message,
            field: firstError.path,
          })
          return
        }
      }

      try {
        await signup({
          variables: {
            input: {
              username: form.email,
              password: form.password,
              name: form.name,
              affiliation: form.affiliation,
              companyNumber: form.companyNumber,
              extraEmail: form.extraEmail,
              tosAgreed: form.tosAgreed,
              privacyPolicyAgreed: form.privatePolicyAgreed,
            },
          },
        })
        enqueueSnackbar(<span>인증메일이 발송되었습니다<br />이메일 인증 후, 로그인하시기 바랍니다</span>, { variant: 'success' })
        history.push('/login')
        setError(undefined)
      } catch (err) {
        setError({
          message: err.message,
          field: '',
        })
      }
    },
    [enqueueSnackbar, form, history, signup],
  )

  return (
    <SignupForm
      form={form}
      onChange={onChange}
      onSubmit={handleSubmit}
      error={error}
    />
  )
}

export default SignupContainer
