import { map, sum, pipe } from 'ramda'
import { KeywordRecord, GroupMonthData } from '@models/keyword'
import { filterDevices } from '@lib/utils/keyword'
import { groupKeywordName } from '../util'

const filteredSum = (months: KeywordRecord[], devices: string[]) => pipe(
  filterDevices<KeywordRecord>(devices),
  map<KeywordRecord, number>((m: KeywordRecord) => m.volume),
  sum,
)(months)

export const generateChartData = (
  groups: GroupMonthData[],
  filterDevices: string[],
) => pipe(
  map(({ keywords, months }: GroupMonthData) => ({
    keyword: groupKeywordName(keywords),
    volume: filteredSum(months, filterDevices),
  })),
)(groups)
