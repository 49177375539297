import React, { FC } from 'react'
import Helmet from 'react-helmet'
import FindInPage from '@material-ui/icons/FindInPage'
import { PageHeader } from '@components/common'
import { SearchEngineContainer } from '@containers/searchEngine'

const SearchEngine: FC = () => (
  <div>
    <Helmet>WIDETREND | 검색엔진 채널별 분석</Helmet>
    <PageHeader icon={<FindInPage />}>검색엔진 채널별 분석</PageHeader>
    <SearchEngineContainer />
  </div>
)

export default SearchEngine
