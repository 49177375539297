import React, { FC, memo } from 'react'
import { SearchForm, useSearchForm } from '@components/common'
import {
  useFetchSearchEngineDataLazyQuery,
  IFetchSearchEngineDataQuery,
  IPeriodTypes,
} from '@generated/graphql'
import NoSearchResult from '@components/common/NoSearchResult'
import useSearchFormSubmit from '@hooks/useSearchFormSubmit'
import { SearchEngineContent } from '@components/searchEngine'
import isEmpty from 'ramda/es/isEmpty'


const getEmptyKeywords = (engineData?: IFetchSearchEngineDataQuery["searchEngineData"]): string[] => {
  if (!engineData || engineData?.monthly.length === 0) return []
  return engineData?.monthly.filter(({ data }) => data.every(({ volumes }) => isEmpty(volumes.filter(Boolean)))).map(({ name }) => name)
}

const SearchHelpBlock = () => (
  <ul>
    <li>검색할 키워드는 한 줄로 입력해주세요</li>
    <li>
      입력한 검색어(띄어쓰기 제외)의 검색 데이터를 합하여 결과를 제공합니다.
      (네이버, 구글, 유투브 검색 기준)
    </li>
    <li>
      최대 3개의 검색어를 입력할 수 있습니다. 예)스타벅스, 스벅, starbucks,
      별다방
    </li>
  </ul>
)

type ContentProps = {
  data?: IFetchSearchEngineDataQuery
}

const Content = memo(({ data }: ContentProps) => (
  <>
    {data?.searchEngineData && (
      <SearchEngineContent data={data.searchEngineData} />
    )}
  </>
))

const SearchEngineContainer: FC = () => {
  const [fetch, { data, loading }] = useFetchSearchEngineDataLazyQuery()
  const onSubmit = useSearchFormSubmit({
    onSearch: (periodType: IPeriodTypes, keywordGroups: string[][]) => fetch({
      variables: {
        keywords: keywordGroups[0],
      }
    }),
  })

  const emptyKeywords = getEmptyKeywords(data?.searchEngineData)
  const [form, onChange] = useSearchForm({ onSubmit })
  return (
    <>
      <SearchForm
        form={form}
        placeholder='한 개 이상의 키워드를 콤마(,)로 구분하여 한줄로 입력해주세요'
        onChange={onChange}
        loading={loading}
        onSubmit={onSubmit}
        helpBlock={<SearchHelpBlock />}
      />
      {emptyKeywords.length > 0 && (<NoSearchResult keywords={emptyKeywords} />)}
      <Content data={data} />
    </>
  )
}

export default SearchEngineContainer
