import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PieChart } from '@widetrend/ui/components/chart/common'
import { CompareHelpBlock } from '@components/keyword/CompareHelpBlock'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import sum from 'ramda/es/sum'
import values from 'ramda/es/values'
import {
  TotalVolumeGroup,
  totalVolumeToPercentage,
  getMaxEntry,
} from '@lib/utils/keyword'
import { Gender } from 'types/api'
import { genderMap } from './util'

type GenderCardProps = {
  groupedVolume: TotalVolumeGroup
}

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
})

const GenderCard: FC<GenderCardProps> = ({ groupedVolume }) => {
  const styles = useStyles()

  const sumVolume = sum(values(groupedVolume))
  const percentageMap = totalVolumeToPercentage(groupedVolume, sumVolume)

  const [maxKey, maxPercent] = getMaxEntry(percentageMap)
  const minKey = Object.keys(percentageMap).find((s: string) => s !== maxKey)

  const chartData = [genderMap[Gender.MALE], genderMap[Gender.FEMALE]].map(
    (k) => [k, groupedVolume[k]],
  )

  return (
    <Paper className={styles.root}>
      <Box p={2}>
        <Typography variant='body1'>성별 비중</Typography>
        <PieChart
          name='gender'
          data={chartData}
          chartOptions={{ colors: ['#61BCF5', '#E572B1'] }}
        />
      </Box>
      <Box p={2}>
        <ul>
          <li>
            <CompareHelpBlock
              maxColumn={maxKey}
              maxPercent={maxPercent}
              minColumn={minKey}
              minPercent={minKey ? percentageMap[minKey] : undefined}
            />
          </li>
        </ul>
      </Box>
    </Paper>
  )
}

export default GenderCard
