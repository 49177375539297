import React, { FC } from 'react'
import HighChart from '../HighChart'

type SearchVolumeChartProps = {
  categories: string[]
  datas: number[]
}

const buildOption = ({
  categories,
  datas,
}: SearchVolumeChartProps): Highcharts.Options => ({
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories,
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: '검색량',
    },
  },

  legend: {
    enabled: false,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}<br/>',
  },
  series: [
    {
      type: 'bar',
      name: '검색량',
      data: datas,
      dataLabels: {
        enabled: true,
        rotation: 0,
        color: '#FFFFFF',
        align: 'right',
        format: '{point.y:,.0f}',
        x: -5,
        style: {
          fontSize: '12px',
        },
      },
    },
  ],
})

const SearchVolumeChart: FC<SearchVolumeChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default SearchVolumeChart
