import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import useReactRouter from 'use-react-router'
import { useForm } from '@hooks/index'
import { actions } from '@store/modules/auth'
import { FormErrorType } from '@common/types'
import { LoginForm } from '@components/login'
import { pickFirstError } from '@lib/formError'
import { useLoginMutation } from '@generated/graphql'
import * as yup from 'yup'

const loginSchema = yup.object({
  email: yup
    .string()
    .email('이메일 형식만 입력 가능합니다.')
    .required('이메일을 입력해주세요'),
  password: yup
    .string()
    .required('패스워드를 입력해주세요')
    .max(20, '패스워드는 20자를 넘길 수 없습니다.'),
})

const validateOrder = ['email', 'password']

const LoginContainer: FC = () => {
  const dispatch = useDispatch()
  const { history } = useReactRouter()
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState<FormErrorType | undefined>(undefined)
  const [form, onChange] = useForm({
    email: '',
    password: '',
  })

  const [login] = useLoginMutation()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      try {
        await loginSchema.validate(form, { abortEarly: false })
      } catch (err) {
        const firstError = pickFirstError(err, validateOrder)

        if (firstError) {
          setError({
            message: firstError.message,
            field: firstError.path,
          })
        }
      }

      try {
        const result = await login({
          variables: {
            username: form.email,
            password: form.password
          }
        })

        if (!result) return

        enqueueSnackbar('로그인 성공', { variant: 'success' })
        localStorage.setItem('token', result?.data?.login?.token ?? "")
        dispatch(actions.login())
        history.push('/notice')
      } catch (err) {
        setError({
          message: err.message,
          field: '',
        })
      }
    },
    [dispatch, enqueueSnackbar, form, history, login],
  )

  return (
    <LoginForm
      form={form}
      error={error}
      onChange={onChange}
      onSubmit={handleSubmit}
    />
  )
}

export default LoginContainer
