import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { DailyTrendContainer } from '@containers/dailyTrend'
import { KeywordDailyGroup } from '@models/keyword'

type DailyTrendSectionProps = {
  groupDailys: KeywordDailyGroup[]
}

const DailyTrendSection: FC<DailyTrendSectionProps> = ({ groupDailys }) => (
  <Box>
    <DailyTrendContainer groupDailys={groupDailys} />
  </Box>
)

export default DailyTrendSection
