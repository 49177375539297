import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { GroupStatData } from '@models/keyword'
import { makeStyles } from '@material-ui/core/styles'
import { Section, Strong } from '@components/common'
import { GenderWeightContainer } from '@containers/keywordCompare'

type GenderWeightSectionProps = {
  groupStats: GroupStatData[]
}

const useStyles = makeStyles({
  root: {},
})

const GenderWeightSection: FC<GenderWeightSectionProps> = ({ groupStats }) => {
  const styles = useStyles()
  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>{groupStats.length}개 검색어</Strong>의 성별
          검색 비중
        </Typography>
      }
      helpBlock='검색어의 성별 검색 비중을 표시합니다.'
    >
      <Paper className={styles.root}>
        <Box p={2}>
          <GenderWeightContainer groupStats={groupStats} />
        </Box>
      </Paper>
    </Section>
  )
}

export default GenderWeightSection
