import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles({
  root: {},
  sectionHeader: {},
  sectionBody: {},
})

type SectionProps = {
  children: any
  header: any
  helpBlock: string
}

const Section: FC<SectionProps> = ({ children, header, helpBlock }) => {
  const styles = useStyles()
  return (
    <Box
      className={styles.root}
      my={2}
    >
      <div className={styles.sectionHeader}>
        <Box my={1}>{header}</Box>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          {helpBlock}
        </Typography>
      </div>
      <Box
        mt={1}
        className={styles.sectionBody}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Section
