import React, { FC } from 'react'
import TrendingUp from '@material-ui/icons/TrendingUp'
import Helmet from 'react-helmet'
import { PageHeader } from '@components/common'
import { DailyTrendContentContainer } from '@containers/dailyTrend'

const DailyTrend: FC = () => (
  <div>
    <Helmet>
      <title>WIDETREND | 일별 검색량 추이</title>
    </Helmet>
    <PageHeader icon={<TrendingUp />}>일별 검색량 추이</PageHeader>
    <DailyTrendContentContainer />
  </div>
)

export default DailyTrend
