import React, { FC, ChangeEvent, FormEvent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import InputAdornment from '@material-ui/core/InputAdornment'
import { IKeywordRelation } from '@generated/graphql'

export type SearchFormType = {
  searchType: IKeywordRelation
  keywords: string
}

type SearchFormProps = {
  form: SearchFormType
  onSubmit: (form: SearchFormType) => any
  onChange: (e: ChangeEvent<any>) => void
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
  },
  form: {},
  leftIcon: {
    marginRight: 5,
  },
  keywordSection: {},
  conditionSection: {},
  buttonSection: {
    textAlign: 'right',
  },
  labelHelp: {
    fontSize: 12,
    marginLeft: 10,
    color: theme.palette.grey[600],
  },
  formGroup: {},
  radioGroup: {
    flexDirection: 'row',
  },
}))

export const SearchForm: FC<SearchFormProps> = ({
  form,
  onChange,
  onSubmit,
  loading,
}) => {
  const styles = useStyles()

  return (
    <Paper className={styles.root}>
      <form
        className={styles.form}
        onSubmit={(e: FormEvent) => {
          e.preventDefault()
          onSubmit(form)
        }}
      >
        <div className={styles.conditionSection}>
          <Typography variant='body2'>검색어 확장</Typography>
          <RadioGroup
            name='duration'
            value={form.searchType}
            className={styles.radioGroup}
            onChange={onChange}
          >
            <FormControlLabel
              value={IKeywordRelation.AutoComplete}
              label='자동완성어 분석'
              control={
                <Radio
                  color='primary'
                  inputProps={{ name: 'searchType' }}
                  disabled={loading}
                />
              }
            />
            <FormControlLabel
              value={IKeywordRelation.Related}
              label='연관검색어 분석'
              control={
                <Radio
                  color='primary'
                  inputProps={{ name: 'searchType' }}
                  disabled={loading}
                />
              }
            />
          </RadioGroup>
        </div>
        <div className={styles.keywordSection}>
          <TextField
            label='키워드'
            placeholder='검색할 키워드(한 개만)를 입력해주세요'
            value={form.keywords}
            onChange={onChange}
            InputProps={{
              name: 'keywords',
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
            disabled={loading}
          />
        </div>
        <Box mt={2}>
          <ul>
            <li>
              자동완성어 확장 분석 – 주제 키워드에 대해 추천되는 자동완성어를
              통해, 검색어의 확장된 관심사를 분석할 수 있습니다.
            </li>
            <li>
              연관검색어 확장 분석 – 주제 키워드의 연관 검색어를 통해, 검색어
              간의 맥락을 분석할 수 있습니다.
            </li>
            <li>
              자동완성어와 연관검색어 현황은 오늘자 네이버 검색엔진 기준 입니다.
            </li>
            <li>경우에 따라 시간이 다소 소요될 수 있습니다. (최대 1분)</li>
            <li>
              확장 검색어의 최근 1년 간 검색량을 집계하여 제공합니다.
              <br />
              이전 기간에 대한 분석을 원하시면, 단일검색어 분석 및 검색어 비교
              분석 메뉴를 이용하시기 바랍니다.
            </li>
            <li>
              검색 키워드는 한 개만 입력해주시기 바랍니다.
              <br />
              콤마(,)로 구분하여 입력 시, 첫 키워드를 주제어로 적용합니다.
            </li>
          </ul>
        </Box>
        <p className={styles.buttonSection}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
          >
            <Search className={styles.leftIcon} />
            분석
          </Button>
        </p>
      </form>
    </Paper>
  )
}

SearchForm.defaultProps = {
  loading: false,
}
