import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { GroupMonthData } from '@models/keyword'
import { makeStyles } from '@material-ui/core/styles'
import { Section, Strong } from '@components/common'
import { SearchProgressContainer } from '@containers/keywordCompare'

type SearchProgressSectionProps = {
  groupMonths: GroupMonthData[]
}

const useStyles = makeStyles({
  root: {},
})

const SearchProgressSection: FC<SearchProgressSectionProps> = ({
  groupMonths,
}) => {
  const styles = useStyles()
  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>{groupMonths.length}개 검색어</Strong>의{' '}
          검색추이 비교
        </Typography>
      }
      helpBlock='검색어의 월별 검색량 추이입니다. 범례의 키워드를 클릭하면, 키워드를 선택적으로 비교할 수 있습니다.
'
    >
      <Paper className={styles.root}>
        <Box p={2}>
          <SearchProgressContainer groupMonths={groupMonths} />
        </Box>
      </Paper>
    </Section>
  )
}

export default SearchProgressSection
