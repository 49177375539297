import React, { FC } from 'react'
import HighChart from '../HighChart'

type PieChartProps = {
  name: string
  data: any[]
  chartOptions?: any
}

const buildOption = ({
  name,
  data,
  chartOptions,
}: PieChartProps): Highcharts.Options => ({
  chart: {
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  xAxis: {},
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b><br/>{point.percentage:.1f} %',
        distance: -50,
        filter: {
          property: 'percentage',
          operator: '>',
          value: 4,
        },
      },
    },
  },
  ...chartOptions,
  series: [
    {
      name,
      data,
      type: 'pie',
      colorByPoint: true,
    },
  ],
})

const PieChart: FC<PieChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default PieChart
