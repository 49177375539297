import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'
import { IPeriodTypes } from '@generated/graphql'
import qs from 'qs'

type Form = {
  keywords: string[][]
  periodType: IPeriodTypes
}

type HookReturnType = [Form | null, (keywordGroup: string[][], periodType: IPeriodTypes) => void]

const convertPeriodType = (type: string): IPeriodTypes | null => {
  switch (type) {
    case IPeriodTypes.Year_1.toString():
      return IPeriodTypes.Year_1
    case IPeriodTypes.All.toString():
      return IPeriodTypes.All
    default:
      return null
  }
}

export default function useKeywordParams(): HookReturnType {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const form = useMemo(() => {
    const params = qs.parse(search.slice(1))
    const keywordGroup = params?.keywordGroup
    const periodType = convertPeriodType(params?.periodType)

    if (!keywordGroup || !periodType) return null

    return {
      periodType,
      keywords: keywordGroup.split('|')?.map(group => group?.split(','))
    }
  }, [search])

  const updateForm = useCallback((keywordGroup: string[][], periodType: IPeriodTypes) => {
    const _keywordGruop = keywordGroup.map((keywords: string[]) => keywords.join(',')).join('|')
    const _periodType = periodType.toString()
    history.push({
      pathname,
      search: `${qs.stringify({
        keywordGroup: _keywordGruop,
        periodType: _periodType
      })}`
    })
  }, [history, pathname])

  return [form, updateForm]
}
