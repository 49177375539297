import React, { useMemo } from 'react'
import { numberFormat } from '@lib/utils/format'
import Checkbox from '@material-ui/core/Checkbox'

type Params = {
  pageIndex: number
  pageSize: number
}
export default function useColumns({ pageIndex, pageSize }:Params) {
  const columns = useMemo(
    () => [
      {
        id: 'rowIdx',
        Header: 'No',
        width: 30,
        Cell: ({ row: { index } }) => index + 1 + pageIndex * pageSize,
      },
      {
        id: 'selection',
        Header: '선택',
        width: 30,
        Cell: ({ row }) => (
          <div>
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              style={{ padding: 0 }}
            />
          </div>
        ),
      },
      {
        Header: '검색어',
        accessor: 'keyword',
        width: 200
      },
      {
        Header: 'Mobile 검색량',
        accessor: 'mobile',
        width: 50,
        Cell: ({ cell: { value } }) => `${numberFormat(value)}`,
      },
      {
        Header: 'PC 검색량',
        accessor: 'pc',
        width: 50,
        Cell: ({ cell: { value } }) => `${numberFormat(value)}`,
      },
      {
        Header: '전일 대비 증감량',
        accessor: 'variation',
        width: 50,
        Cell: ({ cell: { value } }) => `${numberFormat(value)}`,
      },
      {
        Header: '전일 대비 증감률',
        width: 50,
        accessor: 'variationRatio',
      },
    ],
    [pageIndex, pageSize],
  )
  return columns
}
