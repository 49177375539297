import React, { FC, ReactNode } from 'react'
import blue from '@material-ui/core/colors/blue'
import { makeStyles } from '@material-ui/core/styles'
import { Link as InternalLink } from 'react-router-dom'

type LinkProps = {
  children: ReactNode
  external?: boolean
  to: string
}

const useStyles = makeStyles({
  root: {
    color: blue[200],
    textDecoration: 'none',
  },
})

const Link: FC<LinkProps> = ({ children, external, to }) => {
  const styles = useStyles()

  if (external) {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={to}
        className={styles.root}
      >
        {children}
      </a>
    )
  }

  return (
    <InternalLink
      to={to}
      className={styles.root}
    >
      {children}
    </InternalLink>
  )
}

Link.defaultProps = {
  external: false,
}

export default Link
