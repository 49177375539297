import React, { FC } from 'react'
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import max from 'ramda/es/max'

export const HeaderCell = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
}))(TableCell)

export const SummaryCell = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
}))(TableCell)

type PercentCellProps = {
  maxValue: number
  value: number
  children: any
} & TableCellProps

const percentCellStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    paddingRight: 10,
  },
  positioner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 1,
    bottom: 0,
  },
  info: {
    position: 'relative',
    zIndex: 99,
  },
  progress: {
    backgroundColor: '#E77F7D',
    height: '100%',
  },
}))

export const PercentCell: FC<PercentCellProps> = ({
  children,
  maxValue,
  value,
  ...rest
}) => {
  const styles = percentCellStyles()
  return (
    <TableCell
      {...rest}
      className={styles.root}
    >
      <div className={styles.info}>{children}</div>
      <div className={styles.positioner}>
        <div
          className={styles.progress}
          style={{ width: `${max((value / maxValue) * 100, 1)}%` }}
        />
      </div>
    </TableCell>
  )
}

const heatmapStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  info: {
    position: 'relative',
    zIndex: 99,
  },
  heatmap: {
    position: 'absolute',
    backgroundColor: '#E77F7D',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}))

type HeatmapCellProps = {
  opacity: number
  children: any
} & TableCellProps

export const HeatmapCell: FC<HeatmapCellProps> = ({
  children,
  opacity,
  ...rest
}) => {
  const styles = heatmapStyles()
  return (
    <TableCell
      {...rest}
      className={styles.root}
    >
      <div className={styles.info}>{children}</div>
      <div
        className={styles.heatmap}
        style={{ opacity }}
      />
    </TableCell>
  )
}
