import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { KeywordRecord } from '@models/keyword'
import DeviceProgressContainer from '@containers/keyword/DeviceProgressContainer'
import { numberFormat } from '@lib/utils'
import { renameKeys } from '@lib/utils/function'
import { Section, Strong } from '@components/common'
import {
  TotalVolumeGroup,
  totalVolumeByGroup,
  getUniqDates,
} from '@lib/utils/keyword'
import {
  totalVolumeGroupByDeviceAndDate,
  generateDeviceProgressData,
  getMaxEntryByDevice,
  deviceMap,
} from './util'
import DeviceCard from './DeviceCard'

const useStyles = makeStyles({
  root: {},
  paper: {
    height: '100%',
  },
})

type DeviceSectionProps = {
  monthDatas: KeywordRecord[]
}

const DeviceSection: FC<DeviceSectionProps> = ({ monthDatas }) => {
  const styles = useStyles()

  const totalVolumeByDevice = renameKeys<number>(
    deviceMap,
    totalVolumeByGroup<KeywordRecord>('device', monthDatas),
  )

  const months = getUniqDates(monthDatas)
  const volumeGroupByDeviceAndDate = renameKeys<TotalVolumeGroup>(
    deviceMap,
    totalVolumeGroupByDeviceAndDate(monthDatas),
  )

  const seriesData = generateDeviceProgressData(volumeGroupByDeviceAndDate)
  const maxEntryByDevice = getMaxEntryByDevice(volumeGroupByDeviceAndDate)

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>검색어</Strong>의{' '}
          <Strong color='secondary'>디바이스별</Strong> 비중 및 검색 추이
        </Typography>
      }
      helpBlock='디바이스별 검색량 추이를 나타냅니다. (검색어별로 수집 시작 기간이 다를 수 있습니다.)'
    >
      <Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
          >
            <DeviceCard
              groupedVolume={totalVolumeByDevice}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
          >
            <Paper className={styles.paper}>
              <Box
                p={2}
                pb={0}
              >
                <Typography variant='body1'>디바이스별 검색 추이</Typography>
                <DeviceProgressContainer
                  months={months}
                  seriesData={seriesData}
                />
              </Box>
              <Box
                p={2}
                pt={0}
              >
                <ul>
                  {maxEntryByDevice.map(([device, [maxMonth, maxVolume]]) => (
                    <li key={device}>
                      {device} 검색량의 가장 높았던 월:{' '}
                      <Strong color='primary'>{maxMonth}</Strong>(
                      <Strong color='primary'>{numberFormat(maxVolume)}</Strong>
                      )회
                    </li>
                  ))}
                </ul>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}

export default DeviceSection
