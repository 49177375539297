import React, { useEffect, useCallback, ChangeEvent, useImperativeHandle, Ref, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTable, useRowSelect, usePagination } from 'react-table'
import Box from '@material-ui/core/Box'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

type fetchDataParams = {
  pageIndex: number
  pageSize: number
}

type DataTableProps = {
  columns: any
  data: any
  onChangePageInfo: ({ pageIndex, pageSize }: fetchDataParams) => any
  onSelectionChange: (selectedRowPaths: string[]) => void
  pageCount: number
  actions: any
}

const useStyles = makeStyles(() => ({
  tableWrapper: {
    maxHeight: 600,
    overflow: 'auto',
  },
  table: {
    tableLayout: 'fixed'
  },
  center: {
    textAlign: 'center'
  }
}))

export interface DataTableHandle {
  resetSelectedRows: () => void
}

function Table({
  columns,
  data,
  onChangePageInfo,
  onSelectionChange,
  pageCount,
  actions,
}: DataTableProps, ref: Ref<DataTableHandle>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    setPageSize,
    rows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      pageCount,
      initialState: { pageIndex: 0, pageSize: 50 },
      manualPagination: true,
      getRowId: (row: any) => row.keyword,
      autoResetSelectedRows: false
    },
    useRowSelect,
    usePagination,
  )

  useImperativeHandle<DataTableHandle, DataTableHandle>(ref, () => ({
    resetSelectedRows: () => {
      toggleAllRowsSelected(false)
    }
  }), [toggleAllRowsSelected])

  const styles = useStyles()

  useEffect(() => {
    onChangePageInfo({ pageIndex, pageSize })
  }, [pageIndex, pageSize, onChangePageInfo])

  useEffect(() => {
    onSelectionChange(selectedRowIds)
  }, [onSelectionChange, selectedRowIds])

  const handlePageIndexChange = useCallback(
    (e: any, page: number) => {
      if (page >= 0 && page <= pageCount) gotoPage(page)
    },
    [gotoPage, pageCount],
  )

  const handlePageSizeChange = useCallback(
    (e: ChangeEvent<any>) => {
      const value = Number(e.target.value)
      setPageSize(value)
    },
    [setPageSize],
  )

  return (
    <Box>
      <Box className={styles.tableWrapper}>
        <MuiTable
          stickyHeader
          className={styles.table}
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{ width: column?.width || 100 }}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row)
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })
                  }
                </>
              ): (
                <TableRow>
                  <TableCell className={styles.center} colSpan={columns.length} />
                </TableRow>
              )
            }
          </TableBody>
        </MuiTable>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Box pt={2}>{actions}</Box>
        <Box>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 300]}
            component='div'
            count={pageCount}
            rowsPerPage={pageSize}
            page={pageIndex}
            onChangePage={handlePageIndexChange}
            onChangeRowsPerPage={handlePageSizeChange}
            labelRowsPerPage='결과 표시'
          />
        </Box>
      </Box>
    </Box>
  )
}

export const DataTable = forwardRef<DataTableHandle, DataTableProps>(Table)
