import React, { FC, useCallback } from 'react'
import { DownloadSection } from '@components/common'
import { useBusinessKeywordDownloadLazyQuery } from '@generated/graphql'
import { useDownload } from '../useDownload'

interface Props {
  category: {
    main: string
    sub: string
  }
  date: string
}

const BusinessKeywordDownload: FC<Props> = ({ category, date }) => {
  const [loadDownloadLink, { data }] = useBusinessKeywordDownloadLazyQuery()
  useDownload(data?.downloadData)

  const handleDownload = useCallback(() => {
    loadDownloadLink({
      variables: {
        input: {
          category,
          date
        }
      },
    })
  }, [category, date, loadDownloadLink])

  return (
    <DownloadSection onClick={handleDownload} />
  )
}

export default BusinessKeywordDownload
