import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Mail from '@material-ui/icons/Mail'
import Check from '@material-ui/icons/Check'
import Lock from '@material-ui/icons/Lock'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Business from '@material-ui/icons/Business'
import PermContactCalendar from '@material-ui/icons/PermContactCalendar'
import ContactMail from '@material-ui/icons/ContactMail'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { errorField, removeGqlErrorString } from '../../lib/formError'
import { FormErrorType } from '../../common/types'
import { FormLogo, Link, Message } from '../common'

const useStyles = makeStyles({
  root: {
    width: 800,
  },
  leftIcon: {
    marginRight: 5,
  },
  formGroup: {
    marginBottom: 15,
    '& > div': {
      flex: 1,
    },
    '& > div + div': {
      marginLeft: 5,
    },
  },
  errorSection: {
    marginTop: 20,
  },
})

export type FormType = {
  email: string
  password: string
  passwordCheck: string
  name: string
  affiliation: string
  companyNumber: string
  extraEmail: string
  tosAgreed: boolean
  privatePolicyAgreed: boolean
}

type SignupFormProps = {
  form: FormType
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: FormErrorType
}

const SignupForm: FC<SignupFormProps> = ({
  form,
  onChange,
  onSubmit,
  error,
}) => {
  const styles = useStyles()

  const errorDisplay = error
    && (!error.field || ['tosAgreed', 'privatePolicyAgreed'].includes(error.field))

  return (
    <Card className={styles.root}>
      <form onSubmit={onSubmit}>
        <FormLogo />
        <CardContent>
          <Box
            display='flex'
            className={styles.formGroup}
          >
            <TextField
              label='이메일'
              placeholder='이메일을 입력해주세요'
              {...errorField('email', error)}
              InputProps={{
                onChange,
                name: 'email',
                value: form.email,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            display='flex'
            className={styles.formGroup}
          >
            <TextField
              label='비밀번호'
              placeholder='비밀번호를 입력해주세요'
              {...errorField('password', error)}
              InputProps={{
                onChange,
                name: 'password',
                type: 'password',
                value: form.password,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='비밀번호 확인'
              placeholder='비밀번호를 한 번더 입력해주세요'
              {...errorField('passwordCheck', error)}
              InputProps={{
                onChange,
                name: 'passwordCheck',
                type: 'password',
                value: form.passwordCheck,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Check />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            display='flex'
            className={styles.formGroup}
          >
            <TextField
              label='이름'
              placeholder='이름을 입력해주세요'
              fullWidth
              {...errorField('name', error)}
              InputProps={{
                onChange,
                name: 'name',
                type: 'text',
                value: form.name,
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='소속'
              placeholder='소속(회사, 학교 등)을 입력해주세요'
              fullWidth
              {...errorField('affiliation', error)}
              InputProps={{
                onChange,
                name: 'affiliation',
                type: 'text',
                value: form.affiliation,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Business />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            display='flex'
            className={styles.formGroup}
          >
            <TextField
              label='사업자등록번호 (선택사항)'
              placeholder='사업자등록번호를 입력해주세요'
              fullWidth
              {...errorField('name', error)}
              InputProps={{
                onChange,
                name: 'companyNumber',
                type: 'text',
                value: form.companyNumber,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PermContactCalendar />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='세금계산서 발행 이메일(선택사항)'
              placeholder='세금계산서를 발행할 이메일을 입력해주세요'
              fullWidth
              {...errorField('extraEmail', error)}
              InputProps={{
                onChange,
                name: 'extraEmail',
                type: 'text',
                value: form.extraEmail,
                startAdornment: (
                  <InputAdornment position='start'>
                    <ContactMail />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <div>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...{
                      onChange,
                      checked: !!form.tosAgreed,
                      inputProps: { name: 'tosAgreed' },
                    }}
                  />
                }
                label='서비스 이용약관 동의'
              />
              <Link to='/signup/service_use'>내용보기</Link>
            </Typography>
          </div>
          <div>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...{
                      onChange,
                      checked: !!form.privatePolicyAgreed,
                      inputProps: { name: 'privatePolicyAgreed' },
                    }}
                  />
                }
                label='개인정보보호정책 동의'
              />
              <Link to='/signup/private'>내용보기</Link>
            </Typography>
          </div>
          <div>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              이미 계정이 있으신가요? <Link to='/login'>로그인</Link>
            </Typography>
          </div>
          {errorDisplay && (
            <div className={styles.errorSection}>
              <Message type='error'>
                <div dangerouslySetInnerHTML={{ __html: removeGqlErrorString(error?.message) }} />
              </Message>
            </div>
          )}
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
          >
            <Check className={styles.leftIcon} />
            Signup
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

export default SignupForm
