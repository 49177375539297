import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import values from 'ramda/es/values'
import { KeywordRecord } from '@models/keyword'
import MonthlyChart from '@widetrend/ui/components/chart/keyword/MonthlyChart'
import { numberFormat, avg } from '@lib/utils'
import moment from 'moment'
import { Section, Strong } from '@components/common'
import { getMaxEntry, totalVolumeByGroup } from '@lib/utils/keyword'

const useStyles = makeStyles({
  root: {},
})

type PeriodType = {
  name: string
  periods: (string | undefined)[]
}

type MonthlySectionProps = {
  monthDatas: KeywordRecord[]
  periods: PeriodType[]
  keywordCaption: string
}

const MonthlySection: FC<MonthlySectionProps> = ({
  monthDatas,
  periods,
  keywordCaption,
}) => {
  const styles = useStyles()
  const monthlyVolume = totalVolumeByGroup<KeywordRecord>('date', monthDatas)
  const months = Object.keys(monthlyVolume)
  const volumes = values(monthlyVolume)
  const [maxMonth, maxVolume] = getMaxEntry(monthlyVolume)

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>검색어</Strong>의{' '}
          <Strong color='secondary'>월별</Strong> 검색량 추이
        </Typography>
      }
      helpBlock='검색어의 검색량을 합산한 값의 월별 추이입니다. (검색어별로 수집 시작 기간이 다를 수 있습니다.)'
    >
      <Paper className={styles.root}>
        <Box p={2}>
          <MonthlyChart
            keywordCaption={keywordCaption}
            months={months}
            datas={volumes}
          />
        </Box>
        <Box p={2}>
          <ul>
            <li>
              월 평균 검색량:{' '}
              <Strong color='primary'>
                {numberFormat(Math.round(avg(volumes)))}
              </Strong>
              회
            </li>
            <li>
              검색량이 가장 높았던 월:{' '}
              <Strong color='primary'>
                {moment(maxMonth, 'YYYY-MM').format('YYYY년 MM월')}
              </Strong>
              (<Strong color='primary'>{numberFormat(maxVolume)}</Strong>
              )회
            </li>
            <li>
              검색어별 기준 기간
              <ul>
                {periods.map(
                  ({ name, periods: [minMonth, maxMonth] }: PeriodType) => (
                    <li key={name}>
                      <Typography
                        variant='body2'
                        color='textPrimary'
                      >
                        {name}
                        {minMonth && maxMonth && (
                          <span>
                            {moment(minMonth).format('YYYY년 MM월')} ~{' '}
                            {moment(maxMonth).format('YYYY년 MM월')}
                          </span>
                        )}
                      </Typography>
                    </li>
                  ),
                )}
              </ul>
            </li>
          </ul>
        </Box>
      </Paper>
    </Section>
  )
}

export default MonthlySection
