import React, { FC } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/icons/Menu'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InlineLogo from './InlineLogo'
import { MyMenu } from '../main'

type HeaderProps = {
  username: string
  onDrawerToggle: () => void
  onLogout: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  appBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}))

const Header: FC<HeaderProps> = ({ username, onDrawerToggle, onLogout }) => {
  const styles = useStyles()
  return (
    <AppBar
      position='fixed'
      className={styles.appBar}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={onDrawerToggle}
        >
          <Menu />
        </IconButton>
        <div className={styles.appBarContainer}>
          <InlineLogo />
          <MyMenu
            email={username}
            onLogout={onLogout}
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
