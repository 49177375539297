import React, { FC, useCallback } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { AgreeModal } from '@components/signup'
import CenterFormTemplate from '@components/common/CenterFormTemplate'
import SignupContainer from '@containers/signup/SignupContainer'
import {
  SERVICE_USE_TITLE,
  SERVICE_USE_CONTENT,
  PRIVATE_TITLE,
  PRIVATE_CONTENT,
} from './constant'

const Signup: FC = () => {
  const history = useHistory()
  const handleClose = useCallback(() => history.push('/signup'), [history])
  return (
    <CenterFormTemplate>
      <SignupContainer />
      <Switch>
        <Route
          path='/signup/service_use'
          render={() => (
            <AgreeModal
              open
              onClose={handleClose}
              title={SERVICE_USE_TITLE}
              message={SERVICE_USE_CONTENT}
            />
          )}
        />
        <Route
          path='/signup/private'
          render={() => (
            <AgreeModal
              open
              onClose={handleClose}
              title={PRIVATE_TITLE}
              message={PRIVATE_CONTENT}
            />
          )}
        />
      </Switch>
    </CenterFormTemplate>
  )
}

export default Signup
