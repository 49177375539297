import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '@store/modules/auth'
import { useUserInfoQuery } from '@generated/graphql'

const UserLoader: FC = () => {
  const dispatch = useDispatch()
  const { data } = useUserInfoQuery({
    fetchPolicy: 'network-only',
  })

  if (data && data.me) {
    const {
      me: { username },
    } = data
    dispatch(
      actions.setUser({
        username,
      }),
    )
  }
  return null
}

export default UserLoader
