import { TotalVolumeGroup } from '@lib/utils/keyword'
import { map, defaultTo } from 'ramda'

export type ProcessDataResult = {
  keyword: string
  total: number
  data: TotalVolumeGroup
}

interface IGroupedData {
  keyword: string
  data: TotalVolumeGroup
}

export const mapCategoriesToChartData = (
  categories: string[],
  processData: IGroupedData[],
) => map(
  (category: string) => ({
    name: category,
    data: map(
      ({ data }: IGroupedData) => defaultTo(null, data[category]),
      processData,
    ),
  }),
  categories,
)
export const groupKeywordName = (keywords: string[]) => `${keywords[0]}(${keywords.length})`
