import React, { FC, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@store/modules'
import { actions, DrawerType } from '@store/modules/ui'
import { Drawer } from '@components/main'

const DrawerContainer: FC = () => {
  const dispatch = useDispatch()
  const { isLgDrawerOpen, isSmDrawerOpen } = useSelector(
    (state: RootState) => state.ui,
  )

  const handleClose = useCallback(
    () => dispatch(actions.closeDrawer('sm')),
    [dispatch],
  )

  const isDrawerOpen = useCallback(
    (type: DrawerType) => type === 'sm' ? isSmDrawerOpen : isLgDrawerOpen,
    [isLgDrawerOpen, isSmDrawerOpen],
  )

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [isLgDrawerOpen])

  return <Drawer
    isOpen={isDrawerOpen}
    onClose={handleClose}
  />
}

export default DrawerContainer
