import React, { FC } from 'react'
import CenterFormTemplate from '@components/common/CenterFormTemplate'
import LoginContainer from '@containers/login/LoginContainer'

const Login: FC = () => (
  <CenterFormTemplate>
    <LoginContainer />
  </CenterFormTemplate>
)

export default Login
