import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Mail from '@material-ui/icons/Mail'
import Check from '@material-ui/icons/Check'
import Lock from '@material-ui/icons/Lock'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import { errorField, removeGqlErrorString } from '../../lib/formError'
import { FormErrorType } from '../../common/types'
import { FormLogo, Link, Message } from '../common'

const useStyles = makeStyles({
  root: {
    width: 400,
  },
  leftIcon: {
    marginRight: 5,
  },
  formGroup: {
    marginBottom: 15,
  },
  errorSection: {
    marginTop: 20,
  },
})

export type FormType = {
  email: string
  password: string
}

type LoginFormProps = {
  form: FormType
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: FormErrorType
}

const LoginForm: FC<LoginFormProps> = ({ form, onSubmit, onChange, error }) => {
  const styles = useStyles()

  return (
    <Card className={styles.root}>
      <form onSubmit={onSubmit}>
        <FormLogo />
        <CardContent>
          <div className={styles.formGroup}>
            <TextField
              label='Email'
              placeholder='이메일을 입력해주세요'
              fullWidth
              {...errorField('email', error)}
              InputProps={{
                onChange,
                name: 'email',
                value: form.email,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <TextField
              label='Password'
              placeholder='비밀번호를 입력해주세요'
              fullWidth
              {...errorField('password', error)}
              InputProps={{
                onChange,
                name: 'password',
                type: 'password',
                value: form.password,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              아직 회원이 아니신가요? <Link to='/signup'>회원가입</Link>
            </Typography>
            <Box>
              <Typography
                variant='body2'
                color='textSecondary'
              >
                <Link
                  to='https://s3.ap-northeast-2.amazonaws.com/files.widetrend.co.kr/widetrend_introduce.pdf'
                  external
                >
                  서비스 안내 보기
                </Link>
              </Typography>
            </Box>
          </div>
          {error && !error.field && (
            <div className={styles.errorSection}>
              <Message type='error'>
                <div dangerouslySetInnerHTML={{ __html: removeGqlErrorString(error.message) }} />
              </Message>
            </div>
          )}
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
          >
            <Check className={styles.leftIcon} />
            Login
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

export default LoginForm
