import { KeywordData, KeywordRecord } from '@models/keyword'
import { map, join, head, last, ascend, sort, uniq } from 'ramda'

export const generateKeywordCaption = (keywords: KeywordData[]) => join(', ', map((k: KeywordData) => k.name, keywords))

const sortMonth = (months: KeywordRecord[]): string[] => sort(
  ascend((v: string) => v),
  uniq(map((m: KeywordRecord) => m.date, months)),
)

export const getPeriodByKeywords = (keywords: KeywordData[]) => map((k: KeywordData) => ({
  name: k.name,
  periods: [head(sortMonth(k.months)), last(sortMonth(k.months))],
}))(keywords)
