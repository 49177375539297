import { StatRecord } from '@models/keyword'
import { percent } from '@lib/utils'
import {
  pipe,
  filter,
  groupBy,
  equals,
  includes,
  map,
  sum,
  mapObjIndexed,
  last,
  sort,
  sortBy,
  toPairs,
  ascend,
  uniq,
} from 'ramda'

interface IDeviceData {
  device: string
}

interface IVolumeData {
  volume: number
}

interface IDateData {
  date: string
}

export type TotalVolumeGroup = {
  [key: string]: number
}

type StringNumberPair = [string, number]

export const getMaxEntry = (monthlyVolume: TotalVolumeGroup): any => last<StringNumberPair>(
  sortBy<StringNumberPair>(
    (arr: any[]) => arr[1],
    toPairs<number>(monthlyVolume),
  ),
)

export const totalVolumeToPercentage = (
  totalVolumeByDevice: TotalVolumeGroup,
  total: number,
) => mapObjIndexed<number, number>(percent(total))(totalVolumeByDevice)

export const filterStatData = (stats: StatRecord[]) => (
  date: string,
  devices: string[],
) => pipe(
  filter<StatRecord, 'array'>((stat: StatRecord) => equals(stat.date, date)),
  filterDevices<StatRecord>(devices),
)(stats)

export const getUniqDates = (datas: IDateData[]): string[] => pipe(
  map<IDateData, string>((m: IDateData) => m.date),
  uniq,
  sort(ascend((a) => a)),
)(datas)

export const filterDevices = <T extends IDeviceData>(devices: string[]) => (
  data: T[],
) => filter<T, 'array'>((v: T) => includes(v.device, devices))(data)

export const totalVolumeByGroup = <T extends IVolumeData>(
  groupColumn: keyof T,
  datas: T[],
): TotalVolumeGroup => pipe(
    groupBy((m: T) => String(m[groupColumn])),
    mapObjIndexed<T[], number>((group: T[]) => sum(map((v: T) => v.volume || 0, group)),),
  )(datas)
