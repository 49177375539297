import React, { FC, ChangeEvent } from 'react'
import { Select, SelectItem } from '@components/common'
import { Category } from '@models/businessKeyword'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

type CategoryFormProps = {
  depth1List: Category[]
  depth2List: Category[]
  selectDepth1: string | null
  selectDepth2: string | null
  handle1DpethClick: (e: ChangeEvent<any>) => void
  handle2DepthClick: (e: ChangeEvent<any>) => void
  handleSubmit: () => void
}

const categoriesToOptions = (categories: Category[]) => categories.map((c: Category) => ({ value: c.id, label: c.name }))

const CategoryForm: FC<CategoryFormProps> = ({
  depth1List,
  depth2List,
  selectDepth1,
  selectDepth2,
  handle1DpethClick,
  handle2DepthClick,
  handleSubmit,
}) => (
  <form
    onSubmit={(e) => {
      e.preventDefault()
      handleSubmit()
    }}
  >
    <Box
      display='flex'
      justifyContent='space-between'
    >
      <Box>
        <SelectItem label='대분류'>
          <Select
            value={selectDepth1}
            options={categoriesToOptions(depth1List)}
            onChange={handle1DpethClick}
          />
        </SelectItem>
        <SelectItem label='중분류'>
          <Select
            value={selectDepth2}
            options={categoriesToOptions(depth2List)}
            onChange={handle2DepthClick}
          />
        </SelectItem>
      </Box>
      <Button
        color='primary'
        variant='contained'
        type='submit'
      >
        결과 보기
      </Button>
    </Box>
  </form>
)

export default CategoryForm
