import {
  createStandardAction,
  createReducer,
  ActionType,
} from 'typesafe-actions'
import { Category } from '@models/businessKeyword'
import merge from 'ramda/es/merge'

export type State = {
  depth1?: Category
  depth2?: Category
}

export const initialState: State = {
  depth1: undefined,
  depth2: undefined,
}

export type SetCategoriesPayload = {
  depth1?: Category
  depth2?: Category
}

const SET_CATEGORIES = 'businessKeyword/SET_CATEGORIES'
const CLEAR_CATEGORIES = 'businessKeyword/CLEAR_CATEGORIES'

const setCategories = createStandardAction(SET_CATEGORIES)<SetCategoriesPayload>()
const clearCategories = createStandardAction(CLEAR_CATEGORIES)()

export const actions = {
  setCategories,
  clearCategories,
}

export type Action = ActionType<typeof actions>

export const reducer = createReducer<State, Action>(initialState, {
  [SET_CATEGORIES]: (state, action) => merge(state, {
    depth1: action.payload.depth1,
    depth2: action.payload.depth2,
  }),
  [CLEAR_CATEGORIES]: (state) => merge(state, {
    depth1: undefined,
    depth2: undefined,
  }),
})
