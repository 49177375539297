import React, { FC, useCallback, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import KeywordRelationshipDownload from '@containers/download/KeywordRelationshipDownload'
import { SearchForm, SearchFormType, useSearchForm } from '@components/relatedKeyword'
import { KeywordCompareContent } from '@components/keywordCompare'
import {
  IPeriodTypes,
  useKeywordRelationshipLazyQuery,
} from '@generated/graphql'
import { getEmptyKeywords, getTotalKeywordsLength } from '@lib/utils/keywordGroup'

const RelatedKeywordContainer: FC = () => {
  const [fetch, { data, loading }] = useKeywordRelationshipLazyQuery()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = useCallback(({ searchType, keywords }: SearchFormType) => {
    if (!keywords.trim()) {
      enqueueSnackbar('키워드를 입력해주세요', { variant: 'error' })
    }

    fetch({
      variables: {
        input: {
          keywords: [keywords],
          relationType: searchType,
          periodType: IPeriodTypes.Year_1
        }
      }
    })
  }, [enqueueSnackbar, fetch])

  const [form, onChange] = useSearchForm()
  const emptyKeywords = useMemo(() => getEmptyKeywords(data?.groupedData), [data])

  const contents = useMemo(() => {
    if (!data?.groupedData) return null
    return <KeywordCompareContent groups={data?.groupedData} />
  }, [data])

  const downloadSection = useMemo(() => {
    const isAllEmpty = getTotalKeywordsLength(data?.groupedData) === emptyKeywords.length
    if (!data?.groupedData || isAllEmpty) return null
    return <KeywordRelationshipDownload
      keyword={form.keywords}
      relationType={form.searchType}
    />
  }, [data, emptyKeywords, form])

  return (
    <>
      <SearchForm
        form={form}
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loading}
      />
      {contents}
      {downloadSection}
    </>
  )
}

export default RelatedKeywordContainer
