import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import GenderAgeContainer from '@containers/keyword/GenderAgeContainer'
import { StatRecord } from '@models/keyword'
import { getUniqDates } from '@lib/utils/keyword'
import { Section, Strong } from '../../common'

type GenderAgeTableSectionPrpos = {
  statDatas: StatRecord[]
}

const GenderAgeTableSection: FC<GenderAgeTableSectionPrpos> = ({
  statDatas,
}) => {
  const months = getUniqDates(statDatas)

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>검색어</Strong>의{' '}
          <Strong color='secondary'>성별/연령별</Strong> 검색량 분포
        </Typography>
      }
      helpBlock='해당 월의 성별/연령별 검색량을 나타냅니다.'
    >
      <Paper>
        <Box p={2}>
          <GenderAgeContainer
            months={months}
            stats={statDatas}
          />
          <Box
            my={2}
            fontStyle='italic'
          >
            <Strong color='secondary'>
              ※ 해당 월의 전체 검색량과 차이가 있을 수 있습니다.
            </Strong>
          </Box>
        </Box>
      </Paper>
    </Section>
  )
}

export default GenderAgeTableSection
