import {
  totalVolumeByGroup,
  totalVolumeToPercentage,
  filterDevices,
} from '@lib/utils/keyword'
import { StatRecord, GroupStatData } from '@models/keyword'
import { pipe, map, pluck, sum } from 'ramda'
import { renameKeys } from '@lib/utils/function'
import { Gender } from 'types/api'
import { groupKeywordName, ProcessDataResult } from '../util'

export const genders = ['여성', '남성']

export const genderMap = {
  [Gender.MALE]: '남성',
  [Gender.FEMALE]: '여성',
}

export const processData = (groups: GroupStatData[], devices: string[]) => pipe(
  map(({ keywords, stats }: GroupStatData) => ({
    keywords,
    stats: filterDevices<StatRecord>(devices)(stats),
  })),
  map(({ keywords, stats }) => ({
    keyword: groupKeywordName(keywords),
    total: sum(pluck('volume', stats)),
    data: totalVolumeByGroup<StatRecord>('gender', stats),
  })),
  map(({ keyword, total, data }: ProcessDataResult) => ({
    keyword,
    total,
    data: totalVolumeToPercentage(data, total),
  })),
  map(({ data, ...rest }) => ({
    data: renameKeys<number>(genderMap, data),
    ...rest,
  })),
)(groups)
