import React, { FC } from 'react'
import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Link from './Link'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 10px',
    height: '110px',
    borderTop: `1px solid ${theme.palette.divider}`,
    fontSize: theme.typography.fontSize * 0.9,
    background: grey[200],
  },
  list: {
    display: 'table',
    margin: 0,
    padding: 0,
    marginBottom: 5,
    '& li': {
      display: 'table-cell',
      paddingRight: 5,
    },
  },
  link: {
    color: blue[500],
    textDecoration: 'none',
  },
}))

const Footer: FC = () => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <div>
        <ul className={styles.list}>
          <li>
            <Link
              to='https://s3.ap-northeast-2.amazonaws.com/files.widetrend.co.kr/widetrend_terms_and_condition.docx'
              external
            >
              서비스이용약관
            </Link>
          </li>
          <li>
            <Link
              to='https://s3.ap-northeast-2.amazonaws.com/files.widetrend.co.kr/widetrend_private.docx'
              external
            >
              개인정보취급방침
            </Link>
          </li>
        </ul>
        <p>
          서울시 강남구 강남대로 342 역삼빌딩 5층 | ㈜와이드코어 | 대표이사 신도용{' '}
          <br />
          사업자등록번호 : 861-86-00641 | 고객센터 : 070-7798-1316 |{' '}
          <Link
            to='http://www.widecore.co.kr'
            external
          >www.widecore.co.kr
          </Link> <br />
          개인정보보호책임자 엄성용 | E-mail :{' '}
          <Link
            to='mailto:widecore@widecore.co.kr'
            external
          >
            widecore@widecore.co.kr
          </Link>{' '}
          <br />
          Copyright © 2020 Widecore Corp All rights reserved. <br />
        </p>
      </div>
    </div>
  )
}

export default Footer
