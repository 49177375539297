
import {
  useReducer,
  useCallback,
  ChangeEvent
} from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import {
  IBusinessKeywordMonthlyOrderField,
} from '@generated/graphql'
import { getBaseMonths } from './util'

type State = {
  pageIndex: number
  pageSize: number
  selectedRowPaths: string[]
  orderField: IBusinessKeywordMonthlyOrderField
  baseMonth: string
}

type Action =
  | { type: 'setPageInfo'; payload: { pageIndex: number; pageSize: number } }
  | { type: 'setOrderField'; payload: IBusinessKeywordMonthlyOrderField }
  | { type: 'setBaseMonth'; payload: string }
  | { type: 'setSelectedRowPaths'; payload: string[] }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setOrderField':
      return {
        ...state,
        orderField: action.payload,
      }
    case 'setBaseMonth':
      return {
        ...state,
        baseMonth: action.payload,
      }
    case 'setSelectedRowPaths':
      return {
        ...state,
        selectedRowPaths: action.payload,
      }
    case 'setPageInfo':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
const baseMonths = getBaseMonths()

export default function useContainerState() {
  const history = useHistory()
  const [state, dispatch] = useReducer(
    reducer,
    {
      pageIndex: 0,
      pageSize: 50,
      orderField: IBusinessKeywordMonthlyOrderField.Total,
      selectedRowPaths: [],
      baseMonth: baseMonths[0],
    },
  )
  const { selectedRowPaths } = state


  const onOrderFieldChange = useCallback((e: ChangeEvent<any>) => {
    dispatch({ type: 'setOrderField', payload: e.target.value })
  }, [dispatch])

  const onBaseMonthChange = useCallback((e: ChangeEvent<any>) => {
    dispatch({ type: 'setBaseMonth', payload: e.target.value })
  }, [dispatch])

  const onChangePageInfo = useCallback(
    ({ pageIndex: idx, pageSize: size }) => {
      dispatch({
        type: 'setPageInfo',
        payload: { pageIndex: idx, pageSize: size },
      })
    },
    [],
  )

  const onSelectionChange = useCallback((selectedRowPaths: string[]) => {
    dispatch({ type: 'setSelectedRowPaths', payload: selectedRowPaths })
  }, [])

  const onKeywordCompare = useCallback(() => {
    history.push({
      pathname: '/keyword-compairsion-analysis',
      search: `?${qs.stringify({
        keywordGroup: Object.keys(selectedRowPaths || {}).join('|'),
        periodType: "YEAR_1"
      })}`
    })
  }, [selectedRowPaths, history])

  return {
    state,
    dispatch,
    onOrderFieldChange,
    onBaseMonthChange,
    onChangePageInfo,
    onSelectionChange,
    onKeywordCompare
  }
}
