import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { IPostType } from '@generated/graphql'
import NoticeTable from './NoticeTable'

type NoticeSectionProps = {
  onSelect: (row: IPostType) => void
  notices: IPostType[]
}

const useStyles = makeStyles({
  root: {
    padding: 20,
  },
})

const NoticeSection: FC<NoticeSectionProps> = ({ onSelect, notices }) => {
  const styles = useStyles()
  return (
    <Paper className={styles.root}>
      <Typography variant='body1'>서비스 안내</Typography>
      <NoticeTable
        onSelect={onSelect}
        notices={notices}
      />
    </Paper>
  )
}

export default NoticeSection
