import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import { DataTables } from '@components/keyword/GenderAgeTableSection'
import { StatRecord } from '@models/keyword'
import { DeviceFilter } from '@components/common'
import last from 'ramda/es/last'
import { filterStatData } from '@lib/utils/keyword'
import useInput from '@hooks/useInput'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type GenderAgeContainerProps = {
  months: string[]
  stats: StatRecord[]
}

const useStyles = makeStyles({
  rightGap: {
    marginRight: 10,
  },
})

const GenderAgeContainer: FC<GenderAgeContainerProps> = ({ months, stats }) => {
  const styles = useStyles()
  const [devices, onChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )
  const month = useInput<string>(last(months) as string)

  return (
    <Box>
      <Box mb={2}>
        <span className={styles.rightGap}>
          <Select {...month.props}>
            {months.map((m: string) => (
              <MenuItem
                key={m}
                value={m}
              >
                {m}
              </MenuItem>
            ))}
          </Select>
        </span>
        <span className={styles.rightGap}>
          <DeviceFilter
            onChange={onChange}
            isChecked={isChecked}
          />
        </span>
      </Box>
      <Box my={2}>
        <DataTables
          month={month.value}
          stats={filterStatData(stats)(month.value, devices)}
        />
      </Box>
    </Box>
  )
}

export default GenderAgeContainer
