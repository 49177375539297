import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import blue from '@material-ui/core/colors/blue'
import moment from 'moment'
import { IPostType } from '@generated/graphql'

type NoticeTableProps = {
  onSelect: (row: IPostType) => void
  notices: IPostType[]
}

const useStyles = makeStyles({
  root: {},
  titleLink: {
    color: blue[400],
    cursor: 'pointer',
  },
})

const NoticeTable: FC<NoticeTableProps> = ({ onSelect, notices }) => {
  const styles = useStyles()

  return (
    <Table className={styles.root}>
      <colgroup>
        <col width='80px' />
        <col />
        <col width='150px' />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>번호</TableCell>
          <TableCell>제목</TableCell>
          <TableCell>등록일</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {notices.map((notice: IPostType) => (
          <TableRow key={notice.id}>
            <TableCell>{notice.id}</TableCell>
            <TableCell>
              <span
                onClick={() => onSelect(notice)}
                className={styles.titleLink}
              >
                {notice.title}
              </span>
            </TableCell>
            <TableCell>
              {moment(notice.updatedAt, 'YYYY-MM-DD hh:mm:ss').format(
                'YYYY-MM-DD',
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default NoticeTable
