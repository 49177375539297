import ApolloClient from 'apollo-client'
import { from } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionResult from '@generated/introspection-result'
import { actions } from '@store/modules/auth'
import store from '@store/index'

const link = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
})


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (err?.extensions?.CODE === 'AuthenticationError') {
        store.dispatch(actions.expired())
      }
    })
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: introspectionResult
    })
  }),
  link: from([errorLink, authLink, link]),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    },
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
})

export default client
