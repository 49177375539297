import React, { FC } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { GroupMonthData } from '@models/keyword'
import { SearchProgressChart } from '@widetrend/ui/components/chart/keywordCompare'
import { DeviceFilter } from '@components/common'
import sortBy from 'ramda/es/sortBy'
import useInput from '@hooks/useInput'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import Box from '@material-ui/core/Box'
import {
  getMonthKeys,
  processData,
  mapChartData,
} from '@components/keywordCompare/SearchProgressSection/util'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type SearchProgressContainerProps = {
  groupMonths: GroupMonthData[]
}

type SortType = 'input' | 'volume'

const SearchProgressContainer: FC<SearchProgressContainerProps> = ({
  groupMonths,
}) => {
  const [devices, onChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )
  const sort = useInput<SortType>('volume')

  const monthKeys = getMonthKeys(groupMonths)
  let processResult = processData(groupMonths, devices)

  if (sort.value === 'volume') processResult = sortBy(({ total }) => -total, processResult)

  const chartData = mapChartData(processResult, monthKeys)

  return (
    <Box>
      <Box>
        <DeviceFilter
          onChange={onChange}
          isChecked={isChecked}
        />
        <Select {...sort.props}>
          <MenuItem value='input'>입력순</MenuItem>
          <MenuItem value='volume'>검색량순</MenuItem>
        </Select>
      </Box>
      <Box>
        <SearchProgressChart
          categories={monthKeys}
          datas={chartData}
        />
      </Box>
    </Box>
  )
}

export default SearchProgressContainer
