import { useState, useEffect } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import removeNullProperty from '@lib/removeNullProperty'

export const useQueryParams = () => {
  const { search } = useLocation()
  const [queryObject, setQueryObject] = useState<any>({})

  useEffect(() => {
    if (search) {
      const query = qs.parse(search.slice(1))
      setQueryObject(removeNullProperty(query))
    }
  }, [search])

  return queryObject
}
