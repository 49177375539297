import {
  createStandardAction,
  createReducer,
  ActionType,
} from 'typesafe-actions'

export type State = {
  isLgDrawerOpen: boolean
  isSmDrawerOpen: boolean
}

export const initialState: State = {
  isLgDrawerOpen: true,
  isSmDrawerOpen: false,
}

export type DrawerType = 'sm' | 'lg'

const OPEN_DRAWER = 'ui/OPEN_DRAWER'
const CLOSE_DRAWER = 'ui/CLOSE_DRAWER'

const openDrawer = createStandardAction(OPEN_DRAWER)<DrawerType>()
const closeDrawer = createStandardAction(CLOSE_DRAWER)<DrawerType>()

export const actions = {
  openDrawer,
  closeDrawer,
}

export type Action = ActionType<typeof actions>

const getDrawerState = (type: DrawerType, open: boolean) => type === 'sm' ? { isSmDrawerOpen: open } : { isLgDrawerOpen: open }

export const reducer = createReducer<State, Action>(initialState, {
  [OPEN_DRAWER]: (state, action) => ({
    ...state,
    ...getDrawerState(action.payload, true),
  }),
  [CLOSE_DRAWER]: (state, action) => ({
    ...state,
    ...getDrawerState(action.payload, false),
  }),
})
