import * as Yup from 'yup'
import { FormErrorType } from '../common/types'

export const pickFirstError = (
  error: Yup.ValidationError,
  fieldOrder: string[],
): Yup.ValidationError | undefined => fieldOrder
  .map((field: string) => error.inner.find(({ path }: Yup.ValidationError) => path === field),)
  .filter((err) => err !== undefined)[0]

export const errorField = (field: string, error?: FormErrorType) => {
  if (error) {
    if (error.field === field) {
      return {
        error: true,
        helperText: error.message,
      }
    }
  }
  return {}
}


export const removeGqlErrorString = (error: string = '') => error.replace(/GraphQL error:/, "")
