import React, { FC, useCallback } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import { Theme } from '@material-ui/core'
import cx from 'clsx'
import useReactRouter from 'use-react-router'

import Inbox from '@material-ui/icons/Inbox'
import Search from '@material-ui/icons/Search'
import SettingsEthernet from '@material-ui/icons/SettingsEthernet'
import YoutubeSearchedFor from '@material-ui/icons/YoutubeSearchedFor'
import TrendingUp from '@material-ui/icons/TrendingUp'
import FindInPage from '@material-ui/icons/FindInPage'
import Apps from '@material-ui/icons/Apps'

type MenuType = {
  id: string
  label: string
  path: string
  icon: any
}

const menuList1: MenuType[] = [
  {
    id: 'onesearch',
    label: '단일 검색어 분석',
    path: '/keyword-analysis',
    icon: <Search />,
  },
  {
    id: 'compare',
    label: '검색어 비교 분석',
    path: '/keyword-compairsion-analysis',
    icon: <SettingsEthernet />,
  },
  {
    id: 'daily',
    label: '일별 검색량 및 요일 분석',
    path: '/daily-search-volume-trend',
    icon: <TrendingUp />,
  },
  {
    id: 'related',
    label: '연관검색어 확장 분석',
    path: '/related',
    icon: <YoutubeSearchedFor />,
  },
  {
    id: 'searchengine',
    label: '검색엔진 채널별 분석',
    path: '/search-engine-analysis',
    icon: <FindInPage />,
  },
  {
    id: 'businessKeywordMonthly',
    label: '업종별 키워드 분석',
    path: '/business-keyword-monthly',
    icon: <Apps />,
  },
]

const menuList2: MenuType[] = [
  {
    id: 'dashboard',
    label: '공지사항 및 안내',
    path: '/notice',
    icon: <Inbox />,
  },
]

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    cursor: 'pointer',
  },
  selected: {
    background: `${grey[200]} !important`,
    color: theme.palette.primary.dark,
  },
  itemIcon: {
    minWidth: 40,
  },
  selectedIcon: {
    color: theme.palette.primary.dark,
  },
}))

type DrawerListProps = {
  onItemClick?: () => void
}

const MenuList: FC<{ list: MenuType[] } & DrawerListProps> = ({
  list,
  onItemClick,
}) => {
  const styles = useStyles()
  const { history, location } = useReactRouter()

  const handleMenuItemClick = useCallback(
    (path: string) => {
      if (onItemClick) onItemClick()
      history.push(path)
    },
    [history, onItemClick],
  )

  const isActive = useCallback((path: string) => location.pathname === path, [
    location,
  ])
  return (
    <>
      {list.map(({ id, label, icon, path }) => (
        <ListItem
          key={id}
          className={styles.item}
          selected={isActive(path)}
          onClick={() => handleMenuItemClick(path)}
          classes={{ selected: styles.selected }}
        >
          <ListItemIcon
            className={cx(
              { [styles.selectedIcon]: isActive(path) },
              styles.itemIcon,
            )}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      ))}
    </>
  )
}

const DrawerList: FC<DrawerListProps> = ({ onItemClick }) => (
  <List>
    <MenuList {...{ list: menuList1, onItemClick }} />
    <Divider />
    <MenuList {...{ list: menuList2, onItemClick }} />
  </List>
)

export default DrawerList
