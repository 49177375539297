import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { StatRecord } from '@models/keyword'
import { Section, Strong } from '@components/common'
import AgeWeightContainer from '@containers/keyword/AgeWeightContainer'
import { getUniqDates, totalVolumeByGroup } from '@lib/utils/keyword'
import { mapGenderKey } from './util'
import GenderCard from './GenderCard'

type GenderAgeSectionProps = {
  statDatas: StatRecord[]
}

const GenderAgeSection: FC<GenderAgeSectionProps> = ({ statDatas }) => {
  const months = getUniqDates(statDatas)
  const groupedVolume = totalVolumeByGroup<StatRecord>('gender', statDatas)

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>검색어</Strong>의{' '}
          <Strong color='secondary'>성별/연령별</Strong> 비중
        </Typography>
      }
      helpBlock='검색어에 대한 성별/연령별 관심 비중을 나타냅니다.'
    >
      <Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
          >
            <GenderCard groupedVolume={mapGenderKey(groupedVolume)} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
          >
            <Paper>
              <Box
                p={2}
                pb={0}
              >
                <Typography variant='body1'>연령별 비중</Typography>
              </Box>
              <Box
                p={2}
                pt={0}
              >
                <AgeWeightContainer
                  months={months}
                  stats={statDatas}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}

export default GenderAgeSection
