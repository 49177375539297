import { combineReducers } from 'redux'
import * as businessKeyword from './businessKeyword'
import * as auth from './auth'
import * as ui from './ui'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ui: ui.reducer,
  businessKeyword: businessKeyword.reducer,
})

export type RootState = {
  auth: auth.State
  ui: ui.State
  businessKeyword: businessKeyword.State
}

export const rootInitialState: RootState = {
  auth: auth.initialState,
  ui: ui.initialState,
  businessKeyword: businessKeyword.initialState,
}
