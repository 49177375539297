import {
  totalVolumeByGroup,
  totalVolumeToPercentage,
  filterDevices,
} from '@lib/utils/keyword'
import { StatRecord, GroupStatData } from '@models/keyword'
import { pipe, map, pluck, sum } from 'ramda'
import { renameKeys } from '@lib/utils/function'
import { AgeGroup } from 'types/api'
import { groupKeywordName, ProcessDataResult } from '../util'

export const ages = [
  '초등학생이하',
  '중고등학생',
  '20대초반',
  '20대후반',
  '30대',
  '40대',
  '50대이상',
].reverse()

export const ageMap = {
  [AgeGroup._TWELVE]: '초등학생이하',
  [AgeGroup.THIRTEEN__NINETEEN]: '중고등학생',
  [AgeGroup.TWENTY_ZERO__FOUR]: '20대초반',
  [AgeGroup.TWENTY_FIVE__NINE]: '20대후반',
  [AgeGroup.THIRTY_ZERO__NINE]: '30대',
  [AgeGroup.FOURTY_ZERO__NINE]: '40대',
  [AgeGroup.FIFTY_]: '50대이상',
}

export const processData = (groups: GroupStatData[], devices: string[]) => pipe(
  map(({ keywords, stats }: GroupStatData) => ({
    keywords,
    stats: filterDevices<StatRecord>(devices)(stats),
  })),
  map(({ keywords, stats }) => ({
    keyword: groupKeywordName(keywords),
    total: sum(pluck('volume', stats)),
    data: totalVolumeByGroup<StatRecord>('age', stats),
  })),
  map(({ keyword, total, data }: ProcessDataResult) => ({
    keyword,
    total,
    data: totalVolumeToPercentage(data, total),
  })),
  map(({ data, ...rest }) => ({
    data: renameKeys<number>(ageMap, data),
    ...rest,
  })),
)(groups)
