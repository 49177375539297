import { useState, useCallback } from 'react'

const useToggleButtonGroup = <T, >(initialValue: T) => {
  const [value, setValue] = useState<T>(initialValue)

  const onChange = useCallback(
    (e: object, newValue: any) => {
      if (newValue) setValue(newValue)
    },
    [setValue],
  )

  return { value, onChange }
}

export default useToggleButtonGroup
