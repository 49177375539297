import React, { FC } from 'react'
import Search from '@material-ui/icons/Search'
import Helmet from 'react-helmet'
import { PageHeader } from '@components/common'
import { KeywordContainer } from '@containers/keyword'

const Keyword: FC = () => (
  <div>
    <Helmet>
      <title>WIDETREND | 단일 검색어 분석</title>
    </Helmet>
    <PageHeader icon={<Search />}>단일 검색어 분석</PageHeader>
    <KeywordContainer />
  </div>
)

export default Keyword
