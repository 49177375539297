export const SERVICE_USE_TITLE = '이용약관'
export const SERVICE_USE_CONTENT = `
서비스 이용약관

제 1장 총칙

제1조 (목적)

이 서비스 약관은 ㈜와이드코어(이하 “회사”)에서 제공하는 “wideTrend” 서비스(이하 ‘서비스’라 한다)를 이용함에 있어 ㈜와이드코어와 회원의 권리 • 의무 및 책임사항을 규정함을 목적으로 합니다.

제2조 (용어의 정의)

이 약관에서 사용하는 용어의 정의는 다음 각 항과 같으며, 정의되지 않은 용어에 대한 해석은 관계법령에 정하는 바에 따릅니다.

① wideTrend 서비스(이하, 서비스): 사용자 검색에 의한 검색어에 대해 데이터를 수집 • 분석 하고 이를 대시보드 형식으로 제공하는 서비스 • 기타 이와 관련하여 회사가 제공하는 모든 서비스를 말합니다.

② 사이트: 서비스를 제공하는 https://widetrend.co.kr/을 말합니다.

③ 회원(이하, 회원): 본 약관에 동의 후 이용계약을 체결하고, 아이디와 비밀번호를 발급받은 사용자를 말합니다. 회원의 자격 및 권한 등은 회원의 종류, 등급에 따라 일부 메뉴의 이용이 제한될 수 있습니다.

④ 계정(이하 ID): 회원의 식별을 위해 서비스가 승인한 문자 또는 숫자의 조합

⑤ 비밀번호: 비밀 보호를 위해 회원 자신이 설정한 문자, 숫자의 조합

제3조 (약관의 효력 및 개정)

① 본 약관 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 계약을 통해 서비스에 가입함으로써 효력이 발생됩니다.

② 서비스는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.

③ 본 약관은 필요 시 개정될 수 있으며 약관을 개정하고자 할 경우, 회사는 개정된 약관을 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 개정약관의 공지는 규정된 방법 중 1가지 이상의 방법으로 회원에게 명확하게 공지하도록 합니다.

1. 서비스 화면 내 게시
2. 이메일 통보
3. 회사가 정한 기타 공지 방법 등 
- 회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.

④ 본 조의 규정에 의하여 개정약관은 원칙적으로 그 효력 발생일로부터 장래를 향하여 유효합니다.

⑤ 본 약관의 개정과 관련하여 이의가 있는 회원은 회원탈퇴를 할 수 있습니다. 단, 이의가 있음에도 불구하고 본 조 제1항및 제2항에 따른 회사의 공지가 있은 후 14일 이내에 회원탈퇴 및 거부의사를 표명하지 않은 회원은 개정 약관에 동의한 것으로 봅니다. 

⑥ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다.

⑦ 본 조의 통지방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적 또는 전체적인 통지의 경우에 이를 준용합니다.


제 2장 서비스 이용계약

제4조 (이용계약 성립 및 서비스 이용신청) 

① 서비스 이용신청은 회원이 되고자 하는 자가 서비스 실행 시 본 약관 및 개인정보취급방침 등에 대하여 동의하고 회사가 제공하는 가입양식을 작성하여 서비스 이용을 신청한 경우 회사가 이를 승낙함으로써 이용계약이 성립됩니다.

② 다음 각호의 1에 해당하는 경우에는 승낙을 하지 않을 수 있습니다.

1. 이용신청의 내용을 허위로 기재한 경우
2. 실명이 아니거나 타인의 명의를 사용하여 신청하는 경우
3. 사회의 안녕과 질서 혹은 미풍약속을 저해할 목적으로 신청할 경우
4. 부정한 용도로 서비스를 이용하고자 하는 경우
5. 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우
6. 기타 이용신청 요건에 미비 되었을 때

③ 회원은 제1항의 이용 신청 시 반드시 실제정보(본인 명의의 이메일 또는 전화번호)를 기재하여야 하며, 허위정보를 기재한 회원의 경우 서비스 이용이 제한되거나 제5조(회원정보의 변경)에 의거 회원과의 서비스 이용계약을 해지할 수 있습니다.

④ 만14세 미만의 이용고객이 서비스를 이용하기 위해서는 회사가 요구하는 소정의 법정대리인의 동의절차를 거쳐야 합니다.

⑤ 타인의 명의를 도용하는 등의 부정한 목적과 방법으로 이용 신청한 회원의 ID는 사전 통지 없이 삭제될 수 있으며, 당해 회원은 서비스 이용금지 및 법적 제재를 받을 수 있습니다.

⑥ 제1항에 따른 신청에 있어 회사는 회원의 본인인증을 요청할 수 있습니다.

⑦ 회사는 서비스 관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다

제5조 (회원정보의 변경)

① 회원은 회원정보 메뉴 혹은 기타 해당페이지로 링크된 메뉴를 통해서 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 

② 회원정보 입력부분을 허위로 기재했거나, 미 수정에 따라 발생하는 모든 책임은 회원에게 있습니다.

③ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

④ ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입하여야 합니다.


제 3장 계약당사자의 의무

제6조 (회사의 의무)

① 회사는 관련법에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스제공하기 위하여 최선을 다하여 노력합니다.

② 회사는 법률에서 정한 특별한 규정이 없는 경우를 제외하고 기본적으로 회원의 별도 동의 없이 회원정보를 제3자에게 제공하지 않습니다.

③ 회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령 및 회사가 정하는 개인정보 취급방침에 정한 바에 의하며, 회원이 상시 확인할 수 있도록 홈페이지를 통해 공지하고 있습니다.

제7조 (회원의 의무)

① 회원은 본 약관에서 규정하는 사항과 서비스 이용안내 또는 공지사항 등 서비스에서 공지 또는 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.

② 서비스에서 관계법령 및 개인정보보호정책에 의하여 그 책임을 지는 경우를 제외하고, 관리소홀, 부정사용에 의하여 발생되는 모든 결과에 대한 책임은 회원에게 있습니다.

③ 회원은 서비스를 이용할 때 다음 각호의 행위를 하지 않아야 합니다.

1. 신청 또는 변경 시 허위내용을 등록하는 행위
2. 타인의 이메일, 전화번호 등 도용하는 행위
3. 서비스에서 얻은 정보를 복제, 출판 또는 제3자에게 제공하는 행위
4. 서비스의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위
5. 영리 목적으로 사용하거나, 광고, 스팸 전화를 위하여 이용하는 행위
6. 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위
7. 자동 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
8. 본인이 아닌 제3자에게 접속권한을 부여하는 행위
9. 회사의 명예를 손상시키거나 업무를 방해하는 행위
10. 외설 또는 폭력 등 미풍양속에 반하는 용도에 이용하는 행위
11. 기타 현행법 상 불법적이거나 부당한 행위

③ 회원의 동 조 제3에 해당하는 행위로 인해 서비스 회원 또는 제3자가 입은 손해에 대해서는 그 행위를 한 이용자가 배상 또는 법적 책임을 져야 합니다.

④ 회원은 서비스를 이용하여 영리활동을 할 수 없으며, 영리활동에 이용하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다.

⑤ 회원은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도 및 증여할 수 없으며, 이를 담보로도 제공할 수 없습니다.


제 4장 서비스 이용

제8조 (제공 서비스)

① 서비스에 관한 다양한 메뉴를 이용할 수 있습니다.

② 회원가입 신청 후 서비스 이용을 위해서는 관리자의 승인이 필요합니다. 

승인은 1일 이상의 시일이 소요될 수 있습니다.


제9조 (ID 및 비밀번호에 대한 관리)

① ID와 비밀번호의 관리책임은 회원에게 있습니다.

② 회원은 자신의 ID 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하여야 하고 서비스의 안내가 있는 경우에는 그에 따라야 합니다. 이를 따르지 않아 발생하는 모든 결과의 책임은 회원에게 있습니다.

제10조 (게시물의 관리)

① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항 혹은 이메일 등의 방법으로 회원에게 제공할 수 있습니다.

② 회원이 원치 않는 경우 언제든지 무선서비스를 통해서 수신거부를 할 수 있습니다.

③ 회사는 서비스 개선 및 서비스 소개 등의 목적으로 회원의 동의 하에 추가적인 개인정보를 요구할 수 있습니다.

④ 회사는 다음 각 항에 해당하는 게시물이나 자료는 사전통지 없이 삭제할 수 있습니다.

1. 국가안전이나 보안에 위배되는 경우
2. 정치적 목적이나 성향이 있는 경우
3. 특정기관, 단체, 부서를 근거없이 비난하는 경우
4. 특정인을 비방하거나 명예훼손의 우려가 있는 경우
5. 영리목적의 상업성 광고, 저작권을 침해할 수 있는 내용
6. 욕설, 음란물 등 불건전한 내용
7. 실명을 원칙으로 하는 경우에 실명을 사용하지 않았거나 실명이 아닌 것으로 판단되었을 경우
8. 동일인 또는 동일인이라고 인정되는 자가 똑같은 내용을 주 2회 이상 게시하거나 비슷한 내용을 1일 2회 이상 게시하는 경우
9. 게시판의 성격에 부합하지 않는 게시물의 경우
10. 연습, 오류, 장난성의 내용
11. 기타 관련법령에 위반된다고 판단되는 경우


제11조 (정보의 제공 및 광고 게재)

① 회사는 수시로 서비스의 향상을 위하여 기존 서비스의 전부 또는 일부를 별도의 통지 없이 변경할 수 있습니다.

② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.

1. 이용 감소로 인한 원할 한 서비스 제공의 곤란 및 수익성악화
2. 기술 진보에 따른 차세대 서비스로의 전환 필요성, 서비스 제공과 관련한 회사 정책의 변경
3. 컴퓨터 등 정보통신설비의 보수점검 교체 및 고장 통신의 두절
4. 서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우
5. 서비스 업그레이드를 위해 필요한 경우 
6. 정전, 제발 설비의 장애 또는 이용량 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
7. 기타 천재지변, 국가 비상사태 등 불가항력적 사유인 경우

③ 본 조 제2항에 의한 서비스 중단의 경우 제3조(회원에 대한 통지)에 따라 회원에게 통지합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스의 중단으로 인해 사전 통지가 불가능한 경우에는 그러하지 아니합니다.

④ 회사는 본 조 제2항의 사유로 서비스의 제공이 중단 또는 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대해 배상하지 않음을 원칙으로 합니다.

⑤ 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 

⑥ 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 

⑦ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다. 

다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다. 

⑧ 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 화면을 통해 회원에게 제공할 수 있습니다.

⑨ 회사는 서비스 화면에 광고를 게재할 수 있습니다. 


제12조 (게시물의 저작권)

① 서비스 내에 게시한 게시물의 저작권은 회사에게 있습니다. 또한 회사의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 단, 비영리 목적인 경우는 그러하지 아니하며, 또한 서비스내에서의 게재권을 가집니다.

② 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.


제13조 (권리의 귀속)

① 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 

② 회사가 제공하는 서비스의 디자인, 회사가 만든 텍스트, 스크립트(script), 그래픽 등 회사가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한 저작권, 기타 지적재산권은 대한민국 및 외국 법령에 기하여 회사가 보유하고 있거나 회사에게 소유권 또는 사용권이 있습니다.

③ 회원은 본 이용약관으로 인하여 서비스를 소유하거나 서비스에 관한 저작권을 보유하게 되는 것이 아니라, 회사로부터 서비스의 이용을 허락 받게 되는 바, 서비스는 정보취득 또는 개인용도로만 제공되는 형태로 회원이 이용할 수 있습니다.

④ 회원은 서비스를 통해 얻어지는 정보를 영리 목적으로 사용, 복사, 유통하는 것을 포함하여 회사가 만든 텍스트, 스크립트 등을 복사하거나 유통할 수 없습니다.


제14조 (서비스 제공의 중지)

회사는 다음 각 호의 내용에 해당하는 경우 서비스 제공의 일부 또는 전부를 제한하거나 중단할 수 있습니다.

1. 서비스용 설비의 보수 점검, 교체 등 공사로 인한 부득이한 경우
2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
3. 시스템 점검이 필요한 경우
4. 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
5. 기타 천재지변 등 불가항력적 사유가 있는 경우


제 5장 계약해지 및 이용제한

제15조 (이용계약의 해지 및 자격상실/정지)

① 회원은 본인 희망 시 계약을 해지하거나 서비스의 이용중지를 요청할 수 있습니다.

② 회원이 서비스의 이용중지를 요청 시, 이메일 통보 등 회사가 지정한 절차를 거쳐 신청해야 합니다.

③ 회사는 다음 각 호에 해당하는 회원이 확인될 경우, 당해 회원에 대한 사전 통보 없이 회원자격 유보/중지/해지 등의 조치를 취할 수 있으며, 이 경우 회원은 서비스와 관련된 모든 권리를 주장할 수 없습니다.

1.회원가입 신청 시에 허위의 내용을 등록한 경우
2.서비스를 부정한 방법 또는 목적으로 이용한 경우
3.회원이 사망한 경우
4.다른 회원의 서비스 홈페이지 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
5.서비스 내 다른 회원에 대한 위협/희롱/지속적인 고통 및 불편을 끼치는 경우
6.서비스 내에서 회사의 운영자/임직원 또는 회사를 사칭한 경우
7.회원 가입 신청 시 제3자의 개인정보를 이용 및 불법으로 도용한 경우
8.서비스 이용 중 법률 위반행위 및 사회의 안녕/질서/미풍양속을 해치는 행위를 한 경우
9.제3자의 특허권, 상표권, 저작권, 영업비밀 등 지적재산권을 포함한 기타 권리를 침해하는 행위를 한 경우
10.회사로부터 특별한 권한을 부여 받지 않고 회사의 홈페이지 나 클라이언트 프로그램을 변경 또는 회사의 서버를 해킹하는 등의 시스템을 위협하는 행위를 한 경우
11.회사의 사전승인 및 다른 회원에 대한 사전 동의 없이 Active X, 스파이웨어, 애드웨어 등의 프로그램을 강제로 설치하게 한 경우
12.서비스 방문자나 타 회원의 의사와 무관하게 특정 사이트로 방문을 유도한 경우
13.서비스 이용 중 불법복제 소프트웨어, 제3자의 저작물을 밀매하는 등의 관련 법률에 저촉되는 행위를 한 경우
14.인종/성/광신/이적 등의 반 사회적/도덕적 편견에 기반한 단체를 결성하는 행위를 한 경우
15.다른 회원의 ID/이메일 계정 등의 개인정보를 수집하는 행위
16.범죄와의 결부, 관련 법령 위반 활동으로 판단되는 행위를 한 경우
17.다른 회원으로 하여금 상기 5항 내지 16항의 금지행위를 하도록 유발 및 조장하는 활동을 한 경우
18.기타 관련법령에 위반된다고 판단되는 경우


제16조 (회원탈퇴)

① 본 약관 제15조(이용계약의 해지 및 자격상실/정지) 제2항에 정해진 방법으로 탈퇴를 하고자 하는 회원은 회원탈퇴 요청 후 회사가제공하는 절차를 이행하면 탈퇴를 하게 됩니다. 회원가입 시 작성한 기본정보는 완전 소멸됩니다.


제17조 (서비스 종료)

① 서비스를 종료하고자 할 경우, 회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 본 약관 제3조 제3항에 규정된 통지방법을 준용하여 회원에게 알려드립니다.
 
② 본 조 제1항에 따른 서비스 종료 통지일 이후 회원은 회사로부터 서비스를 제공받지 못합니다.


제6장 면책

제18조 (책임제한)

① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 

② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. 

③ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다. 

④ 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 

⑤ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다. 

⑥ 회사 및 회사의 임직원 그리고 대리인은 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 아니합니다.

1. 회원 상태정보의 허위 또는 부정확성에 기인하는 손해 
2. 그 성질과 경위를 불문하고 서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해
3. 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해
4. 서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위로부터 발생하는 손해
5. 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해
6. 전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생되는 손해
7. 회원간의 회원 상태정보 등록 및 서비스 이용 과정에서 발생하는 명예훼손 기타 불법행위로 인한 각종 민형사상 책임


제19조 (해외이용)

회사는 대한민국 내에 설치된 서버를 기반으로 서비스를 제공, 관리하고 있습니다. 따라서 회사는 회원이 대한민국의 영토 이외의 지역에서 서비스를 이용하고자 하는 경우 서비스의 품질 또는 사용성을 보장하지 않습니다. 따라서 회원은 대한민국의 영토 이외의 지역에서 서비스를 이용하고자 하는 경우 스스로의 판단과 책임에 따라서 이용 여부를 결정하여야 하고, 특히 서비스의 이용과정에서 현지 법령을 준수할 책임은 회원에게 있습니다.


제20조 (준거법 및 재판관할)

① 본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국법 및 상관례에 따릅니다.

② 서비스 이용으로 발생한 분쟁에 대해 소송이 제기 될 경우 본청 소재지를 관할하는 법원을 전속 관할법원으로 합니다.


제7장 서비스 이용약관 적용

① 이 서비스이용 약관은 2019.11.01부터 적용됩니다.

`

export const PRIVATE_TITLE = '개인정보보호정책'
export const PRIVATE_CONTENT = `
(주)와이드코어에서는 wideTrend 서비스를 제공하기위해 운영하는 온라인사이트 (이하 사이트 라 한다)를 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. ㈜와이드코어는 서비스의 개인정보처리방침을 개정하는 경우 사이트 공지사항(또는 개별공지)를 통하여 공지할 것입니다.

1. 개인정보의 처리 목적

사이트는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.

① 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별•인증 등을 목적으로 개인정보를 처리합니다.

2. 개인정보 수집

① 개인정보 항목: 
- 필수입력 : 로그인ID(이메일), 비밀번호
- 선택입력 : 회사명, 성명, 사업자등록번호, 세금계산서발행 이메일

② 보유기간: 사이트는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유ㆍ이용기간 또는 법령에 따른 개인정보 보유ㆍ이용기간 내에서 개인정보를 처리ㆍ보유합니다.
구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권ㆍ채무관계
잔존시에는 해당 채권ㆍ채무관계 정산시까지
- 전자상거래에서의 계약ㆍ청약철회, 대금결제, 제화 등 공급기록 : 5년

3. 개인정보 이용

사이트는 회원 관리, 서비스 개발・제공 및 향상 등 아래의 목적으로 개인정보를 이용합니다.

① 회원 가입 의사의 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.

② 서비스 제공 및 서비스 방문 및 이용기록의 분석, 서비스 개선 등을 위하여 개인정보를 이용합니다.

③ 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 등에 개인정보를 이용합니다.

4. 개인정보의 파기

사이트는 개인정보는 수집 및 이용목적이 달성되면 지체없이 파기하며, 절차 및 방법은 다음과 같습니다.

- 전자적 파일 : 파일 삭제 및 디스크 등 저장매체 포맷
- 수기(手記) 문서 : 분쇄하거나 소각

5. 정보주체의 권리∙의무 및 행사 방법

① 정보주체는 서비스에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.

가. 개인정보 열람요구
나. 오류 등이 있을 경우 정정 요구
다. 삭제요구
라. 처리정지 요구

6. 개인정보의 안전성 확보 조치

① 사이트는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
- 관리적 조치 : 직원ㆍ종업원 등에 대한 정기적 교육
- 기술적 조치 : 개인정보처리시스템(또는 개인정보가 저장된 컴퓨터)의 비밀번호설정 등
접근권한 관리, 백신소프트웨어 등 보안프로그램 설치, 개인정보가 저장된 파일의 암호화
- 물리적 조치 : 개인정보가 저장ㆍ보관된 장소의 시건, 출입통제 등

7. 개인정보 보호책임자 작성

(주)와이드코어는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자

성명: 엄성용
직급: 이사
연락처: 070-7798-1316, esy1304@widecore.co.kr
※ 개인정보 보호 담당부서로 연결됩니다.

8. 개인정보 처리방침 변경

① 이 개인정보 처리방침은 2019.11.01부터 적용됩니다.
`
