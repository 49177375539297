import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Section, Strong } from '@components/common'
import { IFetchSearchEngineDataQuery, IChannel } from '@generated/graphql'
import { SearchProgressChart } from '@widetrend/ui/components/chart/keywordCompare'
import SearchEngineSection from './SearchEnginSection'

type SearchEngineContentProps = {
  data: IFetchSearchEngineDataQuery['searchEngineData']
}

const NAME_MAP = {
  [IChannel.Youtube]: '유투브',
  [IChannel.Google]: '구글',
  [IChannel.Searchad]: '네이버'
}

const COLOR_MAP = {
  [IChannel.Youtube]: '#F50E02',
  [IChannel.Google]: '#4284F4',
  [IChannel.Searchad]: '#46CF5D'
}

const SearchEngineContent: FC<SearchEngineContentProps> = ({ data }) => {
  const { summary, monthly, monthKeys } = data
  return (
    <Box mt={8}>
      <SearchEngineSection data={summary} />
      {monthly.map(({ name, data: monthlyData }) => (
        <Section
          header={
            <Typography variant='h5'>
              <Strong color='primary'>{name}</Strong> {' '}
              검색어의 채널별 검색 추이
            </Typography>
          }
          helpBlock=""
        >
          <SearchProgressChart
            categories={monthKeys}
            datas={monthlyData.map((v) => ({
              type: 'line',
              color: COLOR_MAP[v.channel],
              name: NAME_MAP[v.channel],
              data: v.volumes,
            }))}
          />
          <Box mb={4} />
        </Section>
      ))}
    </Box>
  )
}

export default SearchEngineContent
