import { useForm } from '@hooks/useForm'
import { IKeywordRelation } from '@generated/graphql'
import { SearchFormType } from './SearchForm'

export const useSearchForm = (): [SearchFormType, any] => {
  const [form, onChange] = useForm<SearchFormType>({
    searchType: IKeywordRelation.AutoComplete,
    keywords: '',
  })
  return [form, onChange]
}
