import moment from 'moment'
import defaultTo from 'ramda/es/defaultTo'

export const isBetween = (
  target: moment.Moment,
  start: moment.Moment,
  end: moment.Moment,
) => target.diff(start, 'days') >= 0 && target.diff(end, 'days') <= 0

export const formatDate = (target: moment.Moment, format: string) => !target ? '' : target.format(defaultTo(format, 'YYYY-MM-DD'))
