import React, { FC, useState } from 'react'
import Button from '@material-ui/core/Button'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

type MyMenuProps = {
  email: string
  onLogout: () => void
}

const useStyles = makeStyles({
  button: {
    color: 'white',
    textTransform: 'none',
  },
  leftIcon: {
    marginRight: '5px',
  },
})

const MyMenu: FC<MyMenuProps> = ({ email, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const styles = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleLogout = () => {
    onLogout()
    handleClose()
  }

  return (
    <div>
      <Button
        className={styles.button}
        aria-controls='my-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <AccountCircle className={styles.leftIcon} />
        <Hidden xsDown>
          {email}
        </Hidden>
      </Button>
      <Menu
        id='my-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

export default MyMenu
