import React, { FC, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import BarChart from '@material-ui/icons/BarChart'
import PieChart from '@material-ui/icons/PieChart'
import { AgeChart } from '@widetrend/ui/components/chart/keyword'
import { PieChart as AgePieChart } from '@widetrend/ui/components/chart/common'
import { CompareHelpBlock } from '@components/keyword/CompareHelpBlock'
import {
  totalVolumeByGroup,
  totalVolumeToPercentage,
  filterStatData,
  getMaxEntry,
} from '@lib/utils/keyword'
import { DeviceFilter } from '@components/common'
import useCheckboxGroup from '@hooks/useCheckboxGroup'
import useInput from '@hooks/useInput'
import useToggleButtonGroup from '@hooks/useToggleButtonGroup'
import { renameKeys } from '@lib/utils/function'
import { StatRecord } from '@models/keyword'
import { AgeGroup } from 'types/api'
import { last, sum, values, equals, toPairs, keys } from 'ramda'
import { DEFAULT_CHECKED_DEVICE } from '@common/constant'

type ChartType = 'column' | 'pie'
type AgeWeightContainerProps = {
  months: string[]
  stats: StatRecord[]
}

const ageMap = {
  [AgeGroup._TWELVE]: '초등학생이하',
  [AgeGroup.THIRTEEN__NINETEEN]: '중고등학생',
  [AgeGroup.TWENTY_ZERO__FOUR]: '20대초반',
  [AgeGroup.TWENTY_FIVE__NINE]: '20대후반',
  [AgeGroup.THIRTY_ZERO__NINE]: '30대',
  [AgeGroup.FOURTY_ZERO__NINE]: '40대',
  [AgeGroup.FIFTY_]: '50대이상',
}

const useStyles = makeStyles({
  rightGap: {
    marginRight: 10,
  },
})

const AgeWeightContainer: FC<AgeWeightContainerProps> = ({ months, stats }) => {
  const styles = useStyles()
  const chartType = useToggleButtonGroup<ChartType>('column')
  const { props, setValue, value } = useInput<string>(last(months) as string)
  const [devices, onDeviceChange, isChecked] = useCheckboxGroup(
    DEFAULT_CHECKED_DEVICE,
  )

  useEffect(() => {
    setValue(last(months) as string)
  }, [months, setValue])

  const groupedVolume = useMemo(
    () => totalVolumeByGroup<StatRecord>(
      'age',
      filterStatData(stats)(value, devices),
    ),
    [devices, stats, value],
  )

  const groupedVolumePercent = useMemo(
    () => totalVolumeToPercentage(groupedVolume, sum(values(groupedVolume))),
    [groupedVolume],
  )

  const Chart = useMemo(() => {
    if (equals(chartType.value, 'column')) {
      return (
        <AgeChart
          ageGroups={values(ageMap)}
          datas={[
            {
              type: 'column',
              data: keys(ageMap).map((k) => groupedVolumePercent[k]),
            },
          ]}
        />
      )
    }
    if (equals(chartType.value, 'pie')) {
      return (
        <AgePieChart
          name='age'
          data={toPairs<number>(renameKeys<number>(ageMap, groupedVolume))}
        />
      )
    }
    return null
  }, [chartType.value, groupedVolume, groupedVolumePercent])

  const maxEntry = getMaxEntry(
    renameKeys<number>(ageMap, groupedVolumePercent),
  )

  return (
    <>
      <Box
        mt={2}
        display='flex'
        alignItems='center'
      >
        <span className={styles.rightGap}>
          <Select {...props}>
            {months.map((m: string) => (
              <MenuItem
                key={m}
                value={m}
              >
                {m}
              </MenuItem>
            ))}
          </Select>
        </span>
        <span className={styles.rightGap}>
          <DeviceFilter
            onChange={onDeviceChange}
            isChecked={isChecked}
          />
        </span>
        <ToggleButtonGroup
          {...chartType}
          exclusive
          size='small'
          aria-label='chart type'
        >
          <ToggleButton
            value='column'
            aria-label='bar chart'
          >
            <BarChart /> 막대차트
          </ToggleButton>
          <ToggleButton
            value='pie'
            aria-label='stacking chart'
          >
            <PieChart /> 파이차트
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box my={2}>{Chart}</Box>
      <Box mt={2}>
        <ul>
          <li>
            <CompareHelpBlock
              maxColumn={maxEntry?.[0]}
              maxPercent={maxEntry?.[1]}
            />
          </li>
        </ul>
      </Box>
    </>
  )
}

export default AgeWeightContainer
