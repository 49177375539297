import React, { FC, useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import { usePostsQuery, IPostType } from '@generated/graphql'
import { NoticeSection, NoticeModal } from '@components/notice'

const NoticeContainer: FC = () => {
  const { data } = usePostsQuery()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<IPostType>()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleRowSelect = useCallback(
    (row: IPostType) => {
      setOpen(true)
      setSelectedRow(row)
    },
    [setOpen, setSelectedRow],
  )

  return (
    <>
      {data?.posts && (
        <Box>
          <NoticeSection
            onSelect={handleRowSelect}
            notices={data.posts as IPostType[]}
          />
          <NoticeModal
            open={open}
            onClose={handleClose}
            notice={selectedRow}
          />
        </Box>
      )}
    </>
  )
}

export default NoticeContainer
