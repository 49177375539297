import React, { FC } from 'react'
import CX from 'clsx'
import Typography from '@material-ui/core/Typography'
import red from '@material-ui/core/colors/red'
import indigo from '@material-ui/core/colors/indigo'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

type MessageProps = {
  children: any
  type: 'primary' | 'error'
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 5,
  },
  primary: {
    background: indigo[200],
    color: indigo[900],
  },
  error: {
    background: red[200],
    color: red[900],
  },
}))

const Message: FC<MessageProps> = ({ children, type }) => {
  const styles = useStyles()
  return (
    <div className={CX(styles.root, styles[type])}>
      <Typography variant='body2'>{children}</Typography>
    </div>
  )
}

export default Message
