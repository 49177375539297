import { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { SearchFormType } from '@components/common/SearchForm'
import pipe from 'ramda/es/pipe'
import trim from 'ramda/es/trim'
import map from 'ramda/es/map'
import split from 'ramda/es/split'
import filter from 'ramda/es/filter'
import { IPeriodTypes } from '@generated/graphql'

type UseSearchFormSubmitParams = {
  onSearch: (periodType: IPeriodTypes, keywordGroup: string[][]) => any
}

const splitAndTrim = (character: string, str: string) => map(trim, split(character, str))

const processKeyword = (character: string, keyword: string) => filter((s: string) => !!s, splitAndTrim(character, trim(keyword)))

const useSearchFormSubmit = ({
  onSearch,
}: UseSearchFormSubmitParams): any => {
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = useCallback(
    ({ periodType, keywords }: SearchFormType) => {
      if (!trim(keywords)) {
        enqueueSnackbar('키워드를 입력해주세요', { variant: 'error' })
        return
      }

      const groups = processKeyword('\n', keywords)

      if (groups.length > 10) {
        enqueueSnackbar('분석 키워드 개수가 초과되었습니다. 키워드 개수를 조정해주세요', {
          variant: 'error',
        })
        return
      }

      const keywordGroups = map(
        (group: string) => processKeyword(',', group),
        groups,
      )

      const overKeywordGroup = pipe(
        map((keywordArray: string[]) => keywordArray.length),
        filter((count: number) => count > 10),
      )(keywordGroups)

      if (overKeywordGroup.length > 1) {
        enqueueSnackbar('키워드는 10개까지 입력가능합니다.', {
          variant: 'error',
        })
        return
      }

      onSearch(periodType, keywordGroups)
    },
    [enqueueSnackbar, onSearch],
  )

  return handleSubmit
}

export default useSearchFormSubmit
