import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect, {
  SelectProps as MuiSelectProps,
} from '@material-ui/core/Select'

type Option = {
  value: string
  label: string
}

interface SelectProps extends MuiSelectProps {
  options: Option[]
}

type SelectItemProps = {
  label: string
  children: any
}

const useStyles = makeStyles(() => ({
  selectLabel: {
    marginRight: 5,
  },
  selectItem: {
    marginRight: 20,
  },
}))

export const SelectItem: FC<SelectItemProps> = ({ label, children }) => {
  const styles = useStyles()
  return (
    <Box
      display='inline-flex'
      alignItems='center'
      className={styles.selectItem}
    >
      <span className={styles.selectLabel}>{label}</span>
      {children}
    </Box>
  )
}

export const Select: FC<SelectProps> = ({ options, ...rest }) => (
  <MuiSelect {...rest}>
    {options.map((op: Option) => (
      <MenuItem
        key={op.value}
        value={op.value}
      >
        {op.label}
      </MenuItem>
    ))}
  </MuiSelect>
)
