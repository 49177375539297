import React, { FC, useMemo } from 'react'
import { KeywordDailyGroup } from '@models/keyword'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import {
  Section,
  Strong,
  DeviceFilter,
  DateRangePicker,
} from '@components/common'
import { isBetween, formatDate } from '@lib/utils/date'
import {
  processData,
  mapChartData,
} from '@components/dailyTrend/DailyTrendSection/util'
import useGroupDaily from '@components/dailyTrend/useGroupDaily'
import { DailyTrendChart } from '@widetrend/ui/components/chart/dailyTrend'
import filter from 'ramda/es/filter'
import moment from 'moment'

type DailyTrendContainerProps = {
  groupDailys: KeywordDailyGroup[]
}


const convertMomentObject = (date: string) => moment(date, 'YYYY-MM-DD')

const DailyTrendContainer: FC<DailyTrendContainerProps> = ({ groupDailys }) => {
  const {
    uniqDates,
    limit: [limitStart, limitEnd],
    datePickerInput: [focusInput, setFocusInput],
    datePicker: [[startDate, endDate], setDateRange],
    deviceFilter: [devices, onChange, isChecked],
  } = useGroupDaily(groupDailys)

  const processResult = useMemo(
    () => processData(groupDailys, devices, [startDate, endDate]),
    [devices, groupDailys, startDate, endDate],
  )
  const categories = useMemo(
    () => filter(
      (date: string) => isBetween(convertMomentObject(date), startDate, endDate),
      uniqDates,
    ),
    [startDate, endDate, uniqDates],
  )

  const chartData = useMemo(() => mapChartData(categories, processResult), [
    categories,
    processResult,
  ])

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>{groupDailys.length}개 검색어</Strong>의 일간
          검색 트렌드
        </Typography>
      }
      helpBlock={`${formatDate(startDate, 'YYYY년MM월DD일')}부터 ${formatDate(
        endDate,
        'YYYY년MM월DD일',
      )}까지의 검색량 트렌드를 비교합니다`}
    >
      <Paper>
        <Box p={2}>
          <Box>
            <DeviceFilter
              onChange={onChange}
              isChecked={isChecked}
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate: start, endDate: end }) => setDateRange([start, end])
              }
              focusedInput={focusInput}
              onFocusChange={setFocusInput}
              limitStart={limitStart}
              limitEnd={limitEnd}
            />
          </Box>
          <Box>
            <DailyTrendChart
              categories={categories}
              datas={chartData}
            />
          </Box>
        </Box>
      </Paper>
    </Section>
  )
}

export default DailyTrendContainer
