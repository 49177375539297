import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  credits: {
    enabled: false,
  },
})

type HighChartProps = {
  options: any
}

const HighChart: React.FC<HighChartProps> = ({ options }) => <HighchartsReact
  highcharts={Highcharts}
  options={options}
/>

export default HighChart
