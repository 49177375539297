import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CloudDownload from '@material-ui/icons/CloudDownload'

type DownloadSectionProps = {
  onClick: any
}

const useStyles = makeStyles({
  leftIcon: {
    marginRight: 5,
  },
})

const DownloadSection: FC<DownloadSectionProps> = ({ onClick }) => {
  const styles = useStyles()

  return (
    <Box textAlign='center'>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={onClick}
      >
        <CloudDownload className={styles.leftIcon} /> 엑셀파일로 데이터 다운로드
        받기
      </Button>
    </Box>
  )
}

export default DownloadSection
