import {
  getUniqDates,
  filterDevices,
  totalVolumeByGroup,
  TotalVolumeGroup,
} from '@lib/utils/keyword'
import { KeywordRecord, GroupMonthData } from '@models/keyword'
import { pipe, map, pluck, sum, flatten, defaultTo } from 'ramda'
import { groupKeywordName } from '../util'

type NullableNumber = number | null

type ProcessDataResult = {
  keyword: string
  total: number
  data: TotalVolumeGroup
}

type ChartData = {
  type: 'line' | 'bar'
  name: string
  data: NullableNumber[]
}

export const getMonthKeys = (groups: GroupMonthData[]) => getUniqDates(flatten(pluck('months', groups)))

export const processData = (groups: GroupMonthData[], devices: string[]) => pipe(
  map(({ keywords, months }: GroupMonthData) => ({
    keyword: groupKeywordName(keywords),
    months: filterDevices<KeywordRecord>(devices)(months),
  })),
  map(({ keyword, months }) => ({
    keyword,
    total: sum(pluck('volume', months)),
    data: totalVolumeByGroup<KeywordRecord>('date', months),
  })),
)(groups)

export const mapChartData = (
  processData: ProcessDataResult[],
  monthKeys: string[],
): ChartData[] => map(
  ({ keyword, data }) => ({
    type: 'line',
    name: keyword,
    data: mapDataMonthKeys(data, monthKeys),
  }),
  processData,
)

export const mapDataMonthKeys = (
  data: TotalVolumeGroup,
  monthKeys: string[],
): NullableNumber[] => map<string, NullableNumber>(
  (month: string) => defaultTo(null, data[month]),
  monthKeys,
)
