import ReactDOM from 'react-dom'

type PortalProps = {
  children: any
  rootId?: string
}

function Portal({ children, rootId = 'modal-root' }: PortalProps) {
  const root = document.getElementById(rootId)
  return root ? ReactDOM.createPortal(children, root) : null
}

export default Portal
