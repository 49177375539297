import React, { FC, useMemo } from 'react'
import { KeywordDailyGroup } from '@models/keyword'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import {
  Section,
  Strong,
  DeviceFilter,
  DateRangePicker,
} from '@components/common'
import { formatDate } from '@lib/utils/date'
import {
  processData,
  mapChartData,
  weekdays,
} from '@components/dailyTrend/WeekdaySection/util'
import { WeekdayChart } from '@widetrend/ui/components/chart/dailyTrend'
import useGroupDaily from '@components/dailyTrend/useGroupDaily'

type WeekdayContainerProps = {
  groupDailys: KeywordDailyGroup[]
}

const WeekdayContainer: FC<WeekdayContainerProps> = ({ groupDailys }) => {
  const {
    limit: [limitStart, limitEnd],
    datePickerInput: [focusInput, setFocusInput],
    datePicker: [[startDate, endDate], setDateRange],
    deviceFilter: [devices, onChange, isChecked],
  } = useGroupDaily(groupDailys)

  const processResult = useMemo(
    () => processData(groupDailys, devices, [startDate, endDate]),
    [devices, groupDailys, startDate, endDate],
  )

  const chartData = useMemo(() => mapChartData(weekdays, processResult), [
    processResult,
  ])

  return (
    <Section
      header={
        <Typography variant='h5'>
          <Strong color='primary'>{groupDailys.length}개 검색어</Strong>의
          요일별 검색 비중
        </Typography>
      }
      helpBlock={`${formatDate(startDate, 'YYYY년MM월DD일')}부터 ${formatDate(
        endDate,
        'YYYY년MM월DD일',
      )}까지의 검색량 트렌드를 비교합니다`}
    >
      <Paper>
        <Box p={2}>
          <Box>
            <DeviceFilter
              onChange={onChange}
              isChecked={isChecked}
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate: start, endDate: end }) => setDateRange([start, end])
              }
              focusedInput={focusInput}
              onFocusChange={setFocusInput}
              limitStart={limitStart}
              limitEnd={limitEnd}
            />
          </Box>
          <Box>
            <WeekdayChart
              categories={weekdays}
              datas={chartData}
            />
          </Box>
        </Box>
      </Paper>
    </Section>
  )
}

export default WeekdayContainer
