import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

type ColorType = 'primary' | 'secondary'

type StrongProps = {
  color: ColorType
  children: any
}

type StyleProps = {
  color: ColorType
}

const setColor = (theme: Theme, color: ColorType) => ({
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
}[color])

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ color }: StyleProps) => ({
    color: setColor(theme, color),
  }),
}))

const Strong: FC<StrongProps> = ({ color, children }) => {
  const styles = useStyles({ color })
  return <strong className={styles.root}>{children}</strong>
}

export default Strong
