import React, { FC } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import useToggleButtonGroup from '@hooks/useToggleButtonGroup'
import useInput from '@hooks/useInput'
import { makeStyles } from '@material-ui/core/styles'
import { SearchEngineBarChart } from '@widetrend/ui/components/chart/searchEngine'
import BarChart from '@material-ui/icons/BarChart'
import InsertChart from '@material-ui/icons/InsertChart'
import {
  getCategories,
  generateChartData,
  sortData,
  SortType,
} from '@components/searchEngine/EngineWeightByKeyword/util'
import { CHANNEL_MAP } from '@common/constant'
import pluck from 'ramda/es/pluck'
import keys from 'ramda/es/keys'
import { IChannel, ISearchEngineSummaryType } from '@generated/graphql'

type ChartType = 'bar' | 'stacking'

type EngineWeightByKeywordProps = {
  data: ISearchEngineSummaryType[]
}

type SortItem = {
  label: string
  value: string
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const sortList = [
  {
    label: '검색량 많은 순',
    value: 'total',
  },
  {
    label: '네이버 비중 높은 순',
    value: IChannel.Searchad,
  },
  {
    label: '유투브 비중 높은 순',
    value: IChannel.Youtube,
  },
  {
    label: '구글 비중 높은 순',
    value: IChannel.Google,
  },
]

const EngineWeightByKeyword: FC<EngineWeightByKeywordProps> = ({ data }) => {
  const styles = useStyles()
  const chartType = useToggleButtonGroup<ChartType>('bar')
  const sortType = useInput<SortType>('total')
  const sortedData = sortData(sortType.value, data)
  const categories = getCategories(sortedData)
  const chartData = generateChartData(
    keys(CHANNEL_MAP),
    pluck('data', sortedData),
  )

  return (
    <Paper className={styles.root}>
      <Box p={2}>
        <Typography variant='body1'>
          검색엔진별 월별 평균 검색량 비교
        </Typography>
      </Box>
      <Box
        px={2}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Select {...sortType.props}>
          {sortList.map(({ label, value }: SortItem) => (
            <MenuItem
              key={value}
              value={value}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
        <ToggleButtonGroup
          {...chartType}
          exclusive
          size='small'
          aria-label='chart type'
        >
          <ToggleButton
            value='bar'
            aria-label='bar chart'
          >
            <BarChart /> 가로 막대차트
          </ToggleButton>
          <ToggleButton
            value='stacking'
            aria-label='stacking chart'
          >
            <InsertChart /> 누적막대차트
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box my={2}>
        <SearchEngineBarChart
          chartType={chartType.value}
          categories={categories}
          datas={chartData}
          stacking={chartType.value === 'stacking' ? 'percent' : undefined}
        />
      </Box>
    </Paper>
  )
}

export default EngineWeightByKeyword
