import React, { FC } from 'react'
import cx from 'clsx'
import MuiDrawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { DrawerType } from '@store/modules/ui'
import DrawerList from './DrawerList'

const drawerWidth = 260

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}))

type DrawerProps = {
  isOpen: (type: DrawerType) => boolean
  onClose: () => void
}

const Drawer: FC<DrawerProps> = ({ isOpen, onClose }) => {
  const styles = useStyles()
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const isTemporaryOpen = smDown && isOpen('sm')
  const isPermanentOpen = !smDown && isOpen('lg')

  return (
    <nav className={cx({ [styles.drawer]: isPermanentOpen })}>
      <Hidden
        smUp
        implementation='css'
      >
        <MuiDrawer
          variant='temporary'
          className={styles.drawer}
          anchor='left'
          open={isTemporaryOpen}
          onClose={onClose}
          classes={{ paper: styles.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <DrawerList onItemClick={onClose} />
        </MuiDrawer>
      </Hidden>
      <Hidden
        xsDown
        implementation='css'
      >
        {isPermanentOpen && (
          <MuiDrawer
            variant='permanent'
            classes={{ paper: styles.drawerPaper }}
          >
            <div className={styles.toolbar} />
            <DrawerList />
          </MuiDrawer>
        )}
      </Hidden>
    </nav>
  )
}

export default Drawer
