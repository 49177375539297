import React, { FC } from 'react'
import { SeriesOptionsType } from 'highcharts'
import HighChart from '../HighChart'

type SearchProgressChartProps = {
  categories: string[]
  datas: SeriesOptionsType[]
}

const buildOption = ({
  categories,
  datas,
}: SearchProgressChartProps): Highcharts.Options => ({
  chart: {
    type: 'line',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories,
  },
  yAxis: {
    title: {
      text: '검색량',
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
      },
      enableMouseTracking: true,
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}<br/>',
  },
  series: datas,
})

const SearchProgressChart: FC<SearchProgressChartProps> = (props) => {
  const options = buildOption(props)
  return <HighChart options={options} />
}

export default SearchProgressChart
