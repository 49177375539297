import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createMuiTheme,
  responsiveFontSizes,
  withStyles,
} from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import client from '@lib/apollo/client'
import { Loader } from '@components/common'
import {
  ApolloNetworkStatusProvider,
  useApolloNetworkStatus,
} from 'react-apollo-network-status'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import ReactGA from 'react-ga'
import moment from 'moment'
import 'moment/locale/ko'
import store from './store'
import { Main, Signup, Login } from './pages'
import { ProtectRoute } from './containers/auth'

moment.locale('ko')

let theme = createMuiTheme()
theme = responsiveFontSizes(theme)

function GlobalLoadingIndicator() {
  const status = useApolloNetworkStatus()

  if (status.numPendingQueries > 1) {
    return <Loader />
  }
  return null
}

// setup global style
const globalStyles = () => ({
  '@global': {
    '*': {
      'box-sizing': 'border-box',
    },
    ul: {
      paddingLeft: 20,
      listStyle: 'initial',
    },
  },
})

const App: React.FC = () => {
  ReactGA.initialize('UA-164488722-1')

  return (
    <ApolloProvider client={client}>
      <ApolloNetworkStatusProvider client={client}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={1000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Provider store={store}>
              <Router>
                <div className='App'>
                  <CssBaseline />
                  <Switch>
                    <Route
                      path='/login'
                      exact
                      component={Login}
                    />
                    <Route
                      path='/signup'
                      component={Signup}
                    />
                    <ProtectRoute
                      path='/'
                      component={Main}
                    />
                  </Switch>
                </div>
                <GlobalLoadingIndicator />
              </Router>
            </Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloNetworkStatusProvider>
    </ApolloProvider>
  )
}

export default withStyles(globalStyles)(App)
